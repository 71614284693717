import React from 'react';
import { Row, Col, Button, Label } from 'reactstrap';
import PropTypes from 'prop-types';

function SurveyButtonGroup(props) {
  const { qNumber, qText, value, formDataID, dataID, min, max, showNA, onClickFunction } = props;

  return (
    <div>
      <Row className="mt-4">
        <Col sm={12} className="text-center">
          <Label>
            <b>{qNumber}</b> {qText}
          </Label>
          <br />
          {min === 0 && (
            <Button
              className="btn-form"
              id={`${qNumber}-b-0`}
              color={value === 0 ? 'dark' : 'inverse'}
              onClick={() => onClickFunction(formDataID, dataID, 0)}
              active={value === 0}
            >
              0
            </Button>
          )}
          {min <= 1 && max >= 1 && (
            <Button
              className="btn-form"
              id={`${qNumber}-b-1`}
              color={value === 1 ? 'dark' : 'inverse'}
              onClick={() => onClickFunction(formDataID, dataID, 1)}
              active={value === 1}
            >
              1
            </Button>
          )}
          {min <= 2 && max >= 2 && (
            <Button
              className="btn-form"
              id={`${qNumber}-b-2`}
              color={value === 2 ? 'dark' : 'inverse'}
              onClick={() => onClickFunction(formDataID, dataID, 2)}
              active={value === 2}
            >
              2
            </Button>
          )}
          {min <= 3 && max >= 3 && (
            <Button
              className="btn-form"
              id={`${qNumber}-b-3`}
              color={value === 3 ? 'dark' : 'inverse'}
              onClick={() => onClickFunction(formDataID, dataID, 3)}
              active={value === 3}
            >
              3
            </Button>
          )}
          {min <= 4 && max >= 4 && (
            <Button
              className="btn-form"
              id={`${qNumber}-b-4`}
              color={value === 4 ? 'dark' : 'inverse'}
              onClick={() => onClickFunction(formDataID, dataID, 4)}
              active={value === 4}
            >
              4
            </Button>
          )}
          {min <= 5 && max >= 5 && (
            <Button
              className="btn-form"
              id={`${qNumber}-b-5`}
              color={value === 5 ? 'dark' : 'inverse'}
              onClick={() => onClickFunction(formDataID, dataID, 5)}
              active={value === 5}
            >
              5
            </Button>
          )}
          {min <= 6 && max >= 6 && (
            <Button
              className="btn-form"
              id={`${qNumber}-b-6`}
              color={value === 6 ? 'dark' : 'inverse'}
              onClick={() => onClickFunction(formDataID, dataID, 6)}
              active={value === 6}
            >
              6
            </Button>
          )}
          {min <= 7 && max >= 7 && (
            <Button
              className="btn-form"
              id={`${qNumber}-b-7`}
              color={value === 7 ? 'dark' : 'inverse'}
              onClick={() => onClickFunction(formDataID, dataID, 7)}
              active={value === 7}
            >
              7
            </Button>
          )}
          {showNA && (
            <Button
              className="btn-form"
              id={`${qNumber}-b-NA`}
              color={value === -1 ? 'dark' : 'inverse'}
              onClick={() => onClickFunction(formDataID, dataID, -1)}
              active={value === -1}
            >
              N/A
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
}

SurveyButtonGroup.defaultProps = {
  showNA: false,
  min: 0,
  max: 4,
};

SurveyButtonGroup.propTypes = {
  qNumber: PropTypes.string.isRequired,
  qText: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  formDataID: PropTypes.number.isRequired,
  dataID: PropTypes.number.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  showNA: PropTypes.bool,
  onClickFunction: PropTypes.func.isRequired,
};

export default SurveyButtonGroup;
