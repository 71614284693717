/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Progress,
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../../Auth/Auth';

import SurveyButtonGroup from '../../SurveyButtonGroup/SurveyButtonGroup';
import Widget from '../../Widget/Widget';

import '../forms.scss';

import loadingSpinner from '../../../images/Eclipse-1s-200px.svg';

class PSE extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onFrmBtnClick = this.onFrmBtnClick.bind(this);
    this.onSectionBtnClick = this.onSectionBtnClick.bind(this);
    this.getForm = this.getForm.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.renderGeneralSiteInformation = this.renderGeneralSiteInformation.bind(this);
    this.renderSupportingSupervision = this.renderSupportingSupervision.bind(this);
    this.renderAppropriateGuidance = this.renderAppropriateGuidance.bind(this);
    this.renderFeedback = this.renderFeedback.bind(this);
    this.renderSiteInformation = this.renderSiteInformation.bind(this);

    this.state = {
      formData: [
        {
          id: 'General Site Information',
          data: [
            {
              id: 'PracticumLevel',
              value: '',
            },
            {
              id: 'PracticumType',
              value: '',
            },
          ],
        },
        {
          id: 'Defining and Supporting Supervision',
          data: [
            {
              id: 'q1',
              value: '',
            },
            {
              id: 'q2',
              value: '',
            },
            {
              id: 'q3',
              value: '',
            },
            {
              id: 'q4',
              value: '',
            },
            {
              id: 'q5',
              value: '',
            },
            {
              id: 'q6',
              value: '',
            },
            {
              id: 'q7',
              value: '',
            },
            {
              id: 'q8',
              value: '',
            },
            {
              id: 'q9',
              value: '',
            },
            {
              id: 'q10',
              value: '',
            },
          ],
        },
        {
          id: 'Appropriate Guidance',
          data: [
            {
              id: 'q11',
              value: '',
            },
            {
              id: 'q12',
              value: '',
            },
            {
              id: 'q13',
              value: '',
            },
            {
              id: 'q14',
              value: '',
            },
            {
              id: 'q15',
              value: '',
            },
            {
              id: 'q16',
              value: '',
            },
            {
              id: 'q17',
              value: '',
            },
            {
              id: 'q18',
              value: '',
            },
            {
              id: 'q19',
              value: '',
            },
            {
              id: 'q20',
              value: '',
            },
            {
              id: 'q21',
              value: '',
            },
          ],
        },
        {
          id: 'Feedback',
          data: [
            {
              id: 'q22',
              value: '',
            },
            {
              id: 'q23',
              value: '',
            },
            {
              id: 'q24',
              value: '',
            },
            {
              id: 'q25',
              value: '',
            },
            {
              id: 'q26',
              value: '',
            },
            {
              id: 'q27',
              value: '',
            },
            {
              id: 'q28',
              value: '',
            },
            {
              id: 'q29',
              value: '',
            },
            {
              id: 'q30',
              value: '',
            },
          ],
        },
        {
          id: 'Site Information',
          data: [
            {
              id: 'q31',
              value: '',
            },
            {
              id: 'q32',
              value: '',
            },
            {
              id: 'q33',
              value: '',
            },
            {
              id: 'q34',
              value: '',
            },
            {
              id: 'q35',
              value: '',
            },
            {
              id: 'q36',
              value: '',
            },
            {
              id: 'q37',
              value: '',
            },
            {
              id: 'q38',
              value: '',
            },
            {
              id: 'q39',
              value: '',
            },
            {
              id: 'q40',
              value: '',
            },
            {
              id: 'q41',
              value: '',
            },
            {
              id: 'q42',
              value: '',
            },
            {
              id: 'q43',
              value: '',
            },
            {
              id: 'q44',
              value: '',
            },
            {
              id: 'q45',
              value: '',
            },
          ],
        },
      ],
      feedbackComments: '',
      siteComments: '',
      practicumSiteName: '',
      practicumSiteSupervisor: '',
      practicumSiteSupervisorTitle: '',
      academicYear: '',
      studentName: '',
      currentSection: 0,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.getForm();
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  onFrmBtnClick(sectionID, questionID, answer) {
    const newState = {
      ...this.state.formData,
    };

    newState[sectionID].data[questionID].value = answer;

    this.setState({
      formData: newState,
    });
  }

  onSectionBtnClick(e, sectionValue) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const { currentSection } = this.state;
    const newSectionID = currentSection + sectionValue;

    this.setState({
      currentSection: newSectionID,
    });
  }

  getForm() {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/form/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        // check if data is valid
        const {
          data,
          status,
          academicYear,
          student,
          practicumSiteName,
          practicumSiteSupervisor,
          practicumSiteSupervisorTitle,
        } = response.data;
        const studentName = `${student.firstName} ${student.lastName}`;

        if (status !== 'new') {
          let { formData, feedbackComments, siteComments, currentSection } = this.state;

          // check if data was returned in a array
          if (data.length === 5) {
            formData = data;
          } else {
            // eslint-disable-next-line prefer-destructuring
            formData = data[0];
          }

          // * Feedback
          if (formData[3].data[8].value !== '') {
            feedbackComments = formData[3].data[8].value;
          }

          // * Site Information
          if (formData[4].data[14].value !== '') {
            siteComments = formData[4].data[14].value;
          }

          // if form is cmmpleted, set currentSection to display readout
          if (status === 'completed') {
            currentSection = 999;
          }

          // form inProgress/completed, set student info and formData
          this.setState({
            formData,
            feedbackComments,
            siteComments,
            academicYear,
            studentName,
            practicumSiteName,
            practicumSiteSupervisor,
            practicumSiteSupervisorTitle,
            currentSection,
            loading: false,
          });
        } else {
          // new form, set student info
          this.setState({
            academicYear,
            studentName,
            practicumSiteName,
            practicumSiteSupervisor,
            practicumSiteSupervisorTitle,
            loading: false,
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  saveForm(e, newStatus) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/form/update`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const id = params.formid;
    const { formData, feedbackComments, siteComments } = this.state;

    // * Feedback
    // set qs from inputs
    formData[3].data[8].value = feedbackComments;

    // * Site Information
    // set qs from inputs
    formData[4].data[14].value = siteComments;

    axios
      .post(url, { id, newStatus, formData }, config)
      .then((response) => {
        // update form status if results are valid
        const { status } = response.data;
        if (status === 'completed') {
          // eslint-disable-next-line react/prop-types, react/destructuring-assignment
          this.props.history.push('/profile');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  renderGeneralSiteInformation() {
    const {
      formData,
      practicumSiteName,
      practicumSiteSupervisor,
      practicumSiteSupervisorTitle,
      academicYear,
    } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> General Site Information
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* General Information - Practicum Site */}
                  <FormGroup row>
                    <Label md={3} className="text-md-right">
                      <b>Practicum Site</b>
                    </Label>
                    <Col md={8}>
                      <Input
                        id="practicumSiteName"
                        type="text"
                        placeholder={practicumSiteName}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  {/* // General Information - Practicum Site */}
                  {/* General Information - Supervisor Name */}
                  <FormGroup row>
                    <Label md={3} className="text-md-right">
                      <b>Supervisor Name</b>
                    </Label>
                    <Col md={8}>
                      <Input
                        id="practicumSiteSupervisor"
                        type="text"
                        placeholder={practicumSiteSupervisor}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  {/* // General Information - Supervisor Name */}
                  {/* General Information - Supervisor Title */}
                  <FormGroup row>
                    <Label md={3} className="text-md-right">
                      <b>Supervisor Title</b>
                    </Label>
                    <Col md={8}>
                      <Input
                        id="practicumSiteSupervisorTitle"
                        type="text"
                        placeholder={practicumSiteSupervisorTitle}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  {/* // General Information - Supervisor Title */}
                  {/* General Information - Practicum Year */}
                  <FormGroup row>
                    <Label md={3} className="text-md-right">
                      <b>Practicum Year</b>
                    </Label>
                    <Col md={8}>
                      <Input
                        id="academicYear"
                        type="text"
                        placeholder={`${academicYear}-${academicYear + 1}`}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  {/* // General Information - Practicum Year */}
                  {/* General Information - Practicum Level */}
                  <FormGroup row>
                    <Label md={3} className="text-md-right">
                      <b>Practicum Level</b>
                    </Label>
                    <Col md={8}>
                      <Button
                        className="btn-form"
                        id="q6-b-internal"
                        color={formData[0].data[0].value === 'Internal' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(0, 0, 'Internal')}
                        active={formData[0].data[0].value === 'Internal'}
                      >
                        Internal
                      </Button>
                      <Button
                        className="btn-form"
                        id="q6-b-external"
                        color={formData[0].data[0].value === 'External' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(0, 0, 'External')}
                        active={formData[0].data[0].value === 'External'}
                      >
                        External
                      </Button>
                      <Button
                        className="btn-form"
                        id="q6-b-preInternship"
                        color={formData[0].data[0].value === 'Pre-Internship' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(0, 0, 'Pre-Internship')}
                        active={formData[0].data[0].value === 'Pre-Internship'}
                      >
                        Pre-Internship
                      </Button>
                      <Button
                        className="btn-form"
                        id="q6-b-additionalYear"
                        color={formData[0].data[0].value === 'Additional Year' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(0, 0, 'Additional Year')}
                        active={formData[0].data[0].value === 'Additional Year'}
                      >
                        Additional Year
                      </Button>
                    </Col>
                  </FormGroup>
                  {/* // General Information - Practicum Level */}
                  {/* General Information - Practicum Type */}
                  <FormGroup row>
                    <Label md={3} className="text-md-right">
                      <b>Practicum Type</b>
                    </Label>
                    <Col md={8}>
                      <Button
                        className="btn-form"
                        id="q7-b-primary"
                        color={formData[0].data[1].value === 'Primary' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(0, 1, 'Primary')}
                        active={formData[0].data[1].value === 'Primary'}
                      >
                        Primary
                      </Button>
                      <Button
                        className="btn-form"
                        id="q7-b-supplemental"
                        color={formData[0].data[1].value === 'Supplemental' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(0, 1, 'Supplemental')}
                        active={formData[0].data[1].value === 'Supplemental'}
                      >
                        Supplemental
                      </Button>
                    </Col>
                  </FormGroup>
                  {/* // General Information - Practicum Type */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="s0-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s0-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderSupportingSupervision() {
    const { formData } = this.state;

    return (
      <div>
        {/* Defining and Supporting Supervision  */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Defining and Supporting Supervision
                </h5>
              }
            >
              <Row className="mt-4">
                <Col xs="12" md={{ size: 8, offset: 2 }}>
                  <Alert color="primary">
                    <p>For the next set of questions, please use the following guidelines:</p>
                    <Row>
                      <Col xs={1} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col xs={11}>Far below expectations - poor, a concern</Col>
                    </Row>
                    <Row>
                      <Col xs={1} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col xs={11}>Below expectations - marginal</Col>
                    </Row>
                    <Row>
                      <Col xs={1} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col xs={11}>Met expectations - satisfactory</Col>
                    </Row>
                    <Row>
                      <Col xs={1} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col xs={11}>Above expectations - good</Col>
                    </Row>
                    <Row>
                      <Col xs={1} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col xs={11}>Far above expectations - excellent</Col>
                    </Row>
                    <Row>
                      <Col xs={1} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col xs={11}>
                        Not applicable or not enough information to form a judgement
                      </Col>
                    </Row>
                    <p className="mt-4">
                      <strong className="text-danger">
                        All information reported on this form will be submitted anonymously and is
                        not tied to any identifiable student information. Data will only be reported
                        in aggregate.
                      </strong>
                    </p>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Defining and Supporting Supervision - Q1 */}
                  <SurveyButtonGroup
                    qNumber="Q1"
                    qText="Established clear training goals and expectations"
                    value={formData[1].data[0].value}
                    formDataID={1}
                    dataID={0}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Defining and Supporting Supervision - Q1 */}
                  {/* Defining and Supporting Supervision - Q2 */}
                  <SurveyButtonGroup
                    qNumber="Q2"
                    qText="Clearly defined the nature, structure, expectations and limitations of the
                          supervisory relationship"
                    value={formData[1].data[1].value}
                    formDataID={1}
                    dataID={1}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Defining and Supporting Supervision - Q2 */}
                  {/* Defining and Supporting Supervision - Q3 */}
                  <SurveyButtonGroup
                    qNumber="Q3"
                    qText="Provided adequate time for supervision"
                    value={formData[1].data[2].value}
                    formDataID={1}
                    dataID={2}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Defining and Supporting Supervision - Q3 */}
                  {/* Defining and Supporting Supervision - Q4 */}
                  <SurveyButtonGroup
                    qNumber="Q4"
                    qText="Maintained regularly scheduled supervision and was on time for meetings"
                    value={formData[1].data[3].value}
                    formDataID={1}
                    dataID={3}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Defining and Supporting Supervision - Q4 */}
                  {/* Defining and Supporting Supervision - Q5 */}
                  <SurveyButtonGroup
                    qNumber="Q5"
                    qText="Established an atmosphere of acceptance and psychological safety"
                    value={formData[1].data[4].value}
                    formDataID={1}
                    dataID={4}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Defining and Supporting Supervision - Q5 */}
                  {/* Defining and Supporting Supervision - Q6 */}
                  <SurveyButtonGroup
                    qNumber="Q6"
                    qText="Could be reached in times of emergency"
                    value={formData[1].data[5].value}
                    formDataID={1}
                    dataID={5}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Defining and Supporting Supervision - Q6 */}
                  {/* Defining and Supporting Supervision - Q7 */}
                  <SurveyButtonGroup
                    qNumber="Q7"
                    qText="Maintained an appropriate focus in supervisory sessions"
                    value={formData[1].data[6].value}
                    formDataID={1}
                    dataID={6}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Defining and Supporting Supervision - Q7 */}
                  {/* Defining and Supporting Supervision - Q8 */}
                  <SurveyButtonGroup
                    qNumber="Q8"
                    qText="Was clear about the differences between supervision and psychotherapy"
                    value={formData[1].data[7].value}
                    formDataID={1}
                    dataID={7}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Defining and Supporting Supervision - Q8 */}
                  {/* Defining and Supporting Supervision - Q9 */}
                  <SurveyButtonGroup
                    qNumber="Q9"
                    qText="Was available outside regular supervision time when questions / issues
                          emerged"
                    value={formData[1].data[8].value}
                    formDataID={1}
                    dataID={8}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Defining and Supporting Supervision - Q9 */}
                  {/* Defining and Supporting Supervision - Q10 */}
                  <SurveyButtonGroup
                    qNumber="Q10"
                    qText="Helped navigate the dynamics / culture of the site"
                    value={formData[1].data[9].value}
                    formDataID={1}
                    dataID={9}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Defining and Supporting Supervision - Q17 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="s1-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        {/* // Defining and Supporting Supervision */}
      </div>
    );
  }

  renderAppropriateGuidance() {
    const { formData } = this.state;

    return (
      <div>
        {/* Appropriate Guidance */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Appropriate Guidance
                </h5>
              }
            >
              <Row>
                <Col xs="12" md={{ size: 8, offset: 2 }}>
                  <Alert color="primary">
                    <p>For the next set of questions, please use the following guidelines:</p>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11}>Far below expectations - poor, a concern</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11}>Below expectations - marginal</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11}>Met expectations - satisfactory</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11}>Above expectations - good</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11}>Far above expectations - excellent</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11}>
                        Not applicable or not enough information to form a judgement
                      </Col>
                    </Row>
                    <p className="mt-4">
                      <strong className="text-danger">
                        All information reported on this form will be submitted anonymously and is
                        not tied to any identifiable student information. Data will only be reported
                        in aggregate.
                      </strong>
                    </p>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Appropriate Guidance - Q11 */}
                  <SurveyButtonGroup
                    qNumber="Q11"
                    qText="Provided guidance appropriate to your level of competence"
                    value={formData[2].data[0].value}
                    formDataID={2}
                    dataID={0}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Appropriate Guidance - Q11 */}
                  {/* Appropriate Guidance - Q12 */}
                  <SurveyButtonGroup
                    qNumber="Q12"
                    qText="Was able to recognize and accommodate your level of experience and style
                          of learning"
                    value={formData[2].data[1].value}
                    formDataID={2}
                    dataID={1}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Appropriate Guidance - Q12 */}
                  {/* Appropriate Guidance - Q13 */}
                  <SurveyButtonGroup
                    qNumber="Q13"
                    qText="Was flexible and responsive to your changing needs"
                    value={formData[2].data[2].value}
                    formDataID={2}
                    dataID={2}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Appropriate Guidance - Q13 */}
                  {/* Appropriate Guidance - Q14 */}
                  <SurveyButtonGroup
                    qNumber="Q14"
                    qText="Helped clarify and define the nature of problem(s) you are having at work"
                    value={formData[2].data[3].value}
                    formDataID={2}
                    dataID={3}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Appropriate Guidance - Q14 */}
                  {/* Appropriate Guidance - Q15 */}
                  <SurveyButtonGroup
                    qNumber="Q15"
                    qText="Encouraged you to formulate your understanding of the case material"
                    value={formData[2].data[4].value}
                    formDataID={2}
                    dataID={4}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Appropriate Guidance - Q15 */}
                  {/* Appropriate Guidance - Q16 */}
                  <SurveyButtonGroup
                    qNumber="Q16"
                    qText="Encouraged you to explore the implications of your interventions"
                    value={formData[2].data[5].value}
                    formDataID={2}
                    dataID={5}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Appropriate Guidance - Q16 */}
                  {/* Appropriate Guidance - Q17 */}
                  <SurveyButtonGroup
                    qNumber="Q17"
                    qText="Supported your exploring how diversity affects conceptualization and
                          intervention"
                    value={formData[2].data[6].value}
                    formDataID={2}
                    dataID={6}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Appropriate Guidance - Q17 */}
                  {/* Appropriate Guidance - Q18 */}
                  <SurveyButtonGroup
                    qNumber="Q18"
                    qText="Exposed you to relevant reading materials / research"
                    value={formData[2].data[7].value}
                    formDataID={2}
                    dataID={7}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Appropriate Guidance - Q18 */}
                  {/* Appropriate Guidance - Q19 */}
                  <SurveyButtonGroup
                    qNumber="Q19"
                    qText="Provided general knowledge about the psychology profession"
                    value={formData[2].data[8].value}
                    formDataID={2}
                    dataID={8}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Appropriate Guidance - Q19 */}
                  {/* Appropriate Guidance - Q20 */}
                  <SurveyButtonGroup
                    qNumber="Q20"
                    qText="Clearly informed you of the legal and ethical issues relevant to clinical
                          work at your site"
                    value={formData[2].data[9].value}
                    formDataID={2}
                    dataID={9}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Appropriate Guidance - Q20 */}
                  {/* Appropriate Guidance - Q21 */}
                  <SurveyButtonGroup
                    qNumber="Q21"
                    qText="Addressed legal and ethical issues as they emerged"
                    value={formData[2].data[10].value}
                    formDataID={2}
                    dataID={10}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Appropriate Guidance - Q21 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="s1-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        {/* // Appropriate Guidance */}
      </div>
    );
  }

  renderFeedback() {
    const { formData, feedbackComments } = this.state;

    return (
      <div>
        {/* Feedback */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Feedback
                </h5>
              }
            >
              <Row>
                <Col xs="12" md={{ size: 8, offset: 2 }}>
                  <Alert color="primary">
                    <p>For the next set of questions, please use the following guidelines:</p>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11}>Far below expectations - poor, a concern</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11}>Below expectations - marginal</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11}>Met expectations - satisfactory</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11}>Above expectations - good</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11}>Far above expectations - excellent</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11}>
                        Not applicable or not enough information to form a judgement
                      </Col>
                    </Row>
                    <p className="mt-4">
                      <strong className="text-danger">
                        All information reported on this form will be submitted anonymously and is
                        not tied to any identifiable student information. Data will only be reported
                        in aggregate.
                      </strong>
                    </p>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Feedback - Q22 */}
                  <SurveyButtonGroup
                    qNumber="Q22"
                    qText="Provided constructive, concrete and specific feedback about strengths"
                    value={formData[3].data[0].value}
                    formDataID={3}
                    dataID={0}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Feedback - Q22 */}
                  {/* Feedback - Q23 */}
                  <SurveyButtonGroup
                    qNumber="Q23"
                    qText="Provided constructive, concrete and specific feedback about areas in which
                          further development was needed"
                    value={formData[3].data[1].value}
                    formDataID={3}
                    dataID={1}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Feedback - Q23 */}
                  {/* Feedback - Q24 */}
                  <SurveyButtonGroup
                    qNumber="Q24"
                    qText="Used live observation or video / audio recordings of sessions as the basis
                          for supervision at least twice during the practicum year"
                    value={formData[3].data[2].value}
                    formDataID={3}
                    dataID={2}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Feedback - Q24 */}
                  {/* Feedback - Q25 */}
                  <SurveyButtonGroup
                    qNumber="Q25"
                    qText="The direct observation was provided by the licensed supervisor who is
                          legally responsible for your training (this is an APA requirement)"
                    value={formData[3].data[3].value}
                    formDataID={3}
                    dataID={3}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Feedback - Q25 */}
                  {/* Feedback - Q26 */}
                  <SurveyButtonGroup
                    qNumber="Q26"
                    qText="Used role play, observation of sessions, review of video, clinical case
                          notes or other appropriate techniques to help you more effectively
                          intervene with clients"
                    value={formData[3].data[4].value}
                    formDataID={3}
                    dataID={4}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Feedback - Q26 */}
                  {/* Feedback - Q27 */}
                  <SurveyButtonGroup
                    qNumber="Q27"
                    qText="Provided opportunities for you to question, challenge or doubt"
                    value={formData[3].data[5].value}
                    formDataID={3}
                    dataID={5}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Feedback - Q27 */}
                  {/* Feedback - Q28 */}
                  <SurveyButtonGroup
                    qNumber="Q28"
                    qText="Was open to feedback from you about the supervision"
                    value={formData[3].data[6].value}
                    formDataID={3}
                    dataID={6}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Feedback - Q28 */}
                  {/* Feedback - Q29 */}
                  <SurveyButtonGroup
                    qNumber="Q29"
                    qText="Was open to discussing any difficulties in the supervisor / supervisee
                          relationship that were hindering your learning"
                    value={formData[3].data[7].value}
                    formDataID={3}
                    dataID={7}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Feedback - Q29 */}
                  {/* Feedback - Q30 */}
                  <FormGroup className="mt-4">
                    <Row>
                      <Col sm={12} className="text-center">
                        <Label>
                          <b>Q30</b> Please elaborate on any of the rankings. In particular, please
                          provide more details on any of the ratings of <strong>1</strong> or{' '}
                          <strong>5</strong> and provide any other information that you think may be
                          helpful to LLU or students considering this site as a possible training
                          placement.
                        </Label>
                        <br />
                        <Input
                          style={{
                            width: '75%',
                            display: 'block',
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                          }}
                          id="feedbackComments"
                          rows="4"
                          type="textarea"
                          name="feedbackComments"
                          value={feedbackComments}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* // Feedback - Q30 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="s1-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        {/* Feedback */}
      </div>
    );
  }

  renderSiteInformation() {
    const { formData, siteComments } = this.state;

    return (
      <div>
        {/* Site Information */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Site Information
                </h5>
              }
            >
              <Row>
                <Col xs="12" md={{ size: 8, offset: 2 }}>
                  <Alert color="primary">
                    <p>For the next set of questions, please use the following guidelines:</p>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11}>No emphasis</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11}>Slight emphasis</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11}>Moderate emphasis</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11}>Strong emphasis</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11}>Significant emphasis</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11}>
                        Not applicable or not enough information to form a judgement
                      </Col>
                    </Row>
                    <p className="mt-4">
                      <strong className="text-danger">
                        All information reported on this form will be submitted anonymously and is
                        not tied to any identifiable student information. Data will only be reported
                        in aggregate.
                      </strong>
                    </p>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Site Information - Q31 */}
                  <SurveyButtonGroup
                    qNumber="Q31"
                    qText="Opportunities for individual therapy experience"
                    value={formData[4].data[0].value}
                    formDataID={4}
                    dataID={0}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q31 */}
                  {/* Site Information - Q32 */}
                  <SurveyButtonGroup
                    qNumber="Q32"
                    qText="Opportunities for group therapy experience"
                    value={formData[4].data[1].value}
                    formDataID={4}
                    dataID={1}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q32 */}
                  {/* Site Information - Q33 */}
                  <SurveyButtonGroup
                    qNumber="Q33"
                    qText="Opportunities for assessment and diagnostic experience"
                    value={formData[4].data[2].value}
                    formDataID={4}
                    dataID={2}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q33 */}
                  {/* Site Information - Q34 */}
                  <SurveyButtonGroup
                    qNumber="Q34"
                    qText="Opportunities for research experience"
                    value={formData[4].data[3].value}
                    formDataID={4}
                    dataID={3}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q34 */}
                  {/* Site Information - Q35 */}
                  <SurveyButtonGroup
                    qNumber="Q35"
                    qText="On-site didactic training experience"
                    value={formData[4].data[4].value}
                    formDataID={4}
                    dataID={4}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q35 */}
                  {/* Site Information - Q36 */}
                  <SurveyButtonGroup
                    qNumber="Q36"
                    qText="Opportunities for working with underprivileged / underserved populations"
                    value={formData[4].data[5].value}
                    formDataID={4}
                    dataID={5}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q36 */}
                  {/* Site Information - Q37 */}
                  <SurveyButtonGroup
                    qNumber="Q37"
                    qText="Opportunities for working with a range of client populations"
                    value={formData[4].data[6].value}
                    formDataID={4}
                    dataID={6}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q37 */}
                  {/* Site Information - Q38 */}
                  <SurveyButtonGroup
                    qNumber="Q38"
                    qText="Opportunities for working with a range of client diagnostic presentations"
                    value={formData[4].data[7].value}
                    formDataID={4}
                    dataID={7}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q38 */}
                  {/* Site Information - Q39 */}
                  <SurveyButtonGroup
                    qNumber="Q39"
                    qText="Opportunities to interact with and learn from other practicum students"
                    value={formData[4].data[8].value}
                    formDataID={4}
                    dataID={8}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q39 */}
                  {/* Site Information - Q40 */}
                  <SurveyButtonGroup
                    qNumber="Q40"
                    qText="Created a welcoming environment for practicum students"
                    value={formData[4].data[9].value}
                    formDataID={4}
                    dataID={9}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q40 */}
                  {/* Site Information - Q41 */}
                  <SurveyButtonGroup
                    qNumber="Q41"
                    qText="Attention to appropriate match of clinical caseload for level of training"
                    value={formData[4].data[10].value}
                    formDataID={4}
                    dataID={10}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q41 */}
                  {/* Site Information - Q42 */}
                  <SurveyButtonGroup
                    qNumber="Q42"
                    qText="Maintained agreement on number of clinical hours / number of hours in
                          general at the site"
                    value={formData[4].data[11].value}
                    formDataID={4}
                    dataID={11}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q42 */}
                  {/* Site Information - Q43 */}
                  <SurveyButtonGroup
                    qNumber="Q43"
                    qText="Focused on learning about improving communication with and among site
                          staff"
                    value={formData[4].data[12].value}
                    formDataID={4}
                    dataID={12}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q43 */}
                  {/* Site Information - Q44 */}
                  <SurveyButtonGroup
                    qNumber="Q44"
                    qText="Opportunities for gaining in-depth experience with a particular client
                          diagnostic presentation (please specify in comments)"
                    value={formData[4].data[13].value}
                    formDataID={4}
                    dataID={13}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* //  Site Information - Q44 */}
                  {/* Site Information - Q45 */}
                  <FormGroup className="mt-4">
                    <Row>
                      <Col sm={12} className="text-center">
                        <Label>
                          <b>Q45</b> Please elaborate on any of the rankings. In particular, please
                          provide more details on any of the ratings of <strong>1</strong> or{' '}
                          <strong>5</strong> and provide any other information that you think may be
                          helpful to LLU or students considering this site as a possible training
                          placement.
                        </Label>
                        <br />
                        <Input
                          style={{
                            width: '75%',
                            display: 'block',
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                          }}
                          id="siteComments"
                          rows="4"
                          type="textarea"
                          name="siteComments"
                          value={siteComments}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* //  Site Information - Q45 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="s3-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s3-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s3-b-submit"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'completed')}
                      >
                        Submit
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        {/* // Site Information */}
      </div>
    );
  }

  // eslint-disable-next-line consistent-return
  render() {
    const { loading, error, currentSection, studentName } = this.state;

    if (!loading && !error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Row>
                <Col xs="12">
                  <div className="crumbs">
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link to="/profile">Profile</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Practicum Site Evaluation</BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </Col>
              </Row>
              <h2 className="page-title">Practicum Site Evaluation: {studentName}</h2>
              {currentSection === 0 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={0} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 0 && this.renderGeneralSiteInformation()}
              {currentSection === 1 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={4} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 1 && this.renderSupportingSupervision()}
              {currentSection === 2 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={26} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 2 && this.renderAppropriateGuidance()}
              {currentSection === 3 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={49} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 3 && this.renderFeedback()}
              {currentSection === 4 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={68} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 4 && this.renderSiteInformation()}
              {/* {currentSection === 999 && this.renderReadout()} */}
            </Col>
          </Row>
        </div>
      );
    }
    if (loading) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <h2 className="page-title">Practicum Site Evaluation</h2>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
            </Col>
          </Row>
        </div>
      );
    }
    if (error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Widget>
                <Row className="mt-4 mb-4 text-center">
                  <Col>
                    <h4>
                      There was an error loading the form, please reload the page and try again.
                    </h4>
                  </Col>
                </Row>
              </Widget>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

PSE.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(PSE);
