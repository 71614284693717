/* eslint-disable prefer-destructuring */
/* eslint no-nested-ternary: "off" */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../../Auth/Auth';
import Widget from '../../Widget/Widget';

import './ASEStudentReadout.scss';
import '../forms.scss';

import loadingSpinner from '../../../images/Eclipse-1s-200px.svg';

class ASEStudentReadout extends React.Component {
  constructor(props) {
    super(props);

    this.getForm = this.getForm.bind(this);

    this.state = {
      formData: [],
      GPA: '0.00',
      academicComments: '',
      researchComments: '',
      clinicalComments: '',
      professionalismComments: '',
      studentName: '',
      currentProgram: '',
      loading: true,
    };
  }

  componentDidMount() {
    this.getForm();
  }

  getForm() {
    const { match } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/form/${match.params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        // check if data is valid
        const { data, status, student } = response.data;
        const studentName = `${student.firstName} ${student.lastName}`;

        let {
          formData,
          GPA,
          academicComments,
          researchComments,
          clinicalComments,
          professionalismComments,
        } = this.state;

        // check if data was returned in a array
        if (data.length === 3) {
          formData = data;
        } else {
          formData = data[0];
        }

        // set GPA
        GPA = formData[0].data[7].value;

        // set comments
        academicComments = formData[2].data[0].value;
        researchComments = formData[2].data[1].value;
        clinicalComments = formData[2].data[2].value;
        professionalismComments = formData[2].data[3].value;

        // if form is completed check comments and set to 'No Comments' if empty
        if (status === 'completed') {
          if (academicComments === '') {
            academicComments = 'No Comments';
          }
          if (researchComments === '') {
            researchComments = 'No Comments';
          }
          if (clinicalComments === '') {
            clinicalComments = 'No Comments';
          }
          if (professionalismComments === '') {
            professionalismComments = 'No Comments';
          }
        }

        this.setState({
          formData,
          GPA,
          academicComments,
          researchComments,
          clinicalComments,
          professionalismComments,
          studentName,
          currentProgram: student.currentProgram,
          loading: false,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  renderStudentReadout() {
    const {
      formData,
      GPA,
      academicComments,
      researchComments,
      clinicalComments,
      professionalismComments,
      currentProgram,
    } = this.state;

    return (
      <div>
        {/* Student Ratings */}
        <Row>
          <Col xs={12}>
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Student Ratings{' '}
                </h5>
              }
            >
              {/* this alert only displays in the active form, not the readout  */}

              <Row className="mt-4">
                <Col xs={12}>
                  <Alert color="primary">
                    {/* <p>For the next set of questions, please use the following guidelines:</p> */}
                    <Row>
                      <Col xs={10}>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>Rating</strong>
                          </Col>
                          <Col sm={3}>
                            <strong>Evaluation</strong>
                          </Col>
                          <Col sm={7}>
                            <strong>Academic Rating</strong>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>1</strong>
                          </Col>
                          <Col sm={3}>Unacceptable</Col>
                          <Col sm={7}>GPA below 3</Col>
                        </Row>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>2</strong>
                          </Col>
                          <Col sm={3}>Below expectations</Col>
                          <Col sm={7}>GPA below 3.5</Col>
                        </Row>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>3</strong>
                          </Col>
                          <Col sm={3}>Meets expectations</Col>
                          <Col sm={7}>GPA equal to 3.5 - 3.69</Col>
                        </Row>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>4</strong>
                          </Col>
                          <Col sm={3}>Exceeds expectations</Col>
                          <Col sm={7}>GPA equal to 3.7 - 3.89</Col>
                        </Row>
                        <Row>
                          <Col sm={2} className="text-center">
                            <strong>5</strong>
                          </Col>
                          <Col sm={3}>Outstanding</Col>
                          <Col sm={7}>GPA equal to / greater than 3.9</Col>
                        </Row>
                      </Col>
                      <Col xs={2} className="text-center">
                        <div className="GPA">
                          GPA
                          <h1>{GPA}</h1>
                        </div>
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* Student Ratings - Q1 */}
              <Row>
                <Col xs={12} className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q1</b>
                    </Label>{' '}
                    Suggested overall academic rating:{' '}
                    <b className="ml-3">
                      {formData[1].data[0].value === '' || formData[1].data[0].value === 0
                        ? formData[1].data[0].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings - Q1 */}
              {/* Student Academic Ratings Comments */}
              <Row>
                <Col xs={12} className="text-center">
                  <Label>
                    <u>
                      <b>Academic Rating Comments</b>
                    </u>
                  </Label>
                  <p className="readoutComments">{academicComments}</p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Academic Ratings Comments */}
              {/* Student Ratings - Q2 */}
              <Row>
                <Col xs={12} className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q2</b>
                    </Label>{' '}
                    Suggested overall research rating:{' '}
                    <b className="ml-3">
                      {formData[1].data[1].value === '' || formData[1].data[1].value === 0
                        ? formData[1].data[1].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings - Q2 */}
              {currentProgram === 'Ph.D.' && (
                <div>
                  {/* Student Ratings - Q3 - Ph.D. Only */}
                  <Row>
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q3</b>
                        </Label>{' '}
                        Student&apos;s commitment to research lab:{' '}
                        <b className="ml-3">
                          {formData[1].data[2].value === '' || formData[1].data[2].value === 0
                            ? formData[1].data[2].value === ''
                              ? 'Unrated'
                              : 'N/A'
                            : formData[1].data[2].value}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                  {/* // Student Ratings - Q3 - Ph.D. Only */}
                  {/* Student Ratings - Q4 - Ph.D. Only */}
                  <Row>
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q4</b>
                        </Label>{' '}
                        Student&apos;s interest and motivation to conduct research:{' '}
                        <b className="ml-3">
                          {formData[1].data[3].value === '' || formData[1].data[3].value === 0
                            ? formData[1].data[3].value === ''
                              ? 'Unrated'
                              : 'N/A'
                            : formData[1].data[3].value}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                  {/* // Student Ratings - Q4 - Ph.D. Only */}
                  {/* Student Ratings - Q5 - Ph.D. Only */}
                  <Row>
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <b>Q5</b>
                        </Label>{' '}
                        Student engagement in activities that will increase higher research
                        productivity:{' '}
                        <b className="ml-3">
                          {formData[1].data[4].value === '' || formData[1].data[4].value === 0
                            ? formData[1].data[4].value === ''
                              ? 'Unrated'
                              : 'N/A'
                            : formData[1].data[4].value}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                  {/* // Student Ratings - Q5 - Ph.D. Only */}
                </div>
              )}
              {currentProgram === 'Psy.D.' && (
                <div>
                  <Row className="justify-content-center">
                    <Col sm={10} className="text-center">
                      <Label>
                        <b>Q3 through Q5 are Ph.D. only</b>
                      </Label>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                </div>
              )}
              {/* Student Ratings - Q6 */}
              <Row>
                <Col xs={12} className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q6</b>
                    </Label>{' '}
                    Student&apos;s commitment to the degree model:{' '}
                    <b className="ml-3">
                      {formData[1].data[5].value === '' || formData[1].data[5].value === 0
                        ? formData[1].data[5].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[5].value}
                    </b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings - Q6 */}
              {/* Student Ratings Research Comments */}
              <Row>
                <Col xs={12} className="text-center">
                  <Label>
                    <u>
                      <b>Research Rating Comments</b>
                    </u>
                  </Label>
                  <p className="readoutComments">{researchComments}</p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings Research Comments */}
              {/* Student Ratings - Q7 */}
              <Row>
                <Col xs={12} className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q7</b>
                    </Label>{' '}
                    Suggested overall clinical rating:{' '}
                    <b className="ml-3">
                      {formData[1].data[6].value === '' || formData[1].data[6].value === 0
                        ? formData[1].data[6].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[6].value}
                    </b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings - Q7 */}
              {/* Student Ratings Clinical Comments */}
              <Row>
                <Col xs={12} className="text-center">
                  <Label>
                    <u>
                      <b>Clinical Rating Comments</b>
                    </u>
                  </Label>
                  <p className="readoutComments">{clinicalComments}</p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings Clinical Comments */}
              {/* Student Ratings - Q8 */}
              <Row>
                <Col xs={12} className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q8</b>
                    </Label>{' '}
                    Suggested overall professionalism rating:{' '}
                    <b className="ml-3">
                      {formData[1].data[7].value === '' || formData[1].data[7].value === 0
                        ? formData[1].data[7].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[7].value}
                    </b>
                  </p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings - Q8 */}
              {/* Student Ratings Professionalism Comments */}
              <Row>
                <Col xs={12} className="text-center">
                  <Label>
                    <u>
                      <b>Professionalism Rating Comments</b>
                    </u>
                  </Label>
                  <p className="readoutComments">{professionalismComments}</p>
                </Col>
              </Row>
              <p>
                <br />
              </p>
              {/* // Student Ratings Professionalism Comments */}
              <FormGroup>
                <Form>
                  <FormGroup row className="submitBlock">
                    <Col xs={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="readout-b-profile"
                        color="info"
                        tag={Link}
                        to="/profile"
                      >
                        My Account
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  render() {
    const { loading, studentName } = this.state;
    if (!loading) {
      return (
        <div>
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              <Row>
                <Col xs="12">
                  <div className="crumbs">
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link to="/profile">Profile</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Annual Student Evaluation</BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </Col>
              </Row>
              <h2 className="page-title mt-2">Annual Student Evaluation: {studentName}</h2>
              {this.renderStudentReadout()}
            </Col>
          </Row>
        </div>
      );
    }
    return (
      <div>
        <Row>
          <Col xs={{ size: 10, offset: 1 }}>
            <h2 className="page-title mt-2">Annual Student Evaluation</h2>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
          </Col>
        </Row>
      </div>
    );
  }
}

ASEStudentReadout.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(ASEStudentReadout);
