/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import auth0Client from '../../Auth/Auth';

import loadingSpinner from '../../images/Eclipse-1s-200px.svg';

class Callback extends Component {
  async componentDidMount() {
    await auth0Client.handleAuthentication();
    this.props.history.replace('/');
  }

  render() {
    return (
      <Row className="text-center">
        <Col>
          <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
        </Col>
      </Row>
    );
  }
}

Callback.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Callback);
