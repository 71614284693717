/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Progress,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  ButtonGroup,
  Form,
  FormGroup,
  FormFeedback,
  // FormText,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../../Auth/Auth';

import Widget from '../../Widget/Widget';

import '../forms.scss';

import loadingSpinner from '../../../images/Eclipse-1s-200px.svg';

class SPR extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.onUploadFileBtnClick = this.onUploadFileBtnClick.bind(this);
    this.onAddTextArrayBtnClick = this.onAddTextArrayBtnClick.bind(this);
    this.onRemoveTextArrayBtnClick = this.onRemoveTextArrayBtnClick.bind(this);
    this.onFrmBtnClick = this.onFrmBtnClick.bind(this);
    this.onFrmBtnToggle = this.onFrmBtnToggle.bind(this);
    this.onSectionBtnClick = this.onSectionBtnClick.bind(this);
    this.getUploadedFiles = this.getUploadedFiles.bind(this);
    this.getForm = this.getForm.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.validateGPA = this.validateGPA.bind(this);
    this.renderUploadedFiles = this.renderUploadedFiles.bind(this);
    this.renderProfessionalSocieties = this.renderProfessionalSocieties.bind(this);
    this.renderLeadershipRoles = this.renderLeadershipRoles.bind(this);
    this.renderPsychologicalTopics = this.renderPsychologicalTopics.bind(this);
    this.renderEnrollmentInformation = this.renderEnrollmentInformation.bind(this);
    this.renderCoursework = this.renderCoursework.bind(this);
    this.renderProfessionalActivities = this.renderProfessionalActivities.bind(this);
    this.renderDoctoralProject = this.renderDoctoralProject.bind(this);
    this.renderDissertationQuestions = this.renderDissertationQuestions.bind(this);
    this.renderDoctoralInternship = this.renderDoctoralInternship.bind(this);
    this.renderReadout = this.renderReadout.bind(this);

    this.state = {
      formData: [
        {
          id: 'Enrollment Information',
          data: [
            {
              id: 'DateStartedProgram',
              value: '',
            },
            {
              id: 'AcademicStatus',
              value: '',
            },
            {
              id: 'DateLeftProgram',
              value: '',
            },
            {
              id: 'ExpectedReturnDate',
              value: '',
            },
            {
              id: 'ReasonLeftProgram',
              value: '',
            },
            {
              id: 'OtherReasonLeftProgram',
              value: '',
            },
          ],
        },
        {
          id: 'Coursework',
          data: [
            {
              id: 'CurrentGPA',
              value: '',
            },
            {
              id: 'AuthorOfPosterPresentation',
              value: '',
            },
            {
              id: 'AuthorOfPublishedPaper',
              value: '',
            },
            {
              id: 'IsTeachingAssistant',
              value: '',
            },
            {
              id: 'TeachingAssistantCourses',
              value: '',
            },
          ],
        },
        {
          id: 'Professional Activities',
          data: [
            {
              id: 'MemberOfProfessionalSociety',
              value: '',
            },
            {
              id: 'ProfessionalSocieties',
              value: '',
            },
            {
              id: 'InvolvedInLeadershipRoles',
              value: '',
            },
            {
              id: 'LeadershipRoles',
              value: '',
            },
            {
              id: 'PresentedPsychologicalTopic',
              value: '',
            },
            {
              id: 'PsychologicalTopics',
              value: '',
            },
          ],
        },
        {
          id: 'Doctoral Project',
          data: [
            {
              id: 'PassedDoctoralProjectDefense',
              value: '',
            },
            {
              id: 'DoctoralProjectTitle',
              value: '',
            },
            {
              id: 'PassedDate',
              value: '',
            },
            {
              id: 'CommitteePassedProposal',
              value: '',
            },
            {
              id: 'ProposalPassedDate',
              value: '',
            },
            {
              id: 'CommitteeMembers',
              value: '',
            },
            {
              id: 'StartedWorking',
              value: '',
            },
            {
              id: 'IdentifiedFacultyChair',
              value: '',
            },
            {
              id: 'IdentifiedTopicArea',
              value: '',
            },
            {
              id: 'SettledOnGeneralDesignPlan',
              value: '',
            },
            {
              id: 'StartedWritingProposal',
              value: '',
            },
          ],
        },
        {
          id: 'Dissertation',
          data: [
            {
              id: 'PassedDissertationDefense',
              value: '',
            },
            {
              id: 'DissertationProjectTitle',
              value: '',
            },
            {
              id: 'PassedDate',
              value: '',
            },
            {
              id: 'CommitteePassedProposal',
              value: '',
            },
            {
              id: 'ProposalPassedDate',
              value: '',
            },
            {
              id: 'CommitteeMembers',
              value: '',
            },
            {
              id: 'StartedWritingResults',
              value: '',
            },
            {
              id: 'FinishedDataCollection',
              value: '',
            },
            {
              id: 'StartedDataCollection',
              value: '',
            },
            {
              id: 'IdentifiedFacultyChair',
              value: '',
            },
            {
              id: 'IdentifiedTopicArea',
              value: '',
            },
            {
              id: 'SettledOnGeneralDesignPlan',
              value: '',
            },
            {
              id: 'StartedWritingProposal',
              value: '',
            },
          ],
        },
        {
          id: 'Doctoral Internship',
          data: [
            {
              id: 'TakenEPPP',
              value: '',
            },
            {
              id: 'ComprehensiveExamDate',
              value: '',
            },
            {
              id: 'ComprehensiveExamScore',
              value: '',
            },
            {
              id: 'ApplyForInternship',
              value: '',
            },
            {
              id: 'ObtainInternship',
              value: '',
            },
            {
              id: 'InternshipName',
              value: '',
            },
            {
              id: 'APA_Accredited',
              value: '',
            },
            {
              id: 'AccreditedByAnotherBody',
              value: '',
            },
            {
              id: 'AccreditingBodyName',
              value: '',
            },
          ],
        },
      ],
      dateLeftProgram: '',
      expectedReturnDate: '',
      reasonLeftProgramSelect: 'Successful completion of program',
      otherReasonLeftProgram: '',
      GPA: '',
      gpaError: false,
      gpaErrorMsg: '',
      teachingAssistantClassSelect: 'PSYC 501 Advanced Statistics I',
      teachingAssistantClasses: [],
      professionalSocietyInput: '',
      professionalSocieties: [],
      leadershipRoleInput: '',
      leadershipRoles: [],
      psychologicalTopicInput: '',
      psychologicalTopics: [],
      doctoralProjectTitle: '',
      doctoralCommitteeMemberInput: '',
      doctoralCommitteeMembers: [],
      doctoralDefensePassedDate: '',
      doctoralProposalPassedDate: '',
      dissertationProjectTitle: '',
      dissertationCommitteeMemberInput: '',
      dissertationCommitteeMembers: [],
      dissertationPassedDate: '',
      dissertationProposalPassedDate: '',
      comprehensiveExamDate: '',
      comprehensiveExamScore: '',
      internshipNameInput: '',
      accreditingBodyNameInput: '',
      inputKey: Date.now(),
      selectedFile: null,
      uploadPercentage: 0,
      uploadedFiles: [],
      status: '',
      studentID: '',
      studentName: '',
      program: '',
      currentSection: 0,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.getForm();
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleFileChange(e) {
    this.setState({
      selectedFile: e.target.files[0],
      uploadPercentage: 0,
    });
  }

  onUploadFileBtnClick(e, uploadType) {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/file`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const { studentID, selectedFile } = this.state;

    const data = new FormData();

    data.append('form', params.formid);
    data.append('student', studentID);
    data.append('uploadType', uploadType);
    data.append('file', selectedFile);

    axios
      .post(url, data, config, {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            uploadPercentage: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then(() => {
        this.setState({ inputKey: Date.now(), selectedFile: null });
        this.getUploadedFiles();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  onAddTextArrayBtnClick(e, textInput, arrayName) {
    this.setState({
      [arrayName]: [...this.state[arrayName], textInput],
    });
  }

  onRemoveTextArrayBtnClick(e, arrayName, idx) {
    const newArray = [...this.state[arrayName]];
    newArray.splice(idx, 1);
    this.setState({
      [arrayName]: newArray,
    });
  }

  onFrmBtnClick(sectionID, questionID, a, sA) {
    /* a === answer w/ 3 args, subID w/ 4 args
      sA === answer w/ 4 args */
    const newState = {
      ...this.state.formData,
    };

    if (arguments.length === 3) {
      newState[sectionID].data[questionID].value = a;
    } else if (arguments.length === 4) {
      newState[sectionID].data[questionID].data[a].value = sA;
    }

    this.setState({
      formData: newState,
    });
  }

  onFrmBtnToggle(sectionID, questionID, subQuestionID) {
    const newState = {
      ...this.state.formData,
    };

    if (arguments.length === 2) {
      newState[sectionID].data[questionID].value *= -1;
    } else if (arguments.length === 3) {
      newState[sectionID].data[questionID].value[subQuestionID].value *= -1;
    }

    this.setState({
      formData: newState,
    });
  }

  onSectionBtnClick(e, sectionValue) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const newSectionID = this.state.currentSection + sectionValue;

    this.setState({
      currentSection: newSectionID,
    });
  }

  getUploadedFiles() {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/file/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        this.setState({ uploadedFiles: response.data, loading: false });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  getForm() {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/form/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        // check if data is valid
        const { data, status, student } = response.data;
        const { currentProgram, _id } = student;
        const studentName = `${student.firstName} ${student.lastName}`;

        if (status !== 'new') {
          let {
            formData,
            dateLeftProgram,
            expectedReturnDate,
            reasonLeftProgramSelect,
            otherReasonLeftProgram,
            GPA,
            teachingAssistantClasses,
            professionalSocieties,
            leadershipRoles,
            psychologicalTopics,
            doctoralProjectTitle,
            doctoralCommitteeMembers,
            doctoralDefensePassedDate,
            doctoralProposalPassedDate,
            dissertationProjectTitle,
            dissertationCommitteeMembers,
            dissertationPassedDate,
            dissertationProposalPassedDate,
            comprehensiveExamDate,
            comprehensiveExamScore,
            internshipNameInput,
            accreditingBodyNameInput,
            currentSection,
          } = this.state;

          // check if data was returned in a array
          if (data.length === 6) {
            formData = data;
          } else {
            // eslint-disable-next-line prefer-destructuring
            formData = data[0];
          }

          dateLeftProgram = formData[0].data[2].value;
          expectedReturnDate = formData[0].data[3].value;
          if (formData[0].data[4].value !== '') {
            reasonLeftProgramSelect = formData[0].data[4].value;
          }
          otherReasonLeftProgram = formData[0].data[5].value;
          GPA = formData[1].data[0].value;
          if (formData[1].data[4].value !== '') {
            teachingAssistantClasses = formData[1].data[4].value;
          }
          if (formData[2].data[1].value !== '') {
            professionalSocieties = formData[2].data[1].value;
          }
          if (formData[2].data[3].value !== '') {
            leadershipRoles = formData[2].data[3].value;
          }
          if (formData[2].data[5].value !== '') {
            psychologicalTopics = formData[2].data[5].value;
          }
          doctoralProjectTitle = formData[3].data[1].value;
          if (formData[3].data[5].value !== '') {
            doctoralCommitteeMembers = formData[3].data[5].value;
          }
          doctoralDefensePassedDate = formData[3].data[2].value;
          doctoralProposalPassedDate = formData[3].data[4].value;
          dissertationProjectTitle = formData[4].data[1].value;
          if (formData[4].data[5].value !== '') {
            dissertationCommitteeMembers = formData[4].data[5].value;
          }
          dissertationPassedDate = formData[4].data[2].value;
          dissertationProposalPassedDate = formData[4].data[4].value;
          comprehensiveExamDate = formData[5].data[1].value;
          comprehensiveExamScore = formData[5].data[2].value;
          internshipNameInput = formData[5].data[5].value;
          accreditingBodyNameInput = formData[5].data[8].value;

          // if form is cmmpleted, set currentSection to display readout
          if (status === 'completed') {
            currentSection = 999;
          }

          // form inProgress/completed, set student info and formData
          this.setState({
            formData,
            dateLeftProgram,
            expectedReturnDate,
            reasonLeftProgramSelect,
            otherReasonLeftProgram,
            GPA,
            teachingAssistantClasses,
            professionalSocieties,
            leadershipRoles,
            psychologicalTopics,
            doctoralProjectTitle,
            doctoralCommitteeMembers,
            doctoralDefensePassedDate,
            doctoralProposalPassedDate,
            dissertationProjectTitle,
            dissertationCommitteeMembers,
            dissertationPassedDate,
            dissertationProposalPassedDate,
            comprehensiveExamDate,
            comprehensiveExamScore,
            internshipNameInput,
            accreditingBodyNameInput,
            studentID: _id,
            studentName,
            program: currentProgram,
            status,
            currentSection,
          });
          this.getUploadedFiles();
        } else {
          // new form, set student info
          this.setState({
            studentID: _id,
            studentName,
            program: currentProgram,
          });
          this.getUploadedFiles();
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  saveForm(e, newStatus) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const {
      match: { params },
    } = this.props;
    // const url = 'https://llu-api.modemtones.com/form/update';
    const url = `${process.env.REACT_APP_API_URL}/form/update`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const id = params.formid;
    const {
      formData,
      dateLeftProgram,
      expectedReturnDate,
      reasonLeftProgramSelect,
      otherReasonLeftProgram,
      GPA,
      doctoralProjectTitle,
      doctoralDefensePassedDate,
      doctoralProposalPassedDate,
      dissertationProjectTitle,
      dissertationPassedDate,
      dissertationProposalPassedDate,
      comprehensiveExamDate,
      comprehensiveExamScore,
      internshipNameInput,
      accreditingBodyNameInput,
    } = this.state;

    let {
      teachingAssistantClasses,
      professionalSocieties,
      leadershipRoles,
      psychologicalTopics,
      doctoralCommitteeMembers,
      dissertationCommitteeMembers,
    } = this.state;

    // *Enrollment Information
    // clear sub-qs if AcademicStatus === 'Full-Time'
    if (formData[0].data[1].value === 'Full-Time') {
      formData[0].data[2].value = '';
      formData[0].data[3].value = '';
      formData[0].data[4].value = '';
      formData[0].data[5].value = '';
    } else if (
      formData[0].data[1].value === 'Leave of Absence' ||
      formData[0].data[1].value === 'Left Program'
    ) {
      // set sub-qs from inputs
      formData[0].data[2].value = dateLeftProgram;
      formData[0].data[3].value = expectedReturnDate;
      formData[0].data[4].value = reasonLeftProgramSelect;
      // set OtherReasonLeftProgram if reasonLeftProgramSelect === 'Other reasons'
      if (reasonLeftProgramSelect === 'Other reasons') {
        formData[0].data[5].value = otherReasonLeftProgram;
      } else {
        formData[0].data[5].value = '';
      }
    }

    // *Coursework
    // set GPA from input
    formData[1].data[0].value = GPA;
    // clear sub-qs if IsTeachingAssistant === 'No'
    if (formData[1].data[3].value === 'No') {
      formData[1].data[4].value = '';
      teachingAssistantClasses = [];
    } else {
      // set sub-qs from inputs
      formData[1].data[4].value = teachingAssistantClasses;
    }

    // *Professional Activities
    // clear sub-qs if MemberOfProfessionalSociety === 'No'
    if (formData[2].data[0].value === 'No') {
      formData[2].data[1].value = '';
      formData[2].data[2].value = '';
      formData[2].data[3].value = '';
      professionalSocieties = [];
      leadershipRoles = [];
    } else {
      // set sub-qs from inputs
      formData[2].data[1].value = professionalSocieties;
    }

    // clear sub-qs if InvolvedInLeadershipRoles === 'No'
    if (formData[2].data[2].value === 'No') {
      formData[2].data[3].value = '';
      leadershipRoles = [];
    } else {
      // set sub-qs from inputs
      formData[2].data[3].value = leadershipRoles;
    }

    // clear sub-qs if PresentedPsychologicalTopic === 'No'
    if (formData[2].data[4].value === 'No') {
      formData[2].data[5].value = '';
      psychologicalTopics = [];
    } else {
      // set sub-qs from inputs
      formData[2].data[5].value = psychologicalTopics;
    }

    // *Doctoral Project
    // clear sub-qs if PassedDoctoralProjectDefense === 'No' && CommitteePassedProposal === 'No'
    if (formData[3].data[0].value === 'No' && formData[3].data[3].value === 'No') {
      formData[3].data[1].value = '';
      formData[3].data[5].value = '';
      doctoralCommitteeMembers = [];
    } else {
      // set sub-qs from inputs
      formData[3].data[1].value = doctoralProjectTitle;
      formData[3].data[5].value = doctoralCommitteeMembers;
    }

    // clear sub qs and set values if PassedDoctoralProjectDefense === 'Yes'
    if (formData[3].data[0].value === 'Yes') {
      formData[3].data[2].value = doctoralDefensePassedDate;
      formData[3].data[3].value = '';
      formData[3].data[4].value = '';
      formData[3].data[6].value = '';
      formData[3].data[7].value = '';
      formData[3].data[8].value = '';
      formData[3].data[9].value = '';
      formData[3].data[10].value = '';
    } else {
      formData[3].data[2].value = '';
    }

    // clear sub qs and set values if CommitteePassedProposal === 'Yes'
    if (formData[3].data[3].value === 'Yes') {
      formData[3].data[4].value = doctoralProposalPassedDate;
      formData[3].data[7].value = '';
      formData[3].data[8].value = '';
      formData[3].data[9].value = '';
      formData[3].data[10].value = '';
    } else {
      formData[3].data[4].value = '';
    }

    // clear sub qs if IdentifiedFacultyChair === 'No'
    if (formData[3].data[7].value === 'No') {
      formData[3].data[8].value = '';
      formData[3].data[9].value = '';
      formData[3].data[10].value = '';
    }

    // clear sub qs if IdentifiedTopicArea === 'No'
    if (formData[3].data[8].value === 'No') {
      formData[3].data[9].value = '';
      formData[3].data[10].value = '';
    }

    // clear sub qs if SettledOnGeneralDesignPlan === 'No'
    if (formData[3].data[9].value === 'No') {
      formData[3].data[10].value = '';
    }

    // *Dissertation
    // clear sub-qs if PassedDissertationDefense === 'No' && CommitteePassedProposal === 'No'
    if (formData[4].data[0].value === 'No' && formData[3].data[3].value === 'No') {
      formData[4].data[1].value = '';
      formData[4].data[5].value = '';
      dissertationCommitteeMembers = [];
    } else {
      // set sub-qs from inputs
      formData[4].data[1].value = dissertationProjectTitle;
      formData[4].data[5].value = dissertationCommitteeMembers;
    }

    // clear sub qs and set values if PassedDissertationDefense === 'Yes'
    if (formData[4].data[0].value === 'Yes') {
      formData[4].data[2].value = dissertationPassedDate;
      formData[4].data[3].value = '';
      formData[4].data[4].value = '';
      formData[4].data[6].value = '';
      formData[4].data[7].value = '';
      formData[4].data[8].value = '';
      formData[4].data[9].value = '';
      formData[4].data[10].value = '';
      formData[4].data[11].value = '';
      formData[4].data[12].value = '';
    } else {
      formData[4].data[2].value = '';
    }

    // clear sub qs and set values if CommitteePassedProposal === 'Yes'
    if (formData[4].data[3].value === 'Yes') {
      formData[4].data[4].value = dissertationProposalPassedDate;
      formData[4].data[9].value = '';
      formData[4].data[10].value = '';
      formData[4].data[11].value = '';
      formData[4].data[12].value = '';
    } else {
      formData[4].data[4].value = '';
      formData[4].data[6].value = '';
      formData[4].data[7].value = '';
      formData[4].data[8].value = '';
    }

    // clear sub qs if StartedWritingResults === 'Yes'
    if (formData[4].data[6].value === 'Yes') {
      formData[4].data[7].value = '';
      formData[4].data[8].value = '';
    }

    // clear sub qs if FinishedDataCollection === 'Yes'
    if (formData[4].data[7].value === 'Yes') {
      formData[4].data[8].value = '';
    }

    // clear sub qs if IdentifiedFacultyChair === 'No'
    if (formData[4].data[9].value === 'No') {
      formData[4].data[10].value = '';
      formData[4].data[11].value = '';
      formData[4].data[12].value = '';
    }

    // clear sub qs if IdentifiedTopicArea === 'No'
    if (formData[4].data[10].value === 'No') {
      formData[4].data[11].value = '';
      formData[4].data[12].value = '';
    }

    // clear sub qs if SettledOnGeneralDesignPlan === 'No'
    if (formData[4].data[11].value === 'No') {
      formData[4].data[12].value = '';
    }

    // *Doctoral Internship
    // set values if TakenEPPP === 'Yes'
    if (formData[5].data[0].value === 'Yes') {
      formData[5].data[1].value = comprehensiveExamDate;
      formData[5].data[2].value = comprehensiveExamScore;
    } else {
      formData[5].data[1].value = '';
      formData[5].data[2].value = '';
    }

    // clear sub qs if ApplyForInternship === 'No'
    if (formData[5].data[3].value === 'No') {
      formData[5].data[4].value = '';
      formData[5].data[5].value = '';
      formData[5].data[6].value = '';
      formData[5].data[7].value = '';
      formData[5].data[8].value = '';
    }

    // clear sub qs if ObtainInternship === 'No'
    if (formData[5].data[4].value === 'No') {
      formData[5].data[5].value = '';
      formData[5].data[6].value = '';
      formData[5].data[7].value = '';
      formData[5].data[8].value = '';
    } else if (formData[5].data[4].value === 'Yes') {
      // set values if ObtainInternship === 'Yes'
      formData[5].data[5].value = internshipNameInput;
    }

    // clear sub qs if AAccreditedByAnotherBody === 'No'
    if (formData[5].data[7].value === 'No') {
      formData[5].data[8].value = '';
    } else if (formData[5].data[7].value === 'Yes') {
      // set values if AccreditedByAnotherBody === 'Yes'
      formData[5].data[8].value = accreditingBodyNameInput;
    }

    axios
      .post(url, { id, newStatus, formData }, config)
      .then((response) => {
        // update form status if results are valid
        const { status } = response.data;
        this.setState({ status });
        if (status === 'completed') {
          // eslint-disable-next-line react/prop-types
          this.props.history.push('/profile');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  validateGPA() {
    let gpaError = false;
    let gpaErrorMsg = '';
    let { GPA } = this.state;
    const legalValue = /^[0-4][.][0-9][0-9]$/;

    if (GPA.length === 3) {
      GPA = `${GPA}0`;
    }

    if (GPA === '') {
      gpaError = true;
      gpaErrorMsg = 'GPA cannot be blank.';
    } else if (!legalValue.test(GPA)) {
      gpaError = true;
      gpaErrorMsg = 'Please enter a valid 3 digit GPA';
    }

    this.setState({
      gpaError,
      gpaErrorMsg,
      GPA,
    });
  }

  renderUploadedFiles(uploadType) {
    const { uploadedFiles, status } = this.state;

    // get files for selected uploadType
    const filteredFiles = uploadedFiles.filter((val) => val.uploadType === uploadType);

    return (
      <div>
        <FormGroup className="submitBlock">
          <Row className="justify-content-center">
            <Col md={6} className="text-center">
              <Label>
                <i className="fa fa-chevron-right" /> Uploaded files
              </Label>
              {filteredFiles.map((file) => (
                <InputGroup key={file._id} className="mt-2">
                  <Input type="text" id="q5-s1-q1-t-file" placeholder={file.filename} disabled />
                  {/* <InputGroupAddon addonType="append">
                    <ButtonGroup>
                      <Button className="subQ-buttons" id="q5-s1-q1-b-fileView" color="success">
                        <i className="fa fa-eye" />
                      </Button>
                      <Button className="subQ-buttons" id="q5-s1-q1-b-fileDelete" color="danger">
                        <i className="fa fa-trash" />
                      </Button>
                    </ButtonGroup>
                  </InputGroupAddon> */}
                </InputGroup>
              ))}
              {/* <InputGroup>
                <Input type="text" id="q5-s1-q1-t-file" placeholder="my_file.docx" />
                <InputGroupAddon>
                  <ButtonGroup>
                    <Button className="subQ-buttons" id="q5-s1-q1-b-fileView" color="success">
                      <i className="fa fa-eye" />
                    </Button>
                    <Button className="subQ-buttons" id="q5-s1-q1-b-fileDelete" color="danger">
                      <i className="fa fa-trash" />
                    </Button>
                  </ButtonGroup>
                </InputGroupAddon>
              </InputGroup>
              <InputGroup className="mt-2">
                <Input type="text" id="q5-s1-q1-t-file" placeholder="my_file.docx" />
                <InputGroupAddon>
                  <ButtonGroup>
                    <Button className="subQ-buttons" id="q5-s1-q1-b-fileView" color="success">
                      <i className="fa fa-eye" />
                    </Button>
                    <Button className="subQ-buttons" id="q5-s1-q1-b-fileDelete" color="danger">
                      <i className="fa fa-trash" />
                    </Button>
                  </ButtonGroup>
                </InputGroupAddon>
              </InputGroup> */}
            </Col>
          </Row>
          {status !== 'completed' && (
            <div>
              <Row className="mt-4">
                <Col>
                  {/* <Label>File</Label> */}
                  <Input
                    type="file"
                    name="uploadFile"
                    id="uploadFile"
                    key={this.state.inputKey}
                    onChange={this.handleFileChange}
                  />
                </Col>
                {/* <Col >
              <FormText color="muted">
                This is some placeholder block-level help text for the above input. It's a bit
                lighter and easily wraps to a new line.
              </FormText>
            </Col> */}
              </Row>
              <Row className="mt-4 justify-content-center">
                <Col md={4} xs={8}>
                  <Button
                    className="btn-success btn-block"
                    onClick={(e) => this.onUploadFileBtnClick(e, uploadType)}
                    disabled={this.state.selectedFile === null}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2 justify-content-center">
                <Col md={4} xs={8}>
                  {this.state.uploadPercentage !== 0 && (
                    <Progress max="100" color="success" value={this.state.uploadPercentage}>
                      {Math.round(this.state.uploadPercentage, 2)}%
                    </Progress>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </FormGroup>
        {/* <div className="text-center">

        </div> */}
      </div>
    );
  }

  renderTextArray(arrayName) {
    const textArray = this.state[arrayName];
    const { status } = this.state;

    return (
      <div>
        {textArray.map((value, idx) => (
          <InputGroup key={value} className="mt-2">
            <Input type="text" id="q5-s1-q1-t-file" placeholder={value} disabled />
            {status !== 'completed' && (
              <InputGroupAddon addonType="append">
                <Button
                  className="subQ-buttons"
                  id={`${value}-b-delete`}
                  color="danger"
                  onClick={(e) => this.onRemoveTextArrayBtnClick(e, arrayName, idx)}
                >
                  <i className="fa fa-trash" />
                </Button>
              </InputGroupAddon>
            )}
          </InputGroup>
        ))}
      </div>
    );
  }

  renderProfessionalSocieties() {
    const { professionalSocietyInput } = this.state;

    return (
      <div>
        <FormGroup row>
          <Label md={4} className="text-md-right">
            <i className="fa fa-chevron-right" /> List organization
          </Label>
          <Col md={7}>
            {this.renderTextArray('professionalSocieties')}
            <InputGroup className="mt-4">
              <Input
                type="text"
                id="professionalSocietyInput"
                name="professionalSocietyInput"
                value={professionalSocietyInput}
                onChange={this.handleInputChange}
              />
              <InputGroupAddon>
                <Button
                  className="subQ-buttons"
                  id="professionalSocietyInput-b-add"
                  color="warning"
                  onClick={(e) =>
                    this.onAddTextArrayBtnClick(
                      e,
                      professionalSocietyInput,
                      'professionalSocieties',
                    )
                  }
                >
                  <i className="fa fa-plus" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <br />
      </div>
    );
  }

  renderLeadershipRoles() {
    const { leadershipRoleInput } = this.state;

    return (
      <div>
        <FormGroup row>
          <Label md={4} className="text-md-right">
            <i className="fa fa-chevron-right" /> List role / organization
          </Label>
          <Col md={7}>
            {this.renderTextArray('leadershipRoles')}
            <InputGroup className="mt-4">
              <Input
                type="text"
                id="leadershipRoleInput"
                name="leadershipRoleInput"
                value={leadershipRoleInput}
                onChange={this.handleInputChange}
              />
              <InputGroupAddon>
                <Button
                  className="subQ-buttons"
                  id="leadershipRoleInput-b-add"
                  color="warning"
                  onClick={(e) =>
                    this.onAddTextArrayBtnClick(e, leadershipRoleInput, 'leadershipRoles')
                  }
                >
                  <i className="fa fa-plus" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <br />
      </div>
    );
  }

  renderPsychologicalTopics() {
    const { psychologicalTopicInput } = this.state;

    return (
      <div>
        <FormGroup row className="submitBlock">
          <Label md={4} className="text-md-right">
            <i className="fa fa-chevron-right" /> List topic / audience
          </Label>
          <Col md={7}>
            {this.renderTextArray('psychologicalTopics')}
            <InputGroup className="mt-4">
              <Input
                type="text"
                id="psychologicalTopicInput"
                name="psychologicalTopicInput"
                value={psychologicalTopicInput}
                onChange={this.handleInputChange}
              />
              <InputGroupAddon>
                <Button
                  className="subQ-buttons"
                  id="psychologicalTopicInput-b-add"
                  color="warning"
                  onClick={(e) =>
                    this.onAddTextArrayBtnClick(e, psychologicalTopicInput, 'psychologicalTopics')
                  }
                >
                  <i className="fa fa-plus" />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </FormGroup>
        <br />
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderEnrollmentInformation() {
    const {
      formData,
      dateLeftProgram,
      expectedReturnDate,
      reasonLeftProgramSelect,
      otherReasonLeftProgram,
    } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Enrollment Information
                </h5>
              }
            >
              <FormGroup>
                <Form className="mt-4">
                  {/* Enrollment - Q1 */}
                  {/* this one shows up only on the readout */}
                  {/* <FormGroup row>
                    <Label htmlFor="dateStartedProgram" md={4} className="text-md-right">
                      Date started program
                    </Label>
                    <Col md={4}>

                      <Input
                        type="text"
                        id="dateStartedProgram"
                        name="dateStartedProgram"
                        // value={practicumSiteName}
                        // onChange={this.handleTextInputChange}
                        placeholder="N/A"
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <p>
                    <br />
                  </p> */}
                  {/* // Enrollment - Q1 */}
                  {/* Enrollment - Q2 */}
                  <Row>
                    <Col className="text-center">
                      <Label>
                        <b>Academic Status</b>
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="q2-b-fullTime"
                        color={formData[0].data[1].value === 'Full-Time' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(0, 1, 'Full-Time')}
                        active={formData[0].data[1].value === 'Full-Time'}
                      >
                        Full-Time
                      </Button>
                      <Button
                        className="btn-form"
                        id="q2-b-leaveOfAbsence"
                        color={
                          formData[0].data[1].value === 'Leave of Absence' ? 'warning' : 'inverse'
                        }
                        onClick={() => this.onFrmBtnClick(0, 1, 'Leave of Absence')}
                        active={formData[0].data[1].value === 'Leave of Absence'}
                      >
                        Leave of Absence
                      </Button>
                      <Button
                        className="btn-form"
                        id="q2-b-leftProgram"
                        color={formData[0].data[1].value === 'Left Program' ? 'danger' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(0, 1, 'Left Program')}
                        active={formData[0].data[1].value === 'Left Program'}
                      >
                        Left Program
                      </Button>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                  {/* Enrollment - Q2 - subQs */}
                  {(formData[0].data[1].value === 'Leave of Absence' ||
                    formData[0].data[1].value === 'Left Program') && (
                    <div>
                      <FormGroup row className="submitBlock">
                        <Col>
                          {/* Enrollment - Q2 - sub Q1  */}
                          <FormGroup row>
                            <Col sm={5} className="text-md-right">
                              <Label htmlFor="dateLeftProgram">
                                <i className="fa fa-chevron-right" /> Date left program
                              </Label>
                            </Col>
                            {/* id="DateLeftProgram" = id="q2-s1-q1-t-dateLeftProgram"  */}
                            <Col sm={4}>
                              <Input
                                type="date"
                                id="dateLeftProgram"
                                name="dateLeftProgram"
                                className="dateTextbox"
                                value={dateLeftProgram}
                                onChange={this.handleInputChange}
                              />
                            </Col>
                          </FormGroup>
                          {/* // Enrollment - Q2 - sub Q1  */}
                          {/* Enrollment - Q2 - sub Q2  */}
                          <FormGroup row>
                            <Col sm={5} className="text-md-right">
                              <Label htmlFor="expectedReturnDate">
                                <i className="fa fa-chevron-right" /> Expected return date
                              </Label>
                            </Col>
                            {/* id="ExpectedReturnDate" = id="q2-s1-q2-t-expectedReturnDate"  */}
                            <Col sm={4}>
                              <Input
                                type="date"
                                id="expectedReturnDate"
                                name="expectedReturnDate"
                                className="dateTextbox"
                                value={expectedReturnDate}
                                onChange={this.handleInputChange}
                              />
                            </Col>
                          </FormGroup>
                          {/* // Enrollment - Q2 - sub Q2  */}
                          {/* Enrollment - Q2 - sub Q3  */}
                          <FormGroup row>
                            <Col sm={5} className="text-md-right">
                              <Label htmlFor="reasonLeftProgramSelect">
                                <i className="fa fa-chevron-right" /> Reason left program
                              </Label>
                            </Col>
                            <Col sm={6}>
                              <Input
                                type="select"
                                id="q2-s1-q3-reasonLeftProgram"
                                name="reasonLeftProgramSelect"
                                value={reasonLeftProgramSelect}
                                onChange={this.handleInputChange}
                              >
                                <option>Successful completion of program</option>
                                <option>Academic reasons</option>
                                <option>Awarded terminal master's degree</option>
                                <option>Change in psychology area specialization</option>
                                <option>Change in career / Employed elsewhere</option>
                                <option>Death of student</option>
                                <option>Did not return from absence</option>
                                <option>Dissmissed - failed program requirements</option>
                                <option>Family or relationship matters</option>
                                <option>Financial</option>
                                <option>Health / Medical</option>
                                <option>New interest outside of psychology</option>
                                <option>No reason provided for leaving program</option>
                                <option>Personal reasons</option>
                                <option>Student relocated</option>
                                <option>Transferred to a different university</option>
                                <option>Transferred to follow academic advisor</option>
                                <option>Voluntary withdrawal - academic difficulties</option>
                                <option>Other reasons</option>
                              </Input>
                            </Col>
                          </FormGroup>
                          {this.state.reasonLeftProgramSelect === 'Other reasons' && (
                            <div>
                              <Row>
                                <Col className="text-center">
                                  <Label>
                                    <i className="fa fa-chevron-right" /> Please specify other
                                    reasons
                                  </Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="text-center">
                                  <Input
                                    type="text"
                                    id="q2-s1-q3-otherReasonLeftProgram"
                                    name="otherReasonLeftProgram"
                                    value={otherReasonLeftProgram}
                                    onChange={this.handleInputChange}
                                  />
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Col>
                      </FormGroup>
                      <p>
                        <br />
                      </p>
                    </div>
                  )}
                  {/* // Enrollment - Q2 - subQs */}
                  <FormGroup row className="submitBlock">
                    <Col className="text-center">
                      <ButtonGroup>
                        <Button
                          // className="mt-2"
                          id="s0-b-save"
                          color="info"
                          onClick={(e) => this.saveForm(e, 'inProgress')}
                        >
                          Save
                        </Button>
                        <Button
                          className="ml-2"
                          id="s0-b-cont"
                          color="info"
                          onClick={(e) => this.onSectionBtnClick(e, 1)}
                        >
                          Continue
                        </Button>
                      </ButtonGroup>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderCoursework() {
    const { formData, GPA, gpaError, gpaErrorMsg, teachingAssistantClassSelect } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Coursework
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Coursework - Q3 */}
                  <FormGroup>
                    <Row>
                      <Col className="text-center">
                        <Label htmlFor="q3-t-GPA">
                          <b>Q1</b> What is your current GPA?
                        </Label>
                        <br />
                        <Input
                          type="text"
                          id="q4-t-GPA"
                          name="GPA"
                          className="gpa-textbox-width"
                          value={GPA}
                          onChange={this.handleInputChange}
                          onBlur={this.validateGPA}
                          invalid={gpaError}
                        />
                        <FormFeedback>{gpaErrorMsg}</FormFeedback>
                      </Col>
                    </Row>
                  </FormGroup>
                  <p>
                    <br />
                  </p>
                  {/* // Coursework - Q3 */}
                  {/* Coursework - Q4 */}
                  <FormGroup>
                    <Row>
                      <Col className="text-center">
                        <Label>
                          <b>Q2</b> Have you been first author or co-author on a poster
                          presentation?
                        </Label>
                        <br />
                        <Button
                          className="btn-form"
                          id="q4-b-yes"
                          color={formData[1].data[1].value === 'Yes' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(1, 1, 'Yes')}
                          active={formData[1].data[1].value === 'Yes'}
                        >
                          Yes
                        </Button>
                        <Button
                          className="btn-form"
                          id="q4-b-no"
                          color={formData[1].data[1].value === 'No' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(1, 1, 'No')}
                          active={formData[1].data[1].value === 'No'}
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* Coursework - Q4 - sub Q1  */}
                  {formData[1].data[1].value === 'Yes' && (
                    <div>
                      {this.renderUploadedFiles('posterPresentation')}
                      <p>
                        <br />
                      </p>
                    </div>
                  )}
                  {/* // Coursework - Q4 */}
                  {/* Coursework - Q5 */}
                  <FormGroup>
                    <Row>
                      <Col className="text-center">
                        <Label>
                          <b>Q3</b>Have you been first author or co-author on a published paper?
                        </Label>
                        <br />
                        <Button
                          className="btn-form"
                          id="q5-b-yes"
                          color={formData[1].data[2].value === 'Yes' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(1, 2, 'Yes')}
                          active={formData[1].data[2].value === 'Yes'}
                        >
                          Yes
                        </Button>
                        <Button
                          className="btn-form"
                          id="q5-b-no"
                          color={formData[1].data[2].value === 'No' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(1, 2, 'No')}
                          active={formData[1].data[2].value === 'No'}
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* Coursework - Q5 - sub Q1  */}
                  {formData[1].data[2].value === 'Yes' && (
                    <div>
                      {this.renderUploadedFiles('publishedPaper')}
                      <p>
                        <br />
                      </p>
                    </div>
                  )}
                  {/* // Coursework - Q5 - subQs */}
                  {/* // Coursework - Q5 */}
                  {/* Coursework - Q6 */}
                  <FormGroup>
                    <Row>
                      <Col className="text-center">
                        <Label>
                          <b>Q4</b> Have you been a teaching assistant for any courses?
                        </Label>
                        <br />
                        <Button
                          className="btn-form"
                          id="q6-b-yes"
                          color={formData[1].data[3].value === 'Yes' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(1, 3, 'Yes')}
                          active={formData[1].data[3].value === 'Yes'}
                        >
                          Yes
                        </Button>
                        <Button
                          className="btn-form"
                          id="q6-b-no"
                          color={formData[1].data[3].value === 'No' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(1, 3, 'No')}
                          active={formData[1].data[3].value === 'No'}
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* Coursework - Q6 - sub Q1  */}
                  {formData[1].data[3].value === 'Yes' && (
                    <div>
                      <FormGroup className="submitBlock">
                        <Row className="justify-content-center">
                          <Col xs={12} className="text-center">
                            <Label>
                              <i className="fa fa-chevron-right" /> Select courses
                            </Label>
                          </Col>
                          <Col md={7}>
                            {this.renderTextArray('teachingAssistantClasses')}
                            <br />
                            <InputGroup>
                              <Input
                                type="select"
                                name="teachingAssistantClassSelect"
                                id="q6-s1-q1-s-classTASelect"
                                value={teachingAssistantClassSelect}
                                onChange={this.handleInputChange}
                              >
                                <option>PSYC 501 Advanced Statistics I</option>
                                <option>PSYC 502 Advanced Statistics II</option>
                                <option>PSYC 503 Advanced Statistics III</option>
                                <option>PSYC 505 Research Methods in Psychological Sciences</option>
                                <option>PSYC 511 Psychometric Foundations</option>
                                <option>PSYC 512/L Cognitive/Intellectual Assessment</option>
                                <option>PSYC 513/L Objective Personality Assessment</option>
                                <option>PSYC 516/L Neuropsychological Assessment</option>
                                <option>
                                  PSYC 524 History, Systems & Philosophy of Psychology
                                </option>
                                <option>PSYC 526 Ethics & Legal Issues in Psychology</option>
                                <option>PSYC 537 Applied Behavior Medicine</option>
                                <option>PSYC 545 Cognitive Foundations</option>
                                <option>
                                  PSYC 546 Clinical Psych. and Practice in Medical Settings
                                </option>
                                <option>PSYC 547 Health Psychology Assessment</option>
                                <option>PSYC 551 Psychobiological Foundations</option>
                                <option>PSYC 553 Cognitive Neuroscience</option>
                                <option>PSYC 554 Health Psychology</option>
                                <option>PSYC 555 Psychopharmacology</option>
                                <option>PSYC 564 Foundations of Social Psychology & Culture</option>
                                <option>PSYC 566 Cultural Psychology</option>
                                <option>PSYC 567 Human Diversity</option>
                                <option>PSYC 571 Adult Psychopathology</option>
                                <option>PSYC 572 Child Psychopathology</option>
                                <option>PSYC 575 Foundations of Developmental Psychology</option>
                                <option>PSYC 581/L Evidence Based Psychological Practice I</option>
                                <option>PSYC 582/L Evidence Based Psychological Practice II</option>
                                <option>
                                  PSYC 583/L Evidence Based Psychological Practice III
                                </option>
                                <option>PSYC 584/L Evidence Based Psychological Practice IV</option>
                                <option>PSYC 591 Colloquium</option>
                                <option>PSYC 597 Supervised Research: Lab Affiliation</option>
                                <option>PSYC 604 Seminar in Statistics</option>
                                <option>PSYC 654 Behavioral Neurology</option>
                                <option>PSYC 646 The Nature of Emotion</option>
                                <option>PSYC 676 Geropsychology (CA Licensure)</option>
                                <option>PSYC 681 Clinical Supervision and Consultation</option>
                                <option>PSYC 681L Clinical Supervision and Consultation Lab</option>
                                <option>PSYC 683 Management and Professional Practice</option>
                                <option>PSYC 684 Human Sexuality (CA Licensure)</option>
                                <option>PSYC 685 Drug Addiction/Treatment (CA Licensure)</option>
                                <option>PSYC 686 Elder, Partner, Child Abuse (CA Licensure)</option>
                                <option>PSYC 694 Advanced Topics</option>
                                <option>PSYC 696 Psy.D. Doctoral Research</option>
                                <option>PSYC 697 Ph.D. Doctoral Research</option>
                                <option>PSYC 721 Practicum Preparation</option>
                                <option>PSYC 781 Internal Practicum</option>
                                <option>PSYC 782 External Practicum</option>
                                <option>PSYC 795 Directed Clinical Experience</option>
                                <option>PSYC 798 Pre-internship</option>
                                <option>PSYC 799 Internship</option>
                                <option>RELE Religion Elective</option>
                                <option>RELR 535 Religion: Course Required</option>
                                <option>RELT Religion Elective</option>
                              </Input>
                              <InputGroupAddon>
                                <Button
                                  className="subQ-buttons"
                                  id="q6-s1-q1-t-classTAAdd"
                                  color="warning"
                                  onClick={(e) =>
                                    this.onAddTextArrayBtnClick(
                                      e,
                                      teachingAssistantClassSelect,
                                      'teachingAssistantClasses',
                                    )
                                  }
                                >
                                  <i className="fa fa-plus" />
                                </Button>
                              </InputGroupAddon>
                            </InputGroup>
                          </Col>
                        </Row>
                      </FormGroup>
                      <p>
                        <br />
                      </p>
                    </div>
                  )}
                  {/* // Coursework - Q6 */}
                  <FormGroup row className="submitBlock">
                    <Col className="text-center">
                      <Button
                        className="mt-2"
                        id="s1-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-2 mt-2"
                        id="s1-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-2 mt-2"
                        id="s1-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderProfessionalActivities() {
    const { formData } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Professional Activities
                </h5>
              }
            >
              <h4 className="text-center">
                For events that occurred in the past academic year only.
              </h4>
              <p>
                <br />
              </p>
              <FormGroup>
                <Form>
                  {/* Professional Activities - Q7 */}
                  <FormGroup>
                    <Row>
                      <Col className="text-center">
                        <Label>
                          <b>Q5</b> Did you join or are you a current member of a professional
                          society?
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-center">
                        <Button
                          className="btn-form"
                          id="q7-b-yes"
                          color={formData[2].data[0].value === 'Yes' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(2, 0, 'Yes')}
                          active={formData[2].data[0].value === 'Yes'}
                        >
                          Yes
                        </Button>
                        <Button
                          className="btn-form"
                          id="q7-b-no"
                          color={formData[2].data[0].value === 'No' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(2, 0, 'No')}
                          active={formData[2].data[0].value === 'No'}
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  <p>
                    <br />
                  </p>
                  {/* Professional Activities - Q7 - sub Q1  */}
                  {(() => {
                    if (formData[2].data[0].value === 'Yes') {
                      return (
                        <div>
                          <FormGroup className="submitBlock">
                            {this.renderProfessionalSocieties()}
                            <Row>
                              <Col className="text-center">
                                <Label>
                                  <i className="fa fa-chevron-right" /> Are you involved in any
                                  leadership roles?
                                </Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-center">
                                <Button
                                  className="btn-form"
                                  id="q7-s1-q1-b-yes"
                                  color={formData[2].data[2].value === 'Yes' ? 'dark' : 'inverse'}
                                  onClick={() => this.onFrmBtnClick(2, 2, 'Yes')}
                                  active={formData[2].data[2].value === 'Yes'}
                                >
                                  Yes
                                </Button>
                                <Button
                                  className="btn-form"
                                  id="q7-s1-q1-b-no"
                                  color={formData[2].data[2].value === 'No' ? 'dark' : 'inverse'}
                                  onClick={() => this.onFrmBtnClick(2, 2, 'No')}
                                  active={formData[2].data[2].value === 'No'}
                                >
                                  No
                                </Button>
                              </Col>
                            </Row>
                            <p>
                              <br />
                            </p>
                            {(() => {
                              if (formData[2].data[2].value === 'Yes') {
                                return <div>{this.renderLeadershipRoles()}</div>;
                              }
                            })()}
                          </FormGroup>
                          <p>
                            <br />
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {/* // Professional Activities - Q7 - subQs */}
                  {/* // Professional Activities - Q7 */}
                  {/* Professional Activities - Q8 */}
                  <FormGroup>
                    <Row>
                      <Col className="text-center">
                        <Label>
                          <b>Q6</b> Have you presented a psychological topic to a lay or community
                          audience?
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-center">
                        <Button
                          className="btn-form"
                          id="q8-b-yes"
                          color={formData[2].data[4].value === 'Yes' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(2, 4, 'Yes')}
                          active={formData[2].data[4].value === 'Yes'}
                        >
                          Yes
                        </Button>
                        <Button
                          className="btn-form"
                          id="q8-b-no"
                          color={formData[2].data[4].value === 'No' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(2, 4, 'No')}
                          active={formData[2].data[4].value === 'No'}
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  <p>
                    <br />
                  </p>
                  {(() => {
                    if (formData[2].data[4].value === 'Yes') {
                      return <div>{this.renderPsychologicalTopics()}</div>;
                    }
                  })()}
                  <FormGroup row className="submitBlock">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="s2-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s2-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s2-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderDoctoralProject() {
    const {
      formData,
      doctoralProjectTitle,
      doctoralCommitteeMemberInput,
      doctoralDefensePassedDate,
      doctoralProposalPassedDate,
    } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Doctoral Project
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Doctoral Project - Q9 */}
                  <FormGroup>
                    <Row>
                      <Col className="text-center">
                        <Label>
                          <b>Q7</b> Have you successfully passed your doctoral project defense?
                        </Label>
                        <br />
                        <Button
                          className="btn-form"
                          id="doc-q9-b-yes"
                          color={formData[3].data[0].value === 'Yes' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(3, 0, 'Yes')}
                          active={formData[3].data[0].value === 'Yes'}
                        >
                          Yes
                        </Button>
                        <Button
                          className="btn-form"
                          id="doc-q9-b-no"
                          color={formData[3].data[0].value === 'No' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(3, 0, 'No')}
                          active={formData[3].data[0].value === 'No'}
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  <p>
                    <br />
                  </p>
                  {/* Doctoral Project - Q9 - sub Q1 - YES */}
                  {(() => {
                    if (formData[3].data[0].value === 'Yes') {
                      return (
                        <div>
                          <FormGroup row className="submitBlock">
                            <Col className="text-center">
                              <p>Great news! We need a bit more information.</p>
                              {/* Doctoral Project - Q9 - sub Q1 - YES - Q1 */}
                              <Row>
                                <Col className="text-center">
                                  <Label>
                                    <i className="fa fa-chevron-right" /> Doctoral project title
                                  </Label>
                                  <br />
                                  <Input
                                    type="text"
                                    id="q9-s1-yes-q1-t-docProjTitle"
                                    name="doctoralProjectTitle"
                                    value={doctoralProjectTitle}
                                    onChange={this.handleInputChange}
                                  />
                                </Col>
                              </Row>
                              <p>
                                <br />
                              </p>
                              {/* // Doctoral Project - Q7 - sub Q1 - YES - Q1 */}
                              {/* Doctoral Project - Q7 - sub Q1 - YES - Q2 */}
                              <FormGroup row>
                                <Label md={4} className="text-md-right">
                                  <i className="fa fa-chevron-right" /> Committee members
                                </Label>
                                <Col md={7}>
                                  {this.renderTextArray('doctoralCommitteeMembers')}
                                  <InputGroup className="mt-2">
                                    <Input
                                      type="text"
                                      id="doctoralCommitteeMemberInput"
                                      name="doctoralCommitteeMemberInput"
                                      value={doctoralCommitteeMemberInput}
                                      onChange={this.handleInputChange}
                                    />
                                    <InputGroupAddon>
                                      <Button
                                        className="subQ-buttons"
                                        id="doctoralCommitteeMemberInput-b-add"
                                        color="warning"
                                        onClick={(e) =>
                                          this.onAddTextArrayBtnClick(
                                            e,
                                            doctoralCommitteeMemberInput,
                                            'doctoralCommitteeMembers',
                                          )
                                        }
                                      >
                                        <i className="fa fa-plus" />
                                      </Button>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </Col>
                              </FormGroup>
                              <p>
                                <br />
                              </p>
                              {/* // Doctoral Project - Q7 - sub Q1 - YES - Q2 */}
                              {/* Doctoral Project - Q7 - sub Q1 - YES - Q3 */}
                              <FormGroup row>
                                <Label sm={5} className="text-md-right">
                                  <i className="fa fa-chevron-right" /> Defense passed date
                                </Label>
                                <Col sm={4}>
                                  <Input
                                    type="date"
                                    id="doc-q9-s3-yes-q3-t-dateDefensePassed"
                                    name="doctoralDefensePassedDate"
                                    className="dateTextbox"
                                    value={doctoralDefensePassedDate}
                                    onChange={this.handleInputChange}
                                  />
                                </Col>
                              </FormGroup>
                              {/* // Doctoral Project - Q7 - sub Q1 - YES - Q3 */}
                            </Col>
                          </FormGroup>
                          <p>
                            <br />
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {/* // Doctoral Project - Q9 - sub Q1 - YES */}
                  {/* Doctoral Project - Q9 - sub Q1 - NO */}
                  {(() => {
                    if (formData[3].data[0].value === 'No') {
                      return (
                        <div>
                          <FormGroup row className="submitBlock">
                            <Col className="text-center">
                              <p>Well then, we need a bit more information.</p>
                              {/* Doctoral Project - Q9 - sub Q1 - NO - Q1 */}
                              <Row>
                                <Col className="text-center">
                                  <Label>
                                    <i className="fa fa-chevron-right" /> Has your committee passed
                                    your doctoral project proposal?
                                  </Label>
                                  <br />
                                  <Button
                                    className="btn-form"
                                    id="doc-q9-s1-no-q1-b-yes"
                                    color={formData[3].data[3].value === 'Yes' ? 'dark' : 'inverse'}
                                    onClick={() => this.onFrmBtnClick(3, 3, 'Yes')}
                                    active={formData[3].data[3].value === 'Yes'}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    className="btn-form"
                                    id="doc-q9-s1-no-q1-b-no"
                                    color={formData[3].data[3].value === 'No' ? 'dark' : 'inverse'}
                                    onClick={() => this.onFrmBtnClick(3, 3, 'No')}
                                    active={formData[3].data[3].value === 'No'}
                                  >
                                    No
                                  </Button>
                                </Col>
                              </Row>
                              <p>
                                <br />
                              </p>
                              {/* Doctoral Project - Q9 - NO - sub Q1 - YES - SubQ */}
                              {(() => {
                                if (formData[3].data[3].value === 'Yes') {
                                  return (
                                    <div>
                                      {/* Doctoral Project - Q7 - NO - sub Q1 - YES - sub Q1 */}
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Doctoral project
                                            title
                                          </Label>
                                          <br />
                                          <Input
                                            type="text"
                                            id="q9-s1-yes-q1-t-docProjTitle"
                                            name="doctoralProjectTitle"
                                            value={doctoralProjectTitle}
                                            onChange={this.handleInputChange}
                                          />
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                      {/* // Doctoral Project - Q9 -NO - sub Q1 - YES - sub Q1 */}
                                      {/* Doctoral Project - Q9 - NO - sub Q1 - YES - sub Q2 */}
                                      <FormGroup row>
                                        <Label md={4} className="text-md-right">
                                          <i className="fa fa-chevron-right" /> Committee members
                                        </Label>
                                        <Col md={7}>
                                          {this.renderTextArray('doctoralCommitteeMembers')}
                                          <InputGroup className="mt-2">
                                            <Input
                                              type="text"
                                              id="doctoralCommitteeMemberInput"
                                              name="doctoralCommitteeMemberInput"
                                              value={doctoralCommitteeMemberInput}
                                              onChange={this.handleInputChange}
                                            />
                                            <InputGroupAddon>
                                              <Button
                                                className="subQ-buttons"
                                                id="doctoralCommitteeMembers-b-add"
                                                color="warning"
                                                onClick={(e) =>
                                                  this.onAddTextArrayBtnClick(
                                                    e,
                                                    doctoralCommitteeMemberInput,
                                                    'doctoralCommitteeMembers',
                                                  )
                                                }
                                              >
                                                <i className="fa fa-plus" />
                                              </Button>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </Col>
                                      </FormGroup>
                                      <p>
                                        <br />
                                      </p>
                                      {/* // Doctoral Project - Q9 - NO - sub Q1 - YES - sub Q2 */}
                                      {/* Doctoral Project - Q9 - NO - sub Q1 - YES - sub Q3 */}
                                      <FormGroup row>
                                        <Label sm={5} className="text-md-right">
                                          <i className="fa fa-chevron-right" /> Proposal passed date
                                        </Label>
                                        <Col sm={4}>
                                          <Input
                                            type="date"
                                            id="doc-q9-s1-no-q3-t-dateProposalPassed"
                                            name="doctoralProposalPassedDate"
                                            className="dateTextbox"
                                            value={doctoralProposalPassedDate}
                                            onChange={this.handleInputChange}
                                          />
                                        </Col>
                                      </FormGroup>
                                      <p>
                                        <br />
                                      </p>
                                      {/* // Doctoral Project - Q9 - NO - sub Q1 - YES - Q3 */}
                                      {/* Doctoral Project - Q9 - NO - sub Q1 - YES - sub Q4 */}
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Have you started
                                            working on completing your doctoral project for the
                                            final defense?
                                          </Label>
                                          <br />
                                          <Button
                                            className="btn-form"
                                            id="doc-q9-s1-no-q4-b-yes"
                                            color={
                                              formData[3].data[6].value === 'Yes'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(3, 6, 'Yes')}
                                            active={formData[3].data[6].value === 'Yes'}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            className="btn-form"
                                            id="doc-q9-s1-no-q4-b-no"
                                            color={
                                              formData[3].data[6].value === 'No'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(3, 6, 'No')}
                                            active={formData[3].data[6].value === 'No'}
                                          >
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                      {/* // Doctoral Project - Q9 - NO - sub Q1 - YES - subQS */}
                                    </div>
                                  );
                                }
                              })()}
                              {/* Doctoral Project - Q9 - NO - sub Q1 - YES - SubQ */}
                              {/* Doctoral Project - Q9 - sub Q1 - NO - sub Q4 - NO - Q1 */}
                              {(() => {
                                if (formData[3].data[3].value === 'No') {
                                  return (
                                    <div>
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Have you
                                            identified a faculty chair for your doctoral project?
                                          </Label>
                                          <br />
                                          <Button
                                            className="btn-form"
                                            id="doc-q9-s1-no-q4-no-q1-b-yes"
                                            color={
                                              formData[3].data[7].value === 'Yes'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(3, 7, 'Yes')}
                                            active={formData[3].data[7].value === 'Yes'}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            className="btn-form"
                                            id="doc-q9-s1-no-q4-no-q1-b-no"
                                            color={
                                              formData[3].data[7].value === 'No'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(3, 7, 'No')}
                                            active={formData[3].data[7].value === 'No'}
                                          >
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                    </div>
                                  );
                                }
                              })()}
                              {/* // Doctoral Project - Q9 - sub Q1 - NO - sub Q4 - NO - Q1 */}
                              {/* Doctoral Project - Q9 - sub Q1 - NO - sub Q4 - NO - Q2 */}
                              {(() => {
                                if (
                                  formData[3].data[3].value === 'No' &&
                                  formData[3].data[7].value === 'Yes'
                                ) {
                                  return (
                                    <div>
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Have you
                                            identified a topic area for your doctoral project?
                                          </Label>
                                          <br />
                                          <Button
                                            className="btn-form"
                                            id="doc-q9-s1-no-q4-no-q2-b-yes"
                                            color={
                                              formData[3].data[8].value === 'Yes'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(3, 8, 'Yes')}
                                            active={formData[3].data[8].value === 'Yes'}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            className="btn-form"
                                            id="doc-q9-s1-no-q4-no-q2-b-no"
                                            color={
                                              formData[3].data[8].value === 'No'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(3, 8, 'No')}
                                            active={formData[3].data[8].value === 'No'}
                                          >
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                    </div>
                                  );
                                }
                              })()}
                              {/* // Doctoral Project - Q9 - sub Q1 - NO - sub Q4 - NO - Q2 */}
                              {/* Doctoral Project - Q9 - sub Q1 - NO - sub Q4 - NO - Q3 */}
                              {(() => {
                                if (
                                  formData[3].data[3].value === 'No' &&
                                  formData[3].data[7].value === 'Yes' &&
                                  formData[3].data[8].value === 'Yes'
                                ) {
                                  return (
                                    <div>
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Have you settled
                                            on a general design or plan for conducting your doctoral
                                            project?
                                          </Label>
                                          <br />
                                          <Button
                                            className="btn-form"
                                            id="doc-q9-s1-no-q4-no-q3-b-yes"
                                            color={
                                              formData[3].data[9].value === 'Yes'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(3, 9, 'Yes')}
                                            active={formData[3].data[9].value === 'Yes'}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            className="btn-form"
                                            id="doc-q9-s1-no-q4-no-q3-b-no"
                                            color={
                                              formData[3].data[9].value === 'No'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(3, 9, 'No')}
                                            active={formData[3].data[9].value === 'No'}
                                          >
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                    </div>
                                  );
                                }
                              })()}
                              {/* // Doctoral Project - Q9 - sub Q1 - NO - sub Q4 - NO - Q3 */}
                              {/* Doctoral Project - Q9 - sub Q1 - NO - sub Q4 - NO - Q4 */}
                              {(() => {
                                if (
                                  formData[3].data[3].value === 'No' &&
                                  formData[3].data[7].value === 'Yes' &&
                                  formData[3].data[8].value === 'Yes' &&
                                  formData[3].data[9].value === 'Yes'
                                ) {
                                  return (
                                    <div>
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Have you started
                                            writing your doctoral project proposal?
                                          </Label>
                                          <br />
                                          <Button
                                            className="btn-form"
                                            id="doc-q9-s1-no-q4-no-q4-b-yes"
                                            color={
                                              formData[3].data[10].value === 'Yes'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(3, 10, 'Yes')}
                                            active={formData[3].data[10].value === 'Yes'}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            className="btn-form"
                                            id="doc-q9-s1-no-q4-no-q4-b-no"
                                            color={
                                              formData[3].data[10].value === 'No'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(3, 10, 'No')}
                                            active={formData[3].data[10].value === 'No'}
                                          >
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                    </div>
                                  );
                                }
                              })()}
                              {/* // Doctoral Project - Q9 - sub Q1 - NO - sub Q4 - NO - Q4 */}
                            </Col>
                          </FormGroup>
                          {/* // Doctoral Project - Q9 - sub Q1 - NO - sub Q1 - YES */}
                          <p>
                            <br />
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {/* // Doctoral Project - Q9 - sub Q1 - NO - subQ */}
                  {/* // Doctoral Project - Q9 */}
                  <FormGroup row className="submitBlock">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="s3-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s3-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s3-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderDissertationQuestions() {
    const {
      formData,
      dissertationProjectTitle,
      dissertationCommitteeMemberInput,
      dissertationPassedDate,
      dissertationProposalPassedDate,
    } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Dissertation Questions
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Dissertation - Q7 */}
                  <FormGroup>
                    <Row>
                      <Col className="text-center">
                        <Label>
                          <b>Q7</b> Have you successfully passed your dissertation defense?
                        </Label>
                        <br />
                        <Button
                          className="btn-form"
                          id="dis-q9-b-yes"
                          color={formData[4].data[0].value === 'Yes' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(4, 0, 'Yes')}
                          active={formData[4].data[0].value === 'Yes'}
                        >
                          Yes
                        </Button>
                        <Button
                          className="btn-form"
                          id="dis-q9-b-no"
                          color={formData[4].data[0].value === 'No' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(4, 0, 'No')}
                          active={formData[4].data[0].value === 'No'}
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  <p>
                    <br />
                  </p>
                  {/* Dissertation - Q9 - sub Q1 - YES */}
                  {(() => {
                    if (formData[4].data[0].value === 'Yes') {
                      return (
                        <div>
                          <FormGroup row className="submitBlock">
                            <Col className="text-center">
                              <p>Great news! We need a bit more information.</p>
                              {/* Dissertation - Q9 - sub Q1 - YES - sub Q1 */}
                              <Row>
                                <Col className="text-center">
                                  <Label>
                                    <i className="fa fa-chevron-right" /> Dissertation project title
                                  </Label>
                                  <br />
                                  <Input
                                    type="text"
                                    id="dis-q9-yes-s1-q1-t-dissertationTitle"
                                    name="dissertationProjectTitle"
                                    value={dissertationProjectTitle}
                                    onChange={this.handleInputChange}
                                  />
                                </Col>
                              </Row>
                              <p>
                                <br />
                              </p>
                              {/* // Dissertation- Q7 - sub Q1 - YES - sub Q1 */}
                              {/* Dissertation - Q7 - sub Q1 - YES - sub Q2 */}
                              <FormGroup row>
                                <Label md={4} className="text-md-right">
                                  <i className="fa fa-chevron-right" /> Committee members
                                </Label>
                                <Col md={7}>
                                  {this.renderTextArray('dissertationCommitteeMembers')}
                                  <InputGroup className="mt-2">
                                    <Input
                                      type="text"
                                      id="dissertationCommitteeMemberInput"
                                      name="dissertationCommitteeMemberInput"
                                      value={dissertationCommitteeMemberInput}
                                      onChange={this.handleInputChange}
                                    />
                                    <InputGroupAddon>
                                      <Button
                                        className="subQ-buttons"
                                        id="dissertationCommitteeMemberInput-b-add"
                                        color="warning"
                                        onClick={(e) =>
                                          this.onAddTextArrayBtnClick(
                                            e,
                                            dissertationCommitteeMemberInput,
                                            'dissertationCommitteeMembers',
                                          )
                                        }
                                      >
                                        <i className="fa fa-plus" />
                                      </Button>
                                    </InputGroupAddon>
                                  </InputGroup>
                                </Col>
                              </FormGroup>
                              <p>
                                <br />
                              </p>
                              {/* // Dissertation - Q7 - sub Q1 - YES - sub Q2 */}
                              {/* Dissertation - Q7 - sub Q1 - YES - sub Q3 */}
                              <FormGroup row>
                                <Label sm={5} className="text-md-right">
                                  <i className="fa fa-chevron-right" /> Defense passed date
                                </Label>
                                <Col sm={4}>
                                  <Input
                                    type="date"
                                    id="dis-q9-yes-s1-q3-t-defensePassedDate"
                                    name="dissertationPassedDate"
                                    className="dateTextbox"
                                    value={dissertationPassedDate}
                                    onChange={this.handleInputChange}
                                  />
                                </Col>
                              </FormGroup>
                              {/* // Dissertation - Q7 - sub Q1 - YES - sub Q3 */}
                            </Col>
                          </FormGroup>
                          <p>
                            <br />
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {/* // Dissertation - Q7 - sub Q1 - YES */}
                  {/* Dissertation - Q7 - sub Q1 - NO */}
                  {(() => {
                    if (formData[4].data[0].value === 'No') {
                      return (
                        <div>
                          <FormGroup row className="submitBlock">
                            <Col className="text-center">
                              <p>Well then, we need a bit more information.</p>
                              {/* Dissertation - Q7 - sub Q1 - NO - sub Q1 */}
                              <Row>
                                <Col className="text-center">
                                  <Label>
                                    <i className="fa fa-chevron-right" /> Has your committee passed
                                    your dissertation proposal?
                                  </Label>
                                  <br />
                                  <Button
                                    className="btn-form"
                                    id="dis-q9-no-s1-q1-b-yes"
                                    color={formData[4].data[3].value === 'Yes' ? 'dark' : 'inverse'}
                                    onClick={() => this.onFrmBtnClick(4, 3, 'Yes')}
                                    active={formData[4].data[3].value === 'Yes'}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    className="btn-form"
                                    id="dis-q9-no-s1-q1-b-no"
                                    color={formData[4].data[3].value === 'No' ? 'dark' : 'inverse'}
                                    onClick={() => this.onFrmBtnClick(4, 3, 'No')}
                                    active={formData[4].data[3].value === 'No'}
                                  >
                                    No
                                  </Button>
                                </Col>
                              </Row>
                              <p>
                                <br />
                              </p>
                              {/* Dissertation - Q9 - NO - sub Q1 - YES - SubQ */}
                              {(() => {
                                if (formData[4].data[3].value === 'Yes') {
                                  return (
                                    <div>
                                      {/* Dissertation - Q9 - NO - sub Q1 - YES - sub Q1 */}
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Dissertation
                                            project title
                                          </Label>
                                          <br />
                                          <Input
                                            type="text"
                                            id="dis-q9-yes-s1-q1-t-dissertationTitle"
                                            name="dissertationProjectTitle"
                                            value={dissertationProjectTitle}
                                            onChange={this.handleInputChange}
                                          />
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                      {/* // Dissertation - Q7 -NO - sub Q1 - YES - sub Q1 */}
                                      {/* Dissertation - Q7 - NO - sub Q1 - YES - sub Q2 */}
                                      <FormGroup row>
                                        <Label md={4} className="text-md-right">
                                          <i className="fa fa-chevron-right" /> Committee members
                                        </Label>
                                        <Col md={7}>
                                          {this.renderTextArray('dissertationCommitteeMembers')}
                                          <InputGroup className="mt-2">
                                            <Input
                                              type="text"
                                              id="dissertationCommitteeMemberInput"
                                              name="dissertationCommitteeMemberInput"
                                              value={dissertationCommitteeMemberInput}
                                              onChange={this.handleInputChange}
                                            />
                                            <InputGroupAddon>
                                              <Button
                                                className="subQ-buttons"
                                                id="dissertationCommitteeMemberInput-b-add"
                                                color="warning"
                                                onClick={(e) =>
                                                  this.onAddTextArrayBtnClick(
                                                    e,
                                                    dissertationCommitteeMemberInput,
                                                    'dissertationCommitteeMembers',
                                                  )
                                                }
                                              >
                                                <i className="fa fa-plus" />
                                              </Button>
                                            </InputGroupAddon>
                                          </InputGroup>
                                        </Col>
                                      </FormGroup>
                                      {/* // Dissertation - Q9 - NO - sub Q1 - YES - sub Q2 */}
                                      {/* Dissertation - Q9 - NO - sub Q1 - YES - sub Q3 */}
                                      <FormGroup row>
                                        <Label sm={5} className="text-md-right">
                                          <i className="fa fa-chevron-right" /> Proposal passed date
                                        </Label>
                                        <Col sm={4}>
                                          <Input
                                            type="date"
                                            id="dis-q9-yes-s1-q3-t-defensePassedDate"
                                            name="dissertationProposalPassedDate"
                                            className="dateTextbox"
                                            value={dissertationProposalPassedDate}
                                            onChange={this.handleInputChange}
                                          />
                                        </Col>
                                      </FormGroup>
                                      <p>
                                        <br />
                                      </p>
                                      {/* // Dissertation - Q9 - NO - sub Q1 - YES - sub Q3 */}
                                      {/* Dissertation - Q9 - NO - sub Q1 - YES - sub Q4 */}
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Have you started
                                            writing up your results for your dissertation defense?
                                          </Label>
                                          <br />
                                          <Button
                                            className="btn-form"
                                            id="dis-q9-no-s1-yes-q4-b-yes"
                                            color={
                                              formData[4].data[6].value === 'Yes'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(4, 6, 'Yes')}
                                            active={formData[4].data[6].value === 'Yes'}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            className="btn-form"
                                            id="dis-q9-no-s1-yes-q4-b-no"
                                            color={
                                              formData[4].data[6].value === 'No'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(4, 6, 'No')}
                                            active={formData[4].data[6].value === 'No'}
                                          >
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                      {/* // Dissertation - Q9 - NO - sub Q1 - YES - sub Q4 */}
                                      {/* Dissertation - Q9 - sub Q1 - NO - sub Q4 - NO - Q1 */}
                                      {(() => {
                                        if (formData[4].data[6].value === 'No') {
                                          return (
                                            <div>
                                              <Row>
                                                <Col className="text-center">
                                                  <Label>
                                                    <i className="fa fa-chevron-right" /> Have you
                                                    finished data collection for your dissertation?
                                                    If your dissertation does not involve data
                                                    collection, have you started your analysis or
                                                    other required work?
                                                  </Label>
                                                  <br />
                                                  <Button
                                                    className="btn-form"
                                                    id="dis-q9-no-s1-yes-q4-no-s1-q1-b-yes"
                                                    color={
                                                      formData[4].data[7].value === 'Yes'
                                                        ? 'dark'
                                                        : 'inverse'
                                                    }
                                                    onClick={() => this.onFrmBtnClick(4, 7, 'Yes')}
                                                    active={formData[4].data[7].value === 'Yes'}
                                                  >
                                                    Yes
                                                  </Button>
                                                  <Button
                                                    className="btn-form"
                                                    id="dis-q9-no-s1-yes-q4-no-s1-q1-b-no"
                                                    color={
                                                      formData[4].data[7].value === 'No'
                                                        ? 'dark'
                                                        : 'inverse'
                                                    }
                                                    onClick={() => this.onFrmBtnClick(4, 7, 'No')}
                                                    active={formData[4].data[7].value === 'No'}
                                                  >
                                                    No
                                                  </Button>
                                                </Col>
                                              </Row>
                                              <p>
                                                <br />
                                              </p>
                                            </div>
                                          );
                                        }
                                      })()}
                                      {/* // Dissertation - Q9 - sub Q1 - NO - sub Q4 - NO - sub Q1 */}
                                      {/* Dissertation - Q9 - sub Q1 - NO - sub Q4 - NO - Q1 - NO - sub Q1 */}
                                      {(() => {
                                        if (
                                          formData[4].data[7].value === 'No' &&
                                          formData[4].data[6].value === 'No'
                                        ) {
                                          return (
                                            <div>
                                              <Row>
                                                <Col className="text-center">
                                                  <Label>
                                                    <i className="fa fa-chevron-right" /> Have you
                                                    started any data collection for your
                                                    dissertation?
                                                  </Label>
                                                  <br />
                                                  <Button
                                                    className="btn-form"
                                                    id="dis-q9-no-s1-yes-q4-no-s1-no-s1-q1-b-yes"
                                                    color={
                                                      formData[4].data[8].value === 'Yes'
                                                        ? 'dark'
                                                        : 'inverse'
                                                    }
                                                    onClick={() => this.onFrmBtnClick(4, 8, 'Yes')}
                                                    active={formData[4].data[8].value === 'Yes'}
                                                  >
                                                    Yes
                                                  </Button>
                                                  <Button
                                                    className="btn-form"
                                                    id="dis-q9-no-s1-yes-q4-no-s1-no-s1-q1-b-no"
                                                    color={
                                                      formData[4].data[8].value === 'No'
                                                        ? 'dark'
                                                        : 'inverse'
                                                    }
                                                    onClick={() => this.onFrmBtnClick(4, 8, 'No')}
                                                    active={formData[4].data[8].value === 'No'}
                                                  >
                                                    No
                                                  </Button>
                                                </Col>
                                              </Row>
                                              <p>
                                                <br />
                                              </p>
                                            </div>
                                          );
                                        }
                                      })()}
                                      {/* // Dissertation - Q9 - sub Q1 - NO - sub Q4 - NO - Q1 - NO - sub Q1 */}
                                      {/* // Dissertation - Q9 - NO - sub Q1 - YES - subQS */}
                                    </div>
                                  );
                                }
                              })()}
                              {/* // Dissertation - Q9 - NO - sub Q1 - YES - SubQ */}
                              {/* Dissertation - Q9 - NO - sub Q1 - NO - SubQ */}
                              {(() => {
                                if (formData[4].data[3].value === 'No') {
                                  return (
                                    <div>
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Have you
                                            identified a faculty chair for your dissertation?
                                          </Label>
                                          <br />
                                          <Button
                                            className="btn-form"
                                            id="dis-q9-no-s1-no-q1-b-yes"
                                            color={
                                              formData[4].data[9].value === 'Yes'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(4, 9, 'Yes')}
                                            active={formData[4].data[9].value === 'Yes'}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            className="btn-form"
                                            id="dis-q9-no-s1-no-q1-b-no"
                                            color={
                                              formData[4].data[9].value === 'No'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(4, 9, 'No')}
                                            active={formData[4].data[9].value === 'No'}
                                          >
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                    </div>
                                  );
                                }
                              })()}
                              {/* // Dissertation - Q9 - NO - sub Q1 - NO - sub Q1 */}
                              {/* Dissertation - Q9 - NO - sub Q1 - NO - sub Q2 */}
                              {(() => {
                                if (
                                  formData[4].data[3].value === 'No' &&
                                  formData[4].data[9].value === 'Yes'
                                ) {
                                  return (
                                    <div>
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Have you
                                            identified a topic area for your dissertation?
                                          </Label>
                                          <br />
                                          <Button
                                            className="btn-form"
                                            id="dis-q9-no-s1-yes-q2-b-yes"
                                            color={
                                              formData[4].data[10].value === 'Yes'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(4, 10, 'Yes')}
                                            active={formData[4].data[10].value === 'Yes'}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            className="btn-form"
                                            id="dis-q9-no-s1-yes-q2-b-no"
                                            color={
                                              formData[4].data[10].value === 'No'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(4, 10, 'No')}
                                            active={formData[4].data[10].value === 'No'}
                                          >
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                    </div>
                                  );
                                }
                              })()}
                              {/* // Dissertation - Q9 - NO - sub Q1 - NO - sub Q2 */}
                              {/* Dissertation - Q9 - NO - sub Q1 - NO - sub Q3 */}
                              {(() => {
                                if (
                                  formData[4].data[3].value === 'No' &&
                                  formData[4].data[10].value === 'Yes' &&
                                  formData[4].data[9].value === 'Yes'
                                ) {
                                  return (
                                    <div>
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Have you settled
                                            on a general design or plan for conducting the
                                            dissertation?
                                          </Label>
                                          <br />
                                          <Button
                                            className="btn-form"
                                            id="dis-q9-no-s1-yes-q3-b-yes"
                                            color={
                                              formData[4].data[11].value === 'Yes'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(4, 11, 'Yes')}
                                            active={formData[4].data[11].value === 'Yes'}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            className="btn-form"
                                            id="dis-q9-no-s1-yes-q3-b-no"
                                            color={
                                              formData[4].data[11].value === 'No'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(4, 11, 'No')}
                                            active={formData[4].data[11].value === 'No'}
                                          >
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                    </div>
                                  );
                                }
                              })()}
                              {/* // Dissertation - Q9 - NO - sub Q1 - NO - sub Q3 */}
                              {/* Dissertation - Q9 - NO - sub Q1 - NO - sub Q4 */}
                              {(() => {
                                if (
                                  formData[4].data[3].value === 'No' &&
                                  formData[4].data[11].value === 'Yes' &&
                                  formData[4].data[10].value === 'Yes' &&
                                  formData[4].data[9].value === 'Yes'
                                ) {
                                  return (
                                    <div>
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" /> Have you started
                                            writing your dissertation proposal?
                                          </Label>
                                          <br />
                                          <Button
                                            className="btn-form"
                                            id="dis-q9-no-s1-yes-q4-b-yes"
                                            color={
                                              formData[4].data[12].value === 'Yes'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(4, 12, 'Yes')}
                                            active={formData[4].data[12].value === 'Yes'}
                                          >
                                            Yes
                                          </Button>
                                          <Button
                                            className="btn-form"
                                            id="dis-q9-no-s1-yes-q4-b-no"
                                            color={
                                              formData[4].data[12].value === 'No'
                                                ? 'dark'
                                                : 'inverse'
                                            }
                                            onClick={() => this.onFrmBtnClick(4, 12, 'No')}
                                            active={formData[4].data[12].value === 'No'}
                                          >
                                            No
                                          </Button>
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                    </div>
                                  );
                                }
                              })()}
                              {/* // Dissertation - Q9 - NO - sub Q1 - NO - sub Q4 */}
                              {/* // Dissertation - Q9 - NO - sub Q1 - NO - SubQ */}
                            </Col>
                          </FormGroup>
                          {/* // Dissertation - Q9 - NO - subQs */}
                          <p>
                            <br />
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {/* // Dissertation - Q9 */}
                  <FormGroup row className="submitBlock">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="s5-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s5-b-save"
                        color="inverse"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s5-b-submit"
                        color="info"
                        type="submit"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderDoctoralInternship() {
    const {
      formData,
      comprehensiveExamDate,
      comprehensiveExamScore,
      internshipNameInput,
      accreditingBodyNameInput,
    } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Doctoral Internship
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Doctoral Internship - Q? */}
                  <FormGroup>
                    <Row>
                      <Col className="text-center">
                        <Label>
                          <b>Q8</b> Have you taken your comprehensive exam (EPPP)?
                        </Label>
                        <br />
                        <Button
                          className="btn-form"
                          id="doc-q9-b-yes"
                          color={formData[5].data[0].value === 'Yes' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(5, 0, 'Yes')}
                          active={formData[5].data[0].value === 'Yes'}
                        >
                          Yes
                        </Button>
                        <Button
                          className="btn-form"
                          id="doc-q9-b-no"
                          color={formData[5].data[0].value === 'No' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(5, 0, 'No')}
                          active={formData[5].data[0].value === 'No'}
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  <p>
                    <br />
                  </p>
                  {/* Doctoral Internship - Q? - sub Q1 - YES */}
                  {(() => {
                    if (formData[5].data[0].value === 'Yes') {
                      return (
                        <div>
                          <FormGroup row className="submitBlock">
                            <Col className="text-center">
                              <p>Great news! We need a bit more information.</p>
                              {/* Doctoral Project - Q? - sub Q1 - YES - Q1 */}
                              <FormGroup row>
                                <Label sm={5} className="text-md-right">
                                  <i className="fa fa-chevron-right" /> Date Exam Was Taken
                                </Label>
                                <Col sm={4}>
                                  <Input
                                    type="date"
                                    id="comprehensiveExamDate"
                                    name="comprehensiveExamDate"
                                    className="dateTextbox"
                                    value={comprehensiveExamDate}
                                    onChange={this.handleInputChange}
                                  />
                                </Col>
                              </FormGroup>
                              {/* // Doctoral Project - Q? - sub Q1 - YES - Q1 */}
                              {/* Doctoral Project - Q? - sub Q1 - YES - Q2 */}
                              <Row>
                                <Col className="text-center">
                                  <Label>
                                    <i className="fa fa-chevron-right" /> Score Received
                                  </Label>
                                  <br />
                                  <Input
                                    type="text"
                                    id="comprehensiveExamScore"
                                    name="comprehensiveExamScore"
                                    className="gpa-textbox-width"
                                    value={comprehensiveExamScore}
                                    onChange={this.handleInputChange}
                                  />
                                </Col>
                              </Row>
                              <p>
                                <br />
                              </p>
                              {/* // Doctoral Project - Q? - sub Q1 - YES - Q2 */}
                            </Col>
                          </FormGroup>
                          <p>
                            <br />
                          </p>
                        </div>
                      );
                    }
                  })()}
                  {/* // Doctoral Internship - Q9 - sub Q1 - YES */}
                  {/* // Doctoral Internship - Q? */}
                  {/* Doctoral Internship - Q10 */}
                  <FormGroup>
                    <Row>
                      <Col className="text-center">
                        <Label>
                          <b>Q9</b> Did you apply for internship in the past year?
                        </Label>
                        <br />
                        <Button
                          className="btn-form"
                          id="doc-q9-b-yes"
                          color={formData[5].data[3].value === 'Yes' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(5, 3, 'Yes')}
                          active={formData[5].data[3].value === 'Yes'}
                        >
                          Yes
                        </Button>
                        <Button
                          className="btn-form"
                          id="doc-q9-b-no"
                          color={formData[5].data[3].value === 'No' ? 'dark' : 'inverse'}
                          onClick={() => this.onFrmBtnClick(5, 3, 'No')}
                          active={formData[5].data[3].value === 'No'}
                        >
                          No
                        </Button>
                      </Col>
                    </Row>
                  </FormGroup>
                  <p>
                    <br />
                  </p>
                  {/* Doctoral Internship - Q8 - applied - sub Q1  */}
                  {(() => {
                    if (formData[5].data[3].value === 'Yes') {
                      return (
                        <div>
                          <FormGroup className="submitBlock">
                            <Row>
                              <Col className="text-center">
                                <Label>
                                  <i className="fa fa-chevron-right" /> Did you successfully match
                                  for an internship?
                                </Label>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="text-center">
                                <Button
                                  className="btn-form"
                                  id="q10-s1-applied-b-yes"
                                  color={formData[5].data[4].value === 'Yes' ? 'dark' : 'inverse'}
                                  onClick={() => this.onFrmBtnClick(5, 4, 'Yes')}
                                  active={formData[5].data[4].value === 'Yes'}
                                >
                                  Yes
                                </Button>
                                <Button
                                  className="btn-form"
                                  id="q10-s1-applied-b-no"
                                  color={formData[5].data[4].value === 'No' ? 'dark' : 'inverse'}
                                  onClick={() => this.onFrmBtnClick(5, 4, 'No')}
                                  active={formData[5].data[4].value === 'No'}
                                >
                                  No
                                </Button>
                              </Col>
                            </Row>
                            <p>
                              <br />
                            </p>
                            {(() => {
                              if (formData[5].data[4].value === 'Yes') {
                                return (
                                  <div>
                                    <div>
                                      <Row>
                                        <Col className="text-center">
                                          <Label>
                                            <i className="fa fa-chevron-right" />
                                            Name of Matched Internship Program
                                          </Label>
                                        </Col>
                                      </Row>
                                      <Row>
                                        <Col className="text-center">
                                          <Input
                                            type="text"
                                            id="internshipNameInput"
                                            name="internshipNameInput"
                                            value={internshipNameInput}
                                            onChange={this.handleInputChange}
                                          />
                                        </Col>
                                      </Row>
                                      <p>
                                        <br />
                                      </p>
                                    </div>
                                    <Row>
                                      <Col className="text-center">
                                        <Label>
                                          <i className="fa fa-chevron-right" /> Is the internship
                                          program APA-accredited?
                                        </Label>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="text-center">
                                        <Button
                                          className="btn-form"
                                          id="q10-s1-applied-s1-q6-b-yes"
                                          color={
                                            formData[5].data[6].value === 'Yes' ? 'dark' : 'inverse'
                                          }
                                          onClick={() => this.onFrmBtnClick(5, 6, 'Yes')}
                                          active={formData[5].data[6].value === 'Yes'}
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          className="btn-form"
                                          id="q10-s1-applied-s1-q6-b-no"
                                          color={
                                            formData[5].data[6].value === 'No' ? 'dark' : 'inverse'
                                          }
                                          onClick={() => this.onFrmBtnClick(5, 6, 'No')}
                                          active={formData[5].data[6].value === 'No'}
                                        >
                                          No
                                        </Button>
                                      </Col>
                                    </Row>
                                    <p>
                                      <br />
                                    </p>
                                    {(() => {
                                      if (formData[5].data[6].value === 'No') {
                                        return (
                                          <div>
                                            <Row>
                                              <Col className="text-center">
                                                <Label>
                                                  <i className="fa fa-chevron-right" /> Please
                                                  upload justification for non-APA accredited
                                                  internship
                                                </Label>
                                              </Col>
                                            </Row>
                                            {this.renderUploadedFiles('internshipJustification')}
                                            <p>
                                              <br />
                                            </p>
                                          </div>
                                        );
                                      }
                                    })()}
                                    <Row>
                                      <Col className="text-center">
                                        <Label>
                                          <i className="fa fa-chevron-right" /> Is the internship
                                          program accredited by another body (i.e., CPA, PCSAS)?
                                        </Label>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col className="text-center">
                                        <Button
                                          className="btn-form"
                                          id="q10-s1-applied-s1-q6-s1-q2-b-yes"
                                          color={
                                            formData[5].data[7].value === 'Yes' ? 'dark' : 'inverse'
                                          }
                                          onClick={() => this.onFrmBtnClick(5, 7, 'Yes')}
                                          active={formData[5].data[7].value === 'Yes'}
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          className="btn-form"
                                          id="q10-s1-applied-s1-q6-s1-q2-b-no"
                                          color={
                                            formData[5].data[7].value === 'No' ? 'dark' : 'inverse'
                                          }
                                          onClick={() => this.onFrmBtnClick(5, 7, 'No')}
                                          active={formData[5].data[7].value === 'No'}
                                        >
                                          No
                                        </Button>
                                      </Col>
                                    </Row>
                                    <p>
                                      <br />
                                    </p>
                                    {(() => {
                                      if (formData[5].data[7].value === 'Yes') {
                                        return (
                                          <div>
                                            <Row>
                                              <Col className="text-center">
                                                <Label>
                                                  <i className="fa fa-chevron-right" />
                                                  Name of accrediting body
                                                </Label>
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col className="text-center">
                                                <Input
                                                  type="text"
                                                  id="accreditingBodyNameInput"
                                                  name="accreditingBodyNameInput"
                                                  value={accreditingBodyNameInput}
                                                  onChange={this.handleInputChange}
                                                />
                                              </Col>
                                            </Row>
                                            <p>
                                              <br />
                                            </p>
                                          </div>
                                        );
                                      }
                                    })()}
                                  </div>
                                );
                              }
                            })()}
                          </FormGroup>
                          <p>
                            <br />
                          </p>
                        </div>
                      );
                    }
                  })()}
                  <FormGroup row className="submitBlock">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="s4-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s4-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s4-b-cont"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'completed')}
                      >
                        Submit
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderReadout() {
    const { formData, program } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Enrollment Information
                </h5>
              }
            >
              {/* Enrollment Information - Date started program */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Date started program: <b className="ml-2">N/A</b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - Date started program */}
              {/* Enrollment Information - Academic Status */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Academic Status:{' '}
                    <b className="ml-2">
                      {formData[0].data[1].value === '' ? 'N/A' : formData[0].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - Academic Status */}
              {/* Enrollment Information - Date left program */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Date left program:{' '}
                    <b className="ml-2">
                      {formData[0].data[2].value === '' ? 'N/A' : formData[0].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - Date left program */}
              {/* Enrollment Information - Expected return date */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Expected return date:{' '}
                    <b className="ml-2">
                      {formData[0].data[3].value === '' ? 'N/A' : formData[0].data[3].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - Expected return date */}
              {/* Enrollment Information - Reason left program */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Reason left program:{' '}
                    <b className="ml-2">
                      {formData[0].data[4].value === '' ? 'N/A' : formData[0].data[4].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - Reason left program */}
              {/* Enrollment Information - other reasons */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Please specify other reasons:{' '}
                    <b className="ml-2">
                      {formData[0].data[5].value === '' ? 'N/A' : formData[0].data[5].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Enrollment Information - other reasons */}
            </Widget>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Coursework
                </h5>
              }
            >
              {/* Coursework - Q1 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q1</b>
                    </Label>{' '}
                    What is your current GPA?{' '}
                    <b className="ml-2">
                      {formData[1].data[0].value === '' ? 'N/A' : formData[1].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Coursework - Q1 */}
              {/* Coursework - Q2 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q2</b>
                    </Label>{' '}
                    Have you been first author or co-author on a poster presentation?{' '}
                    <b className="ml-2">
                      {formData[1].data[1].value === '' ? 'N/A' : formData[1].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Coursework - Q2 */}
              {/* Coursework - Q2 - sub Q1  */}
              {formData[1].data[1].value === 'Yes' && (
                <div>{this.renderUploadedFiles('posterPresentation')}</div>
              )}
              {/* // Coursework - Q2 - sub Q1 */}
              {/* Coursework - Q3 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q3</b>
                    </Label>{' '}
                    Have you been first author or co-author on a published paper?{' '}
                    <b className="ml-2">
                      {formData[1].data[2].value === '' ? 'N/A' : formData[1].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Coursework - Q3 */}
              {/* Coursework - Q3 - sub Q1  */}
              {formData[1].data[2].value === 'Yes' && (
                <div>{this.renderUploadedFiles('publishedPaper')}</div>
              )}
              {/* // Coursework - Q3 - sub Q1 */}
              {/* Coursework - Q4 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q4</b>
                    </Label>{' '}
                    Have you been a teaching assistant for any courses?{' '}
                    <b className="ml-2">
                      {formData[1].data[3].value === '' ? 'N/A' : formData[1].data[3].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Coursework - Q4 */}
              {/* Coursework - Q4 - sub Q1  */}
              {formData[1].data[3].value === 'Yes' && (
                <div>
                  <Row className="justify-content-center">
                    <Col md={6}> {this.renderTextArray('teachingAssistantClasses')}</Col>
                  </Row>
                </div>
              )}
              {/* // Coursework - Q4 - sub Q1 */}
            </Widget>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Professional Activities
                </h5>
              }
            >
              {/* Professional Activities - Q5 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q5</b>
                    </Label>{' '}
                    Did you join or are you a current member of a professional society?{' '}
                    <b className="ml-2">
                      {formData[2].data[0].value === '' ? 'N/A' : formData[2].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Professional Activities - Q5 */}
              {/* Professional Activities - Q5 - sub Q1  */}
              {formData[2].data[0].value === 'Yes' && (
                <div>
                  <Row className="justify-content-center">
                    <Col md={6}> {this.renderTextArray('professionalSocieties')}</Col>
                  </Row>
                </div>
              )}
              {/* // Professional Activities - Q5 - sub Q1 */}
              {/* Professional Activities - Q5 - sub Q2 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Are you involved in any leadership roles??{' '}
                    <b className="ml-2">
                      {formData[2].data[2].value === '' ? 'N/A' : formData[2].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Professional Activities - Q5 - sub Q2 */}
              {/* Professional Activities - Q5 - sub Q3  */}
              {formData[2].data[2].value === 'Yes' && (
                <div>
                  <Row className="justify-content-center">
                    <Col md={6}> {this.renderTextArray('leadershipRoles')}</Col>
                  </Row>
                </div>
              )}
              {/* // Professional Activities - Q5 - sub Q3 */}
              {/* Professional Activities - Q6 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q6</b>
                    </Label>{' '}
                    Have you presented a psychological topic to a lay or community audience?{' '}
                    <b className="ml-2">
                      {formData[2].data[4].value === '' ? 'N/A' : formData[2].data[4].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Professional Activities - Q6 */}
              {/* Professional Activities - Q6 - sub Q1  */}
              {formData[2].data[4].value === 'Yes' && (
                <div>
                  <Row className="justify-content-center">
                    <Col md={6}> {this.renderTextArray('psychologicalTopics')}</Col>
                  </Row>
                </div>
              )}
              {/* // Professional Activities - Q6 - sub Q1 */}
            </Widget>
            {program === 'Psy.D.' && (
              <Widget
                title={
                  <h5>
                    <i className="fa fa-align-left" /> Doctoral Project
                  </h5>
                }
              >
                {/* Doctoral Project - Q7 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      <Label>
                        <b>Q7</b>
                      </Label>{' '}
                      Have you successfully passed your doctoral project defense?{' '}
                      <b className="ml-2">
                        {formData[3].data[0].value === '' ? 'N/A' : formData[3].data[0].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 */}
                {/* Doctoral Project - Q7 - sub Q4 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Has your committee passed your doctoral project proposal?{' '}
                      <b className="ml-2">
                        {formData[3].data[3].value === '' ? 'N/A' : formData[3].data[3].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q4 */}
                {/* Doctoral Project - Q7 - sub Q1 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Doctoral project title:{' '}
                      <b className="ml-2">
                        {formData[3].data[1].value === '' ? 'N/A' : formData[3].data[1].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q1 */}
                {/* Doctoral Project - Q7 - sub Q2 */}{' '}
                <Row className="justify-content-center mt-4">
                  <Col md={6} className="text-center">
                    <p className="readout">
                      <u>Committee Members</u>
                    </p>
                    {formData[3].data[5].value.length === 0 ? (
                      <b className="ml-2">N/A</b>
                    ) : (
                      this.renderTextArray('doctoralCommitteeMembers')
                    )}
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q2 */}
                {/* Doctoral Project - Q7 - sub Q3 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Defense passed date:{' '}
                      <b className="ml-2">
                        {formData[3].data[2].value === '' ? 'N/A' : formData[3].data[2].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q3 */}
                {/* Doctoral Project - Q7 - sub Q5 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Proposal passed date:{' '}
                      <b className="ml-2">
                        {formData[3].data[4].value === '' ? 'N/A' : formData[3].data[4].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q5 */}
                {/* Doctoral Project - Q7 - sub Q6 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you started working on completing your doctoral project for the final
                      defense?{' '}
                      <b className="ml-2">
                        {formData[3].data[6].value === '' ? 'N/A' : formData[3].data[6].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q6 */}
                {/* Doctoral Project - Q7 - sub Q7 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you identified a faculty chair for your doctoral project?{' '}
                      <b className="ml-2">
                        {formData[3].data[7].value === '' ? 'N/A' : formData[3].data[7].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q7 */}
                {/* Doctoral Project - Q7 - sub Q8 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you identified a topic area for your doctoral project?{' '}
                      <b className="ml-2">
                        {formData[3].data[8].value === '' ? 'N/A' : formData[3].data[8].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q8 */}
                {/* Doctoral Project - Q7 - sub Q9 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you settled on a general design or plan for conducting your doctoral
                      project?{' '}
                      <b className="ml-2">
                        {formData[3].data[9].value === '' ? 'N/A' : formData[3].data[9].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q9 */}
                {/* Doctoral Project - Q7 - sub Q10 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you started writing your doctoral project proposal?{' '}
                      <b className="ml-2">
                        {formData[3].data[10].value === '' ? 'N/A' : formData[3].data[10].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Doctoral Project - Q7 - sub Q10 */}
              </Widget>
            )}
            {program === 'Ph.D.' && (
              <Widget
                title={
                  <h5>
                    <i className="fa fa-align-left" /> Dissertation Questions
                  </h5>
                }
              >
                {/* Dissertation Questions - Q7 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      <Label>
                        <b>Q7</b>
                      </Label>{' '}
                      Have you successfully passed your dissertation defense?{' '}
                      <b className="ml-2">
                        {formData[4].data[0].value === '' ? 'N/A' : formData[4].data[0].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 */}
                {/* Dissertation Questions - Q7 - sub Q4 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Has your committee passed your dissertation proposal?{' '}
                      <b className="ml-2">
                        {formData[4].data[3].value === '' ? 'N/A' : formData[4].data[3].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q4 */}
                {/* Dissertation Questions - Q7 - sub Q1 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Dissertation project title:{' '}
                      <b className="ml-2">
                        {formData[4].data[1].value === '' ? 'N/A' : formData[4].data[1].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q1 */}
                {/* Dissertation Questions - Q7 - sub Q2 */}{' '}
                <Row className="justify-content-center mt-4">
                  <Col md={6} className="text-center">
                    <p className="readout">
                      <u>Committee Members</u>
                    </p>
                    {formData[4].data[5].value.length === 0 ? (
                      <b className="ml-2">N/A</b>
                    ) : (
                      this.renderTextArray('dissertationCommitteeMembers')
                    )}
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q2 */}
                {/* Dissertation Questions - Q7 - sub Q3 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Defense passed date:{' '}
                      <b className="ml-2">
                        {formData[4].data[2].value === '' ? 'N/A' : formData[4].data[2].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q3 */}
                {/* Dissertation Questions - Q7 - sub Q5 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Proposal passed date:{' '}
                      <b className="ml-2">
                        {formData[4].data[4].value === '' ? 'N/A' : formData[4].data[4].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q5 */}
                {/* Dissertation Questions - Q7 - sub Q6 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you started writing up your results for your dissertation defense?{' '}
                      <b className="ml-2">
                        {formData[4].data[6].value === '' ? 'N/A' : formData[4].data[6].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q6 */}
                {/* Dissertation Questions - Q7 - sub Q7 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you finished data collection for your dissertation? If your dissertation
                      does not involve data collection, have you started your analysis or other
                      required work?{' '}
                      <b className="ml-2">
                        {formData[4].data[7].value === '' ? 'N/A' : formData[4].data[7].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q7 */}
                {/* Dissertation Questions - Q7 - sub Q8 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you started any data collection for your dissertation?{' '}
                      <b className="ml-2">
                        {formData[4].data[8].value === '' ? 'N/A' : formData[4].data[8].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q8 */}
                {/* Dissertation Questions - Q7 - sub Q9 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you identified a faculty chair for your dissertation?{' '}
                      <b className="ml-2">
                        {formData[4].data[9].value === '' ? 'N/A' : formData[4].data[9].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q9 */}
                {/* Dissertation Questions - Q7 - sub Q10 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you identified a topic area for your dissertation?{' '}
                      <b className="ml-2">
                        {formData[4].data[10].value === '' ? 'N/A' : formData[4].data[10].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q10 */}
                {/* Dissertation Questions - Q7 - sub Q11 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you settled on a general design or plan for conducting the dissertation?{' '}
                      <b className="ml-2">
                        {formData[4].data[11].value === '' ? 'N/A' : formData[4].data[11].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q11 */}
                {/* Dissertation Questions - Q7 - sub Q12 */}
                <Row className="mt-4">
                  <Col className="text-center">
                    <p className="readout">
                      Have you started writing your dissertation proposal?{' '}
                      <b className="ml-2">
                        {formData[4].data[12].value === '' ? 'N/A' : formData[4].data[12].value}
                      </b>
                    </p>
                  </Col>
                </Row>
                {/* // Dissertation Questions - Q7 - sub Q12 */}
              </Widget>
            )}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Doctoral Internship
                </h5>
              }
            >
              {/* Doctoral Internship - Q8 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q8</b>
                    </Label>{' '}
                    Have you taken your comprehensive exam (EPPP)?{' '}
                    <b className="ml-2">
                      {formData[5].data[0].value === '' ? 'N/A' : formData[5].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q8 */}
              {/* Doctoral Internship - Q8 - sub Q1 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Date Exam Was Taken:{' '}
                    <b className="ml-2">
                      {formData[5].data[1].value === '' ? 'N/A' : formData[5].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q8 - sub Q1 */}
              {/* Doctoral Internship - Q8 - sub Q2 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Score Received:{' '}
                    <b className="ml-2">
                      {formData[5].data[2].value === '' ? 'N/A' : formData[5].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q8 - sub Q2 */}
              {/* Doctoral Internship - Q9 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q9</b>
                    </Label>{' '}
                    Did you apply for internship in the past year?{' '}
                    <b className="ml-2">
                      {formData[5].data[3].value === '' ? 'N/A' : formData[5].data[3].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 */}
              {/* Doctoral Internship - Q9 - sub Q1 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Did you successfully match for an internship?{' '}
                    <b className="ml-2">
                      {formData[5].data[4].value === '' ? 'N/A' : formData[5].data[4].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 - sub Q1 */}
              {/* Doctoral Internship - Q9 - sub Q2 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Name of Matched Internship Program{' '}
                    <b className="ml-2">
                      {formData[5].data[5].value === '' ? 'N/A' : formData[5].data[5].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 - sub Q2 */}
              {/* Doctoral Internship - Q9 - sub Q3 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Is the internship program APA-accredited?{' '}
                    <b className="ml-2">
                      {formData[5].data[6].value === '' ? 'N/A' : formData[5].data[6].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 - sub Q3 */}
              {/* Doctoral Internship - Q9 - sub Q4 */}
              <Row className="justify-content-center mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Please upload justification for non-APA accredited internship:
                  </p>
                </Col>
              </Row>
              {formData[5].data[6].value === 'Yes' && (
                <div>{this.renderUploadedFiles('internshipJustification')}</div>
              )}
              {/* // Doctoral Internship - Q9 - sub Q4 */}
              {/* Doctoral Internship - Q9 - sub Q5 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Is the internship program accredited by another body (i.e., CPA, PCSAS)?{' '}
                    <b className="ml-2">
                      {formData[5].data[7].value === '' ? 'N/A' : formData[5].data[7].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 - sub Q5 */}
              {/* Doctoral Internship - Q9 - sub Q6 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Name of accrediting body:{' '}
                    <b className="ml-2">
                      {formData[5].data[8].value === '' ? 'N/A' : formData[5].data[8].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Doctoral Internship - Q9 - sub Q6 */}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, error, currentSection, studentName, program } = this.state;
    if (!loading && !error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Row>
                <Col xs="12">
                  <div className="crumbs">
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link to="/profile">Profile</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Student Progress Report</BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </Col>
              </Row>
              <h2 className="page-title">
                Student Progress Report: <small>{studentName}</small>
              </h2>
              {currentSection === 0 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={0} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 0 && this.renderEnrollmentInformation()}
              {currentSection === 1 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={10} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 1 && this.renderCoursework()}
              {currentSection === 2 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={50} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 2 && this.renderProfessionalActivities()}
              {currentSection === 3 && program === 'Psy.D.' && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={70} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 3 && program === 'Psy.D.' && this.renderDoctoralProject()}
              {currentSection === 3 && program === 'Ph.D.' && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={70} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 3 && program === 'Ph.D.' && this.renderDissertationQuestions()}
              {currentSection === 4 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={90} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 4 && this.renderDoctoralInternship()}
              {currentSection === 999 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={100} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 999 && this.renderReadout()}
            </Col>
          </Row>
        </div>
      );
    }
    if (loading) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <h2 className="page-title">Student Progress Report</h2>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
            </Col>
          </Row>
        </div>
      );
    }
    if (error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Widget>
                <Row className="mt-4 mb-4 text-center">
                  <Col>
                    <h4>
                      There was an error loading the form, please reload the page and try again.
                    </h4>
                  </Col>
                </Row>
              </Widget>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

SPR.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(SPR);
