/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Navbar, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import auth0Client from '../../Auth/Auth';

import './Header.scss';

function Header(props) {
  const logout = () => {
    auth0Client.logout();
    // eslint-disable-next-line react/destructuring-assignment
    props.history.replace('/');
  };

  return (
    <div className="tops">
      <Navbar className="d-print-none">
        <NavbarBrand className="logo" style={{ color: 'white' }} tag={Link} to="/profile">
          LLU SBH <strong>Student Evaluation System</strong>
        </NavbarBrand>
        <Nav className="ml-auto">
          {auth0Client.isAuthenticated() && (
            <div>
              <NavItem className="divider" />
              <NavItem className="d-sm-none-down">
                <NavLink
                  onClick={() => {
                    logout();
                  }}
                  className="navItem"
                >
                  <i className="fa fa-power-off text-white" />
                </NavLink>
              </NavItem>
            </div>
          )}
          {!auth0Client.isAuthenticated() && (
            <NavItem className="d-sm-none-down">
              <NavLink onClick={auth0Client.login} className="navItem">
                <h4 className="navbar-login">Login</h4>
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </Navbar>
    </div>
  );
}

Header.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Header);
