/* eslint-disable class-methods-use-this */
/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Alert,
  Progress,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../../Auth/Auth';

import SurveyButtonGroup from '../../SurveyButtonGroup/SurveyButtonGroup';
import Widget from '../../Widget/Widget';

import '../forms.scss';

import loadingSpinner from '../../../images/Eclipse-1s-200px.svg';

const currentDate = new Date();
const currentDateString = new Date().toISOString().substring(0, 10);

class AS extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.onUploadFileBtnClick = this.onUploadFileBtnClick.bind(this);
    this.onFrmBtnClick = this.onFrmBtnClick.bind(this);
    this.onFrmBtnToggle = this.onFrmBtnToggle.bind(this);
    this.onSectionBtnClick = this.onSectionBtnClick.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.renderPostdoctoralTrainingProgramQuestions =
      this.renderPostdoctoralTrainingProgramQuestions.bind(this);
    this.renderProfessionalEmploymentQuestions =
      this.renderProfessionalEmploymentQuestions.bind(this);
    this.renderStateLicensedPsychologistQuestions =
      this.renderStateLicensedPsychologistQuestions.bind(this);
    this.renderUploadedFiles = this.renderUploadedFiles.bind(this);
    this.renderDemographics = this.renderDemographics.bind(this);
    this.renderPostdoctoralTrainingProgram = this.renderPostdoctoralTrainingProgram.bind(this);
    this.renderProfessionalEmployment = this.renderProfessionalEmployment.bind(this);
    this.renderStateLicensedPsychologist = this.renderStateLicensedPsychologist.bind(this);
    this.renderOtherProfessionalActivities = this.renderOtherProfessionalActivities.bind(this);
    this.renderProgramFeedback = this.renderProgramFeedback.bind(this);
    this.renderResearchAndEthicalStandards = this.renderResearchAndEthicalStandards.bind(this);
    this.renderLegalAndEthicalStandards = this.renderLegalAndEthicalStandards.bind(this);
    this.renderIndividualAndCulturalDiversity =
      this.renderIndividualAndCulturalDiversity.bind(this);
    this.renderProfessionalValuesAndAttitudes =
      this.renderProfessionalValuesAndAttitudes.bind(this);
    this.renderCommunicationAndInterpersonalSkills =
      this.renderCommunicationAndInterpersonalSkills.bind(this);
    this.renderAssessment = this.renderAssessment.bind(this);
    this.renderIntervention = this.renderIntervention.bind(this);
    this.renderSupervisionConsultationAndInterpersonalSkills =
      this.renderSupervisionConsultationAndInterpersonalSkills.bind(this);

    this.state = {
      formData: [
        {
          id: 'Demographics',
          data: [
            {
              id: 'permanentEmail',
              value: '',
            },
            {
              id: 'yearGraduated',
              value: '',
            },
            {
              id: 'ethnicity',
              value: '',
            },
            {
              id: 'race',
              value: '',
            },
            {
              id: 'firstGenerationCollegeGraduate',
              value: '',
            },
            {
              id: 'veteran',
              value: '',
            },
          ],
        },
        {
          id: 'Formal Postdoctoral Training Program',
          data: [
            {
              id: 'currentlyInProgram',
              value: '',
            },
            {
              id: 'programName',
              value: '',
            },
            {
              id: 'programAddress',
              value: '',
            },
            {
              id: 'accredited',
              value: '',
            },
            {
              id: 'emphasis',
              value: '',
            },
            {
              id: 'otherEmphasis',
              value: '',
            },
            {
              id: 'activities',
              value: [
                {
                  id: 'Administration',
                  value: -1,
                },
                {
                  id: 'Assessment',
                  value: -1,
                },
                {
                  id: 'Consultation',
                  value: -1,
                },
                {
                  id: 'Psychotherapy',
                  value: -1,
                },
                {
                  id: 'Research',
                  value: -1,
                },
                {
                  id: 'Supervision',
                  value: -1,
                },
                {
                  id: 'Teaching',
                  value: -1,
                },
                {
                  id: 'Unknown',
                  value: -1,
                },
                {
                  id: 'Other',
                  value: -1,
                },
              ],
            },
          ],
        },
        {
          id: 'Professional Employment',
          data: [
            {
              id: 'currentlyEmployed',
              value: '',
            },
            {
              id: 'employerName',
              value: '',
            },
            {
              id: 'employerAddress',
              value: '',
            },
            {
              id: 'jobTitle',
              value: '',
            },
            {
              id: 'positionSettingTypes',
              value: [
                {
                  id: 'Academic Teaching',
                  value: -1,
                },
                {
                  id: 'Academic Medical Center',
                  value: -1,
                },
                {
                  id: 'Area Health Education Center',
                  value: -1,
                },
                {
                  id: 'Certified Community Behavioral Health Center (CCBHC)',
                  value: -1,
                },
                {
                  id: 'Community Mental Health Center',
                  value: -1,
                },
                {
                  id: 'Community Health Center (CHC)',
                  value: -1,
                },
                {
                  id: 'Consortium',
                  value: -1,
                },
                {
                  id: 'Correctional Facility',
                  value: -1,
                },
                {
                  id: 'Critical Access Hospital',
                  value: -1,
                },
                {
                  id: 'Federal Government',
                  value: -1,
                },
                {
                  id: 'FQHC or Look-Alike',
                  value: -1,
                },
                {
                  id: 'Health Department (local/state/tribal)',
                  value: -1,
                },
                {
                  id: 'Health Maintenance Organization',
                  value: -1,
                },
                {
                  id: 'Hospital (non-academic)',
                  value: -1,
                },
                {
                  id: 'Indian Health Service (HIS)/Tribal/Urban Indian Health Center',
                  value: -1,
                },
                {
                  id: 'Independent Practice',
                  value: -1,
                },
                {
                  id: 'Nursing Home',
                  value: -1,
                },
                {
                  id: 'Other Clinical Health Setting',
                  value: -1,
                },
                {
                  id: 'Other Community-Based Organization',
                  value: -1,
                },
                {
                  id: 'Other Long-term Care Facility',
                  value: -1,
                },
                {
                  id: 'Other Specialty Clinic',
                  value: -1,
                },
                {
                  id: 'Private Industry',
                  value: -1,
                },
                {
                  id: 'Psychiatric Facility',
                  value: -1,
                },
                {
                  id: 'Residential Living Facility (including independent and assisted living)',
                  value: -1,
                },
                {
                  id: 'Rural Health Clinic',
                  value: -1,
                },
                {
                  id: 'School District or System',
                  value: -1,
                },
                {
                  id: 'State or Local Government',
                  value: -1,
                },
                {
                  id: 'US Armed Forces',
                  value: -1,
                },
                {
                  id: 'Veterans Affairs Healthcare (e.g., VA hospital or clinic)',
                  value: -1,
                },
                {
                  id: 'Pursuing Additional Education or Training',
                  value: -1,
                },
                {
                  id: 'University Counseling Center',
                  value: -1,
                },
                {
                  id: 'Not Currently Employed',
                  value: -1,
                },
                {
                  id: 'Other',
                  value: -1,
                },
              ],
            },
            {
              id: 'otherPositionSetting',
              value: '',
            },
            {
              id: 'activities',
              value: [
                {
                  id: 'Administration',
                  value: -1,
                },
                {
                  id: 'Assessment',
                  value: -1,
                },
                {
                  id: 'Consultation',
                  value: -1,
                },
                {
                  id: 'Psychotherapy',
                  value: -1,
                },
                {
                  id: 'Research',
                  value: -1,
                },
                {
                  id: 'Supervision',
                  value: -1,
                },
                {
                  id: 'Teaching',
                  value: -1,
                },
                {
                  id: 'Unknown',
                  value: -1,
                },
                {
                  id: 'Other',
                  value: -1,
                },
              ],
            },
            {
              id: 'workSettings',
              value: [
                {
                  id: 'Health Professional Shortage Area',
                  value: -1,
                },
                {
                  id: 'Medically Underserved Community',
                  value: -1,
                },
                {
                  id: 'National Health Service Corps (NHSC) - Approved Site',
                  value: -1,
                },
                {
                  id: 'Primary Care Setting',
                  value: -1,
                },
                {
                  id: 'Rural Area',
                  value: -1,
                },
                {
                  id: 'None of the Above',
                  value: -1,
                },
                {
                  id: 'Other:',
                  value: -1,
                },
              ],
            },
            {
              id: 'otherWorkSetting',
              value: '',
            },
          ],
        },
        {
          id: 'State Licensed Psychologist',
          data: [
            {
              id: 'obtainedLicense',
              value: '',
            },
            {
              id: 'state',
              value: '',
            },
            {
              id: 'date',
              value: '',
            },
            {
              id: 'number',
              value: '',
            },
            {
              id: 'npiNumber',
              value: '',
            },
          ],
        },
        {
          id: 'Other Professional Activities',
          data: [
            {
              id: 'organizationMember',
              value: '',
            },
            {
              id: 'organizationName',
              value: '',
            },
            {
              id: 'publications',
              value: '',
            },
            {
              id: 'conferencePresentations',
              value: '',
            },
            {
              id: 'cv',
              value: '',
            },
          ],
        },
        {
          id: 'Program Feedback',
          data: [
            {
              id: 'doWell',
              value: '',
            },
            {
              id: 'doneBetter',
              value: '',
            },
            {
              id: 'suggestions',
              value: '',
            },
          ],
        },
        {
          id: 'Research and Ethical Standards',
          data: [
            {
              id: 'q10',
              value: '',
            },
            {
              id: 'q11',
              value: '',
            },
            {
              id: 'q12',
              value: '',
            },
          ],
        },
        {
          id: 'Legal and Ethical Standards',
          data: [
            {
              id: 'q13',
              value: '',
            },
            {
              id: 'q14',
              value: '',
            },
            {
              id: 'q15',
              value: '',
            },
          ],
        },
        {
          id: 'Individual and Cultural Diversity',
          data: [
            {
              id: 'q16',
              value: '',
            },
            {
              id: 'q17',
              value: '',
            },
            {
              id: 'q18',
              value: '',
            },
            {
              id: 'q19',
              value: '',
            },
          ],
        },
        {
          id: 'Professional Values and Attitudes',
          data: [
            {
              id: 'q20',
              value: '',
            },
            {
              id: 'q21',
              value: '',
            },
            {
              id: 'q22',
              value: '',
            },
            {
              id: 'q23',
              value: '',
            },
            {
              id: 'q24',
              value: '',
            },
          ],
        },
        {
          id: 'Communication and Interpersonal Skills',
          data: [
            {
              id: 'q25',
              value: '',
            },
            {
              id: 'q26',
              value: '',
            },
            {
              id: 'q27',
              value: '',
            },
            {
              id: 'q28',
              value: '',
            },
          ],
        },
        {
          id: 'Assessment',
          data: [
            {
              id: 'q29',
              value: '',
            },
            {
              id: 'q30',
              value: '',
            },
            {
              id: 'q31',
              value: '',
            },
            {
              id: 'q32',
              value: '',
            },
          ],
        },
        {
          id: 'Intervention',
          data: [
            {
              id: 'q33',
              value: '',
            },
            {
              id: 'q34',
              value: '',
            },
            {
              id: 'q35',
              value: '',
            },
            {
              id: 'q36',
              value: '',
            },
            {
              id: 'q37',
              value: '',
            },
          ],
        },
        {
          id: 'Supervision, Consultation and Interpersonal/Interdisciplinary Skills',
          data: [
            {
              id: 'q38',
              value: '',
            },
            {
              id: 'q39',
              value: '',
            },
            {
              id: 'q40',
              value: '',
            },
          ],
        },
      ],
      emailAddress: '',
      yearGraduatedSelect: parseInt(currentDate.getFullYear, 2),
      ethnicitySelect: 'Hispanic/Latino',
      raceSelect: 'American Indian',
      postdoctoralProgramName: '',
      postdoctoralProgramAddress: '',
      otherEmphasis: '',
      employerName: '',
      employerAddress: '',
      jobTitle: '',
      otherPositionSetting: '',
      otherWorkSetting: '',
      stateSelect: 'CA',
      dateSelect: currentDateString,
      licenseNumber: '',
      npiNumber: '',
      organizationName: '',
      publicationSelect: '0',
      presentationsSelect: '0',
      doWell: '',
      doneBetter: '',
      suggestions: '',
      inputKey: Date.now(),
      selectedFile: null,
      uploadPercentage: 0,
      uploadedFiles: [],
      status: '',
      studentName: '',
      currentSection: 0,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.getForm();
  }

  handleInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleFileChange(e) {
    this.setState({
      selectedFile: e.target.files[0],
      uploadPercentage: 0,
    });
  }

  onUploadFileBtnClick(e, uploadType) {
    const {
      match: { params },
    } = this.props;

    const url = `${process.env.REACT_APP_API_URL}/file`;
    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const { studentID, selectedFile } = this.state;
    const data = new FormData();

    data.append('form', params.formid);
    data.append('student', studentID);
    data.append('uploadType', uploadType);
    data.append('file', selectedFile);

    axios
      .post(url, data, config, {
        onUploadProgress: (ProgressEvent) => {
          this.setState({
            uploadPercentage: (ProgressEvent.loaded / ProgressEvent.total) * 100,
          });
        },
      })
      .then(() => {
        this.setState({ inputKey: Date.now(), selectedFile: null });
        this.getUploadedFiles();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  onFrmBtnClick(sectionID, questionID, a, sA) {
    /* a === answer w/ 3 args, subID w/ 4 args
      sA === answer w/ 4 args */
    const newState = {
      // eslint-disable-next-line react/destructuring-assignment
      ...this.state.formData,
    };

    if (arguments.length === 3) {
      newState[sectionID].data[questionID].value = a;
    } else if (arguments.length === 4) {
      newState[sectionID].data[questionID].data[a].value = sA;
    }

    this.setState({
      formData: newState,
    });
  }

  onFrmBtnToggle(e, sectionID, questionID, subQuestionID) {
    e.preventDefault();
    e.target.blur();

    const newState = {
      // eslint-disable-next-line react/destructuring-assignment
      ...this.state.formData,
    };

    if (arguments.length === 3) {
      newState[sectionID].data[questionID].value *= -1;
    } else if (arguments.length === 4) {
      newState[sectionID].data[questionID].value[subQuestionID].value *= -1;
    }

    this.setState({
      formData: newState,
    });
  }

  onSectionBtnClick(e, sectionValue) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const { currentSection } = this.state;
    const newSectionID = currentSection + sectionValue;

    this.setState({
      currentSection: newSectionID,
    });
  }

  getUploadedFiles() {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/file/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        this.setState({ uploadedFiles: response.data, loading: false });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  getForm() {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/form/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        // check if data is valid
        const { data, status, student } = response.data;
        const { _id } = student;
        const studentName = `${student.firstName} ${student.lastName}`;

        if (status !== 'new') {
          let {
            formData,
            emailAddress,
            yearGraduatedSelect,
            ethnicitySelect,
            raceSelect,
            postdoctoralProgramName,
            postdoctoralProgramAddress,
            otherEmphasis,
            employerName,
            employerAddress,
            jobTitle,
            otherPositionSetting,
            otherWorkSetting,
            stateSelect,
            dateSelect,
            licenseNumber,
            npiNumber,
            organizationName,
            publicationSelect,
            presentationsSelect,
            doWell,
            doneBetter,
            suggestions,
            currentSection,
          } = this.state;

          // check if data was returned in a array
          if (data.length === 14) {
            formData = data;
          } else {
            // eslint-disable-next-line prefer-destructuring
            formData = data[0];
          }

          // * Demographics
          emailAddress = formData[0].data[0].value;
          if (formData[0].data[1].value !== '') {
            yearGraduatedSelect = formData[0].data[1].value;
          }
          if (formData[0].data[2].value !== '') {
            ethnicitySelect = formData[0].data[2].value;
          }
          if (formData[0].data[3].value !== '') {
            raceSelect = formData[0].data[3].value;
          }

          // * Formal Postdoctoral Training Program
          postdoctoralProgramName = formData[1].data[1].value;
          postdoctoralProgramAddress = formData[1].data[2].value;
          otherEmphasis = formData[1].data[5].value;

          // * Professional Employment
          employerName = formData[2].data[1].value;
          employerAddress = formData[2].data[2].value;
          jobTitle = formData[2].data[3].value;
          otherPositionSetting = formData[2].data[5].value;
          otherWorkSetting = formData[2].data[8].value;

          // * State Licensed Psychologist
          if (formData[3].data[1].value !== '') {
            stateSelect = formData[3].data[1].value;
          }
          if (formData[3].data[2].value !== '') {
            dateSelect = formData[3].data[2].value;
          }
          licenseNumber = formData[3].data[3].value;
          npiNumber = formData[3].data[4].value;

          // * Other Professional Activities
          organizationName = formData[4].data[1].value;
          if (formData[4].data[2].value !== '') {
            publicationSelect = formData[4].data[2].value;
          }
          if (formData[4].data[3].value !== '') {
            presentationsSelect = formData[4].data[3].value;
          }

          // * Program Feedback
          doWell = formData[5].data[0].value;
          doneBetter = formData[5].data[1].value;
          suggestions = formData[5].data[2].value;

          // if form is cmmpleted, set currentSection to display readout
          if (status === 'completed') {
            currentSection = 999;
          }

          // form inProgress/completed, set student info and formData
          this.setState({
            formData,
            emailAddress,
            yearGraduatedSelect,
            ethnicitySelect,
            raceSelect,
            postdoctoralProgramName,
            postdoctoralProgramAddress,
            otherEmphasis,
            employerName,
            employerAddress,
            jobTitle,
            otherPositionSetting,
            otherWorkSetting,
            stateSelect,
            dateSelect,
            licenseNumber,
            npiNumber,
            organizationName,
            publicationSelect,
            presentationsSelect,
            doWell,
            doneBetter,
            suggestions,
            studentID: _id,
            studentName,
            status,
            currentSection,
          });
          this.getUploadedFiles();
        } else {
          // new form, set student info
          this.setState({
            studentID: _id,
            studentName,
          });
          this.getUploadedFiles();
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  saveForm(e, newStatus) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const {
      match: { params },
    } = this.props;
    // const url = 'https://llu-api.modemtones.com/form/update';
    const url = `${process.env.REACT_APP_API_URL}/form/update`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const id = params.formid;
    const {
      formData,
      emailAddress,
      yearGraduatedSelect,
      ethnicitySelect,
      raceSelect,
      postdoctoralProgramName,
      postdoctoralProgramAddress,
      otherEmphasis,
      employerName,
      employerAddress,
      jobTitle,
      otherPositionSetting,
      otherWorkSetting,
      stateSelect,
      dateSelect,
      licenseNumber,
      npiNumber,
      organizationName,
      publicationSelect,
      presentationsSelect,
      doWell,
      doneBetter,
      suggestions,
    } = this.state;

    // * Demographics
    // set qs from inputs
    formData[0].data[0].value = emailAddress;
    formData[0].data[1].value = yearGraduatedSelect;
    formData[0].data[2].value = ethnicitySelect;
    formData[0].data[3].value = raceSelect;

    // * Formal Postdoctoral Training Program
    // clear sub-qs if currentlyInProgram === 'No'
    if (formData[1].data[0].value === 'No') {
      formData[1].data[1].value = '';
      formData[1].data[2].value = '';
      formData[1].data[3].value = '';
      formData[1].data[4].value = '';
      formData[1].data[5].value = '';
      formData[1].data[6].value[0].value = -1;
      formData[1].data[6].value[1].value = -1;
      formData[1].data[6].value[2].value = -1;
      formData[1].data[6].value[3].value = -1;
      formData[1].data[6].value[4].value = -1;
      formData[1].data[6].value[5].value = -1;
      formData[1].data[6].value[6].value = -1;
      formData[1].data[6].value[7].value = -1;
      formData[1].data[6].value[8].value = -1;
    } else {
      // set sub-qs from inputs
      formData[1].data[1].value = postdoctoralProgramName;
      formData[1].data[2].value = postdoctoralProgramAddress;
      // set otherEmphasis if emphasis === 'Other'
      if (formData[1].data[4].value === 'Other') {
        formData[1].data[5].value = otherEmphasis;
      } else {
        formData[1].data[5].value = '';
      }
    }

    // * Professional Employment
    // clear sub-qs if currentlyEmployed === 'No'
    if (formData[2].data[0].value === 'No') {
      formData[2].data[1].value = '';
      formData[2].data[2].value = '';
      formData[2].data[3].value = '';
      formData[2].data[4].value[0].value = -1;
      formData[2].data[4].value[1].value = -1;
      formData[2].data[4].value[2].value = -1;
      formData[2].data[4].value[3].value = -1;
      formData[2].data[4].value[4].value = -1;
      formData[2].data[4].value[5].value = -1;
      formData[2].data[4].value[6].value = -1;
      formData[2].data[4].value[7].value = -1;
      formData[2].data[4].value[8].value = -1;
      formData[2].data[4].value[9].value = -1;
      formData[2].data[4].value[10].value = -1;
      formData[2].data[4].value[11].value = -1;
      formData[2].data[4].value[12].value = -1;
      formData[2].data[4].value[13].value = -1;
      formData[2].data[4].value[14].value = -1;
      formData[2].data[4].value[15].value = -1;
      formData[2].data[4].value[16].value = -1;
      formData[2].data[4].value[17].value = -1;
      formData[2].data[4].value[18].value = -1;
      formData[2].data[4].value[19].value = -1;
      formData[2].data[4].value[20].value = -1;
      formData[2].data[4].value[21].value = -1;
      formData[2].data[4].value[22].value = -1;
      formData[2].data[4].value[23].value = -1;
      formData[2].data[4].value[24].value = -1;
      formData[2].data[4].value[25].value = -1;
      formData[2].data[4].value[26].value = -1;
      formData[2].data[4].value[27].value = -1;
      formData[2].data[4].value[28].value = -1;
      formData[2].data[4].value[29].value = -1;
      formData[2].data[4].value[30].value = -1;
      formData[2].data[4].value[31].value = -1;
      formData[2].data[4].value[32].value = -1;
      formData[2].data[5].value = '';
      formData[2].data[6].value[0].value = -1;
      formData[2].data[6].value[1].value = -1;
      formData[2].data[6].value[2].value = -1;
      formData[2].data[6].value[3].value = -1;
      formData[2].data[6].value[4].value = -1;
      formData[2].data[6].value[5].value = -1;
      formData[2].data[6].value[6].value = -1;
      formData[2].data[6].value[7].value = -1;
      formData[2].data[6].value[8].value = -1;
      formData[2].data[7].value[0].value = -1;
      formData[2].data[7].value[1].value = -1;
      formData[2].data[7].value[2].value = -1;
      formData[2].data[7].value[3].value = -1;
      formData[2].data[7].value[4].value = -1;
      formData[2].data[7].value[5].value = -1;
      formData[2].data[7].value[6].value = -1;
      formData[2].data[8].value = '';
    } else {
      // set sub-qs from inputs
      formData[2].data[1].value = employerName;
      formData[2].data[2].value = employerAddress;
      formData[2].data[3].value = jobTitle;
      // set otherPositionSetting if positionSettingTypes === 1
      if (formData[2].data[4].value[32].value === 1) {
        formData[2].data[5].value = otherPositionSetting;
      } else {
        formData[2].data[5].value = '';
      }
      // set otherWorkSetting if workSettings === 1
      if (formData[2].data[7].value[6].value === 1) {
        formData[2].data[8].value = otherWorkSetting;
      } else {
        formData[2].data[8].value = '';
      }
    }

    // * State Licensed Psychologist
    // clear sub-qs if obtainedLicense === 'No' or 'Not yet eligible for licensing'
    if (
      formData[3].data[0].value === 'No' ||
      formData[3].data[0].value === 'Not yet eligible for licensing'
    ) {
      formData[3].data[1].value = '';
      formData[3].data[2].value = '';
      formData[3].data[3].value = '';
      formData[3].data[4].value = '';
    } else {
      // set sub-qs from inputs
      formData[3].data[1].value = stateSelect;
      formData[3].data[2].value = dateSelect;
      formData[3].data[3].value = licenseNumber;
      formData[3].data[4].value = npiNumber;
    }

    // * Other Professional Activities
    // set organizationName if organizationMember === 'Yes'
    if (formData[4].data[0].value === 'Yes') {
      formData[4].data[1].value = organizationName;
    } else {
      formData[4].data[1].value = '';
    }
    // set qs from inputs
    formData[4].data[2].value = publicationSelect;
    formData[4].data[3].value = presentationsSelect;

    // * Program Feedback
    // set qs from inputs
    formData[5].data[0].value = doWell;
    formData[5].data[1].value = doneBetter;
    formData[5].data[2].value = suggestions;

    axios
      .post(url, { id, newStatus, formData }, config)
      .then((response) => {
        // update form status if results are valid
        const { status } = response.data;
        this.setState({ status });
        if (status === 'completed') {
          // eslint-disable-next-line react/prop-types, react/destructuring-assignment
          this.props.history.push('/profile');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  renderUploadedFiles(uploadType) {
    const { uploadedFiles, status, inputKey, selectedFile, uploadPercentage } = this.state;

    // get files for selected uploadType
    const filteredFiles = uploadedFiles.filter((val) => val.uploadType === uploadType);

    return (
      <div>
        <FormGroup className="submitBlock">
          <Row className="justify-content-center">
            <Col md={6} className="text-center">
              <Label>
                <i className="fa fa-chevron-right" /> Uploaded files
              </Label>
              {filteredFiles.map((file) => (
                // eslint-disable-next-line no-underscore-dangle
                <InputGroup key={file._id} className="mt-2">
                  <Input type="text" id="uploadFile-input" placeholder={file.filename} disabled />
                </InputGroup>
              ))}
            </Col>
          </Row>
          {status !== 'completed' && (
            <div>
              <Row className="mt-4">
                <Col>
                  <Input
                    type="file"
                    name="uploadFile"
                    id="uploadFile"
                    key={inputKey}
                    onChange={this.handleFileChange}
                  />
                </Col>
              </Row>
              <Row className="mt-4 justify-content-center">
                <Col md={4} xs={8}>
                  <Button
                    className="btn-success btn-block"
                    onClick={(e) => this.onUploadFileBtnClick(e, uploadType)}
                    disabled={selectedFile === null}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
              <Row className="mt-2 justify-content-center">
                <Col md={4} xs={8}>
                  {uploadPercentage !== 0 && (
                    <Progress max="100" color="success" value={uploadPercentage}>
                      {Math.round(uploadPercentage, 2)}%
                    </Progress>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </FormGroup>
      </div>
    );
  }

  renderPostdoctoralTrainingProgramQuestions() {
    const { formData, postdoctoralProgramName, postdoctoralProgramAddress, otherEmphasis } =
      this.state;
    return (
      <div>
        {/* Formal Postdoctoral Training Program - Q1 - Subquestions  */}
        <FormGroup className="submitBlock mt-4">
          <Row>
            <Col sm={12} className="text-center">
              <p>Wonderful, we need some details.</p>
            </Col>
          </Row>
          {/* Formal Postdoctoral Training Program - Post Doctoral Program Name */}
          <Row className="mt-4">
            <Col sm={4} className="text-md-right">
              <Label for="postdoctoralProgramName">Program Name</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="postdoctoralProgramName"
                name="postdoctoralProgramName"
                value={postdoctoralProgramName}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Formal Postdoctoral Training Program - Post Doctoral Program Name */}
          {/* Formal Postdoctoral Training Program - Post Doctoral Program Address */}
          <Row className="mt-4">
            <Col sm={4} className="text-md-right">
              <Label for="postdoctoralProgramName">Program Address</Label>
            </Col>
            <Col sm={6}>
              <Input
                type="text"
                id="postdoctoralProgramAddress"
                name="postdoctoralProgramAddress"
                value={postdoctoralProgramAddress}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Formal Postdoctoral Training Program - Post Doctoral Program Address */}
          {/* // Formal Postdoctoral Training Program - Accredited */}
          <Row className="mt-4">
            <Col sm={12} className="text-center">
              <Label>
                <i className="fa fa-chevron-right" /> Is it accredited?
              </Label>
              <br />
              <Button
                className="btn-form"
                id="q1-s1-q1-b-yes"
                color={formData[1].data[3].value === 'Yes' ? 'dark' : 'inverse'}
                onClick={() => this.onFrmBtnClick(1, 3, 'Yes')}
                active={formData[1].data[3].value === 'Yes'}
              >
                Yes
              </Button>
              <Button
                className="btn-form"
                id="q1-s1-q1-b-no"
                color={formData[1].data[3].value === 'No' ? 'dark' : 'inverse'}
                onClick={() => this.onFrmBtnClick(1, 3, 'No')}
                active={formData[1].data[3].value === 'No'}
              >
                No
              </Button>
            </Col>
          </Row>
          {/* // Formal Postdoctoral Training Program - Accredited */}
          {/* Formal Postdoctoral Training Program - Emphasis */}
          <Row className="mt-4">
            <Col sm={12} className="text-center">
              <Label>
                <i className="fa fa-chevron-right" /> Emphasis
              </Label>
              <br />
              <Button
                className="btn-form"
                id="q1-s2-q1-b-primarilyClinical"
                color={formData[1].data[4].value === 'Primarily Clinical' ? 'dark' : 'inverse'}
                onClick={() => this.onFrmBtnClick(1, 4, 'Primarily Clinical')}
                active={formData[1].data[4].value === 'Primarily Clinical'}
              >
                Primarily Clinical
              </Button>
              <Button
                className="btn-form"
                id="q1-s2-q1-b-primarilyResearch"
                color={formData[1].data[4].value === 'Primarily Research' ? 'dark' : 'inverse'}
                onClick={() => this.onFrmBtnClick(1, 4, 'Primarily Research')}
                active={formData[1].data[4].value === 'Primarily Research'}
              >
                Primarily Research
              </Button>
              <Button
                className="btn-form"
                id="q1-s2-q1-b-equallyClinicalResearch"
                color={
                  formData[1].data[4].value === 'Equally Clinical & Research' ? 'dark' : 'inverse'
                }
                onClick={() => this.onFrmBtnClick(1, 4, 'Equally Clinical & Research')}
                active={formData[1].data[4].value === 'Equally Clinical & Research'}
              >
                Equally Clinical & Research
              </Button>
              <Button
                className="btn-form"
                id="q1-s2-q1-b-other"
                color={formData[1].data[4].value === 'Other' ? 'dark' : 'inverse'}
                onClick={() => this.onFrmBtnClick(1, 4, 'Other')}
                active={formData[1].data[4].value === 'Other'}
              >
                Other
              </Button>
            </Col>
          </Row>
          {(() => {
            if (formData[1].data[4].value === 'Other') {
              return (
                <div>
                  <Row className="mt-4">
                    <Col sm={12} className="text-center">
                      <Label>
                        <i className="fa fa-chevron-right" /> Please specify the other emphasis
                      </Label>
                      <br />
                      <Input
                        type="text"
                        id="otherEmphasis"
                        name="otherEmphasis"
                        value={otherEmphasis}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                </div>
              );
            }
          })()}
          {/* // Formal Postdoctoral Training Program - Emphasis */}
          {/* Formal Postdoctoral Training Program - Activities */}
          <Row className="mt-4">
            <Col sm={12} className="text-center">
              <Label>
                <i className="fa fa-chevron-right" /> Select all of the activities that apply to the
                position
              </Label>
              <br />
              <Button
                className="btn-form"
                id="postdoctoralTrainingProgram-b-administration"
                color={formData[1].data[6].value[0].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 1, 6, 0)}
                active={formData[1].data[6].value[0].value === 1}
              >
                Administration
              </Button>
              <Button
                className="btn-form"
                id="postdoctoralTrainingProgram-b-assessment"
                color={formData[1].data[6].value[1].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 1, 6, 1)}
                active={formData[1].data[6].value[1].value === 1}
              >
                Assessment
              </Button>
              <Button
                className="btn-form"
                id="postdoctoralTrainingProgram-b-consultation"
                color={formData[1].data[6].value[2].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 1, 6, 2)}
                active={formData[1].data[6].value[2].value === 1}
              >
                Consultation
              </Button>
              <Button
                className="btn-form"
                id="postdoctoralTrainingProgram-b-psychotherapy"
                color={formData[1].data[6].value[3].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 1, 6, 3)}
                active={formData[1].data[6].value[3].value === 1}
              >
                Psychotherapy
              </Button>
              <Button
                className="btn-form"
                id="postdoctoralTrainingProgram-b-research"
                color={formData[1].data[6].value[4].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 1, 6, 4)}
                active={formData[1].data[6].value[4].value === 1}
              >
                Research
              </Button>
              <Button
                className="btn-form"
                id="postdoctoralTrainingProgram-b-supervision"
                color={formData[1].data[6].value[5].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 1, 6, 5)}
                active={formData[1].data[6].value[5].value === 1}
              >
                Supervision
              </Button>
              <Button
                className="btn-form"
                id="postdoctoralTrainingProgram-b-teaching"
                color={formData[1].data[6].value[6].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 1, 6, 6)}
                active={formData[1].data[6].value[6].value === 1}
              >
                Teaching
              </Button>
              <Button
                className="btn-form"
                id="postdoctoralTrainingProgram-b-unknown"
                color={formData[1].data[6].value[7].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 1, 6, 7)}
                active={formData[1].data[6].value[7].value === 1}
              >
                Unknown
              </Button>
              <Button
                className="btn-form"
                id="postdoctoralTrainingProgram-b-other"
                color={formData[1].data[6].value[8].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 1, 6, 8)}
                active={formData[1].data[6].value[8].value === 1}
              >
                Other
              </Button>
            </Col>
          </Row>
          {/* // Formal Postdoctoral Training Program - Activities */}
        </FormGroup>
      </div>
    );
  }

  renderProfessionalEmploymentQuestions() {
    const {
      formData,
      employerName,
      employerAddress,
      jobTitle,
      otherPositionSetting,
      otherWorkSetting,
    } = this.state;

    return (
      <div>
        {/* Professional Employment - Q2 - Subquestions */}
        <FormGroup className="submitBlock mt-4">
          <Row>
            <Col sm={12} className="text-center">
              <p>Great news, we need some details.</p>
            </Col>
          </Row>
          {/* Professional Employment - Employer Name */}
          <Row className="mt-4">
            <Col md={3} className="text-md-right">
              <Label>
                <i className="fa fa-chevron-right" /> Employer Name
              </Label>
            </Col>
            <Col md={8}>
              <Input
                type="text"
                id="employerName"
                name="employerName"
                value={employerName}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Professional Employment - Employer Name */}
          {/* Professional Employment - Employer Address */}
          <Row className="mt-4">
            <Col md={3} className="text-md-right">
              <Label>
                <i className="fa fa-chevron-right" /> Employer Address
              </Label>
            </Col>
            <Col md={8}>
              <Input
                type="text"
                id="employerAddress"
                name="employerAddress"
                value={employerAddress}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // Professional Employment - Employer Address */}
          {/* Professional Employment - Job Title */}
          <Row className="mt-4">
            <Col md={3} className="text-md-right">
              <Label>
                <i className="fa fa-chevron-right" /> Job Title
              </Label>
            </Col>
            <Col md={8}>
              <Input
                type="text"
                id="jobTitle"
                name="jobTitle"
                value={jobTitle}
                onChange={this.handleInputChange}
                placeholder="ex. Research Scientist"
              />
            </Col>
          </Row>
          {/* // Professional Employment - Job Title */}
          {/* Professional Employment - Setting Types */}
          <Row className="mt-4">
            <Col sm={12} className="text-center">
              <Label>
                <i className="fa fa-chevron-right" /> Select{' '}
                <span className="text-danger">ALL</span> setting types that apply to this position
              </Label>
              <br />
              <Button
                className="btn-form"
                id="professionalEmployment-b-AcademicTeaching"
                color={formData[2].data[4].value[0].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 0)}
                active={formData[2].data[4].value[0].value === 1}
              >
                Academic Teaching
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-AcademicMedicalCenter"
                color={formData[2].data[4].value[1].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 1)}
                active={formData[2].data[4].value[1].value === 1}
              >
                Academic Medical Center
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-AreaHealthEducationCenter"
                color={formData[2].data[4].value[2].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 2)}
                active={formData[2].data[4].value[2].value === 1}
              >
                Area Health Education Center
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-CCBHC"
                color={formData[2].data[4].value[3].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 3)}
                active={formData[2].data[4].value[3].value === 1}
              >
                Certified Community Behavioral Health Center (CCBHC)
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-CommunityMentalHealthCenter"
                color={formData[2].data[4].value[4].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 4)}
                active={formData[2].data[4].value[4].value === 1}
              >
                Community Mental Health Center
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-CHC"
                color={formData[2].data[4].value[5].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 5)}
                active={formData[2].data[4].value[5].value === 1}
              >
                Community Health Center (CHC)
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-Consortium"
                color={formData[2].data[4].value[6].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 6)}
                active={formData[2].data[4].value[6].value === 1}
              >
                Consortium
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-CorrectionalFacility"
                color={formData[2].data[4].value[7].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 7)}
                active={formData[2].data[4].value[7].value === 1}
              >
                Correctional Facility
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-CriticalAccessHospital"
                color={formData[2].data[4].value[8].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 8)}
                active={formData[2].data[4].value[8].value === 1}
              >
                Critical Access Hospital
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-FederalGovernment"
                color={formData[2].data[4].value[9].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 9)}
                active={formData[2].data[4].value[9].value === 1}
              >
                Federal Government
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-FQHC"
                color={formData[2].data[4].value[10].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 10)}
                active={formData[2].data[4].value[10].value === 1}
              >
                FQHC or Look-Alike
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-HealthDepartment"
                color={formData[2].data[4].value[11].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 11)}
                active={formData[2].data[4].value[11].value === 1}
              >
                Health Department (local/state/tribal)
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-HealthMaintenanceOrganization"
                color={formData[2].data[4].value[12].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 12)}
                active={formData[2].data[4].value[12].value === 1}
              >
                Health Maintenance Organization
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-Hospital"
                color={formData[2].data[4].value[13].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 13)}
                active={formData[2].data[4].value[13].value === 1}
              >
                Hospital (non-academic)
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-IndianHealthService"
                color={formData[2].data[4].value[14].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 14)}
                active={formData[2].data[4].value[14].value === 1}
              >
                Indian Health Service (HIS)/Tribal/Urban Indian Health Center
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-IndependentPractice"
                color={formData[2].data[4].value[15].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 15)}
                active={formData[2].data[4].value[15].value === 1}
              >
                Independent Practice
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-NursingHome"
                color={formData[2].data[4].value[16].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 16)}
                active={formData[2].data[4].value[16].value === 1}
              >
                Nursing Home
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-OtherClinicalHealthSetting"
                color={formData[2].data[4].value[17].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 17)}
                active={formData[2].data[4].value[17].value === 1}
              >
                Other Clinical Health Setting
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-OtherCommunityBasedOrganization"
                color={formData[2].data[4].value[18].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 18)}
                active={formData[2].data[4].value[18].value === 1}
              >
                Other Community-Based Organization
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-OtherLongtermCareFacility"
                color={formData[2].data[4].value[19].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 19)}
                active={formData[2].data[4].value[19].value === 1}
              >
                Other Long-term Care Facility
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-OtherSpecialtyClinic"
                color={formData[2].data[4].value[20].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 20)}
                active={formData[2].data[4].value[20].value === 1}
              >
                Other Specialty Clinic
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-PrivateIndustry"
                color={formData[2].data[4].value[21].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 21)}
                active={formData[2].data[4].value[21].value === 1}
              >
                Private Industry
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-PsychiatricFacility"
                color={formData[2].data[4].value[22].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 22)}
                active={formData[2].data[4].value[22].value === 1}
              >
                Psychiatric Facility
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-ResidentialLivingFacility"
                color={formData[2].data[4].value[23].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 23)}
                active={formData[2].data[4].value[23].value === 1}
              >
                Residential Living Facility (including independent and assisted living)
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-RuralHealthClinic"
                color={formData[2].data[4].value[24].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 24)}
                active={formData[2].data[4].value[24].value === 1}
              >
                Rural Health Clinic
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-SchoolDistrict"
                color={formData[2].data[4].value[25].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 25)}
                active={formData[2].data[4].value[25].value === 1}
              >
                School District or System
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-StateOrLocalGovernment"
                color={formData[2].data[4].value[26].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 26)}
                active={formData[2].data[4].value[26].value === 1}
              >
                State or Local Government
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-ArmedForces"
                color={formData[2].data[4].value[27].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 27)}
                active={formData[2].data[4].value[27].value === 1}
              >
                US Armed Forces
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-VeteransAffairsHealthcare"
                color={formData[2].data[4].value[28].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 28)}
                active={formData[2].data[4].value[28].value === 1}
              >
                Veterans Affairs Healthcare (e.g., VA hospital or clinic)
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-PursuingAdditionalEducation"
                color={formData[2].data[4].value[29].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 29)}
                active={formData[2].data[4].value[29].value === 1}
              >
                Pursuing Additional Education or Training
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-UniversityCounselingCenter"
                color={formData[2].data[4].value[30].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 30)}
                active={formData[2].data[4].value[30].value === 1}
              >
                University Counseling Center
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-NotCurrentlyEmployed"
                color={formData[2].data[4].value[31].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 31)}
                active={formData[2].data[4].value[31].value === 1}
              >
                Not Currently Employed
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-Other"
                color={formData[2].data[4].value[32].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 4, 32)}
                active={formData[2].data[4].value[32].value === 1}
              >
                Other
              </Button>
            </Col>
          </Row>
          {(() => {
            if (formData[2].data[4].value[32].value === 1) {
              return (
                <div>
                  <Row className="mt-4">
                    <Col sm={12} className="text-center">
                      <Label>
                        <i className="fa fa-chevron-right" /> Please specify the other setting type
                      </Label>
                      <br />
                      <Input
                        type="text"
                        id="otherPositionSetting"
                        name="otherPositionSetting"
                        value={otherPositionSetting}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                </div>
              );
            }
          })()}
          {/* // Professional Employment - Setting Types */}
          {/* Professional Employment - Activities */}
          <Row className="mt-4">
            <Col md={12} className="text-center">
              <Label>
                <i className="fa fa-chevron-right" /> Select all of the activities that apply to the
                position
              </Label>
              <br />
              <Button
                className="btn-form"
                id="professionalEmployment-b-activityAdministration"
                color={formData[2].data[6].value[0].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 6, 0)}
                active={formData[2].data[6].value[0].value === 1}
              >
                Administration
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-activityAssessment"
                color={formData[2].data[6].value[1].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 6, 1)}
                active={formData[2].data[6].value[1].value === 1}
              >
                Assessment
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-activityConsultation"
                color={formData[2].data[6].value[2].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 6, 2)}
                active={formData[2].data[6].value[2].value === 1}
              >
                Consultation
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-activityPsychotherapy"
                color={formData[2].data[6].value[3].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 6, 3)}
                active={formData[2].data[6].value[3].value === 1}
              >
                Psychotherapy
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-activityResearch"
                color={formData[2].data[6].value[4].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 6, 4)}
                active={formData[2].data[6].value[4].value === 1}
              >
                Research
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-activitySupervision"
                color={formData[2].data[6].value[5].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 6, 5)}
                active={formData[2].data[6].value[5].value === 1}
              >
                Supervision
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-activityTeaching"
                color={formData[2].data[6].value[6].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 6, 6)}
                active={formData[2].data[6].value[6].value === 1}
              >
                Teaching
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-activityUnknown"
                color={formData[2].data[6].value[7].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 6, 7)}
                active={formData[2].data[6].value[7].value === 1}
              >
                Unknown
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-activityOther"
                color={formData[2].data[6].value[8].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 6, 8)}
                active={formData[2].data[6].value[8].value === 1}
              >
                Other
              </Button>
            </Col>
          </Row>
          {/* // Professional Employment - Activities */}
          {/* Professional Employment - Work Settings */}
          <Row className="mt-4">
            <Col className="text-center">
              <Label>
                <i className="fa fa-chevron-right" /> Do you work in any of the following settings?
                (Check all that apply)
              </Label>
              <br />
              <Button
                className="btn-form"
                id="professionalEmployment-b-HealthProfessionalShortageArea"
                color={formData[2].data[7].value[0].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 7, 0)}
                active={formData[2].data[7].value[0].value === 1}
              >
                Health Professional Shortage Area
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-MedicallyUnderservedCommunity"
                color={formData[2].data[7].value[1].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 7, 1)}
                active={formData[2].data[7].value[1].value === 1}
              >
                Medically Underserved Community
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-NHSC"
                color={formData[2].data[7].value[2].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 7, 2)}
                active={formData[2].data[7].value[1].value === 2}
              >
                National Health Service Corps (NHSC) - Approved Site
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-PrimaryCareSetting"
                color={formData[2].data[7].value[3].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 7, 3)}
                active={formData[2].data[7].value[3].value === 2}
              >
                Primary Care Setting
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-RuralArea"
                color={formData[2].data[7].value[4].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 7, 4)}
                active={formData[2].data[7].value[4].value === 2}
              >
                Rural Area
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-None"
                color={formData[2].data[7].value[5].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 7, 5)}
                active={formData[2].data[7].value[5].value === 2}
              >
                None of the Above
              </Button>
              <Button
                className="btn-form"
                id="professionalEmployment-b-workSettingOther"
                color={formData[2].data[7].value[6].value === 1 ? 'dark' : 'inverse'}
                onClick={(e) => this.onFrmBtnToggle(e, 2, 7, 6)}
                active={formData[2].data[7].value[6].value === 2}
              >
                Other
              </Button>
            </Col>
          </Row>
          {(() => {
            if (formData[2].data[7].value[6].value === 1) {
              return (
                <div>
                  <Row className="mt-4">
                    <Col sm={12} className="text-center">
                      <Label>
                        <i className="fa fa-chevron-right" /> Please specify the other setting
                      </Label>
                      <br />
                      <Input
                        type="text"
                        id="otherWorkSetting"
                        name="otherWorkSetting"
                        value={otherWorkSetting}
                        onChange={this.handleInputChange}
                      />
                    </Col>
                  </Row>
                </div>
              );
            }
          })()}
          {/* // Professional Employment - Work Settings */}
        </FormGroup>
      </div>
    );
  }

  renderStateLicensedPsychologistQuestions() {
    const { stateSelect, dateSelect, licenseNumber, npiNumber } = this.state;
    return (
      <div>
        <FormGroup className="submitBlock mt-4">
          <Row>
            <Col sm={12} className="text-center">
              <p>Fantastic, we need some details.</p>
            </Col>
          </Row>
          {/* State Licensed Psychologist - State */}
          <Row>
            <Col md={4} className="text-md-right">
              <Label>
                <i className="fa fa-chevron-right" /> State
              </Label>
            </Col>
            <Col sm={4}>
              <Input
                type="select"
                name="stateSelect"
                id="stateSelect"
                value={stateSelect}
                onChange={this.handleInputChange}
              >
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AZ">Arizona</option>
                <option value="AR">Arkansas</option>
                <option value="CA">California</option>
                <option value="CO">Colorado</option>
                <option value="CT">Connecticut</option>
                <option value="DE">Delaware</option>
                <option value="DC">District Of Columbia</option>
                <option value="FL">Florida</option>
                <option value="GA">Georgia</option>
                <option value="HI">Hawaii</option>
                <option value="ID">Idaho</option>
                <option value="IL">Illinois</option>
                <option value="IN">Indiana</option>
                <option value="IA">Iowa</option>
                <option value="KS">Kansas</option>
                <option value="KY">Kentucky</option>
                <option value="LA">Louisiana</option>
                <option value="ME">Maine</option>
                <option value="MD">Maryland</option>
                <option value="MA">Massachusetts</option>
                <option value="MI">Michigan</option>
                <option value="MN">Minnesota</option>
                <option value="MS">Mississippi</option>
                <option value="MO">Missouri</option>
                <option value="MT">Montana</option>
                <option value="NE">Nebraska</option>
                <option value="NV">Nevada</option>
                <option value="NH">New Hampshire</option>
                <option value="NJ">New Jersey</option>
                <option value="NM">New Mexico</option>
                <option value="NY">New York</option>
                <option value="NC">North Carolina</option>
                <option value="ND">North Dakota</option>
                <option value="OH">Ohio</option>
                <option value="OK">Oklahoma</option>
                <option value="OR">Oregon</option>
                <option value="PA">Pennsylvania</option>
                <option value="RI">Rhode Island</option>
                <option value="SC">South Carolina</option>
                <option value="SD">South Dakota</option>
                <option value="TN">Tennessee</option>
                <option value="TX">Texas</option>
                <option value="UT">Utah</option>
                <option value="VT">Vermont</option>
                <option value="VA">Virginia</option>
                <option value="WA">Washington</option>
                <option value="WV">West Virginia</option>
                <option value="WI">Wisconsin</option>
                <option value="WY">Wyoming</option>
                <option value="Other">Other</option>
              </Input>
            </Col>
          </Row>
          {/* // State Licensed Psychologist - State */}
          {/* State Licensed Psychologist - License Date */}
          <Row className="mt-4">
            <Col md={4} className="text-md-right">
              <Label>
                <i className="fa fa-chevron-right" /> License Date
              </Label>
            </Col>
            <Col md={4}>
              <Input
                type="date"
                name="dateSelect"
                id="dateSelect"
                value={dateSelect}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // State Licensed Psychologist - License Date */}
          {/* State Licensed Psychologist - License Number */}
          <Row className="mt-4">
            <Col sm={4} className="text-md-right">
              <Label for="emailAddress">
                <i className="fa fa-chevron-right" /> License Number
              </Label>
            </Col>
            <Col sm={4}>
              <Input
                type="text"
                id="licenseNumber"
                name="licenseNumber"
                value={licenseNumber}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // State Licensed Psychologist - License Number */}
          {/* State Licensed Psychologist - NPI Number */}
          <Row className="mt-4">
            <Col sm={4} className="text-md-right">
              <Label for="emailAddress">
                <i className="fa fa-chevron-right" /> NPI Number
              </Label>
            </Col>
            <Col sm={4}>
              <Input
                type="text"
                id="npiNumber"
                name="npiNumber"
                value={npiNumber}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {/* // State Licensed Psychologist - NPI Number */}
        </FormGroup>
      </div>
    );
  }

  renderDemographics() {
    const { formData, yearGraduatedSelect, ethnicitySelect, raceSelect, emailAddress } = this.state;

    const yearArr = [];
    const currentYear = currentDate.getFullYear();
    yearArr.push(currentYear);

    for (let i = 1; i < 10; i += 1) {
      yearArr.push(currentYear - i);
    }

    return (
      <div>
        <Row>
          {/* Demographics */}
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Demographics
                </h5>
              }
            >
              {/* Demographics - Permanent Email */}
              <Row className="mt-4">
                <Col sm={4} className="text-md-right">
                  <Label for="emailAddress">Permanent e-mail address</Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="text"
                    id="emailAddress"
                    name="emailAddress"
                    value={emailAddress}
                    onChange={this.handleInputChange}
                  />
                </Col>
              </Row>
              {/* // Demographics - Permanent Email */}
              {/* Demographics - Year Graduated */}
              <Row className="mt-4">
                <Col sm={4} className="text-md-right">
                  <Label for="yearGraduatedSelect">Year Graduated from LLU</Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="select"
                    name="yearGraduatedSelect"
                    id="yearGraduatedSelect"
                    value={yearGraduatedSelect}
                    onChange={this.handleInputChange}
                  >
                    {yearArr.map((year) => (
                      <option key={year} value={year}>{`${year}`}</option>
                    ))}
                  </Input>
                </Col>
              </Row>
              {/* // Demographics - Year Graduated */}
              {/* Demographics - Ethnicity */}
              <Row className="mt-4">
                <Col sm={4} className="text-md-right">
                  <Label for="ethnicitySelect">Ethnicity</Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="select"
                    name="ethnicitySelect"
                    id="ethnicitySelect"
                    value={ethnicitySelect}
                    onChange={this.handleInputChange}
                  >
                    <option>Hispanic/Latino</option>
                    <option>non-Hispanic/non-Latino</option>
                  </Input>
                </Col>
              </Row>
              {/* // Demographics - Ethnicity */}
              {/* Demographics - Race */}
              <Row className="mt-4">
                <Col sm={4} className="text-md-right">
                  <Label for="ethnicitySelect">Race</Label>
                </Col>
                <Col sm={6}>
                  <Input
                    type="select"
                    name="raceSelect"
                    id="raceSelect"
                    value={raceSelect}
                    onChange={this.handleInputChange}
                  >
                    <option>American Indian</option>
                    <option>Alaskan Native</option>
                    <option>Black or African American</option>
                    <option>Native Hawaiian</option>
                    <option>Pacific Islander</option>
                    <option>Korean, Japanese, or Chinese</option>
                    <option>Filipino</option>
                    <option>Middle Eastern, Persian, Arab</option>
                    <option>Southeast Asian (e.g., Hmong, Thai, Vietnamese)</option>
                    <option>South or Central Asian (e.g., Indian, Pakistani)</option>
                    <option>White</option>
                    <option>Other</option>
                  </Input>
                </Col>
              </Row>
              {/* // Demographics - Race */}
              {/* Demographics - 1st Gen College */}
              <Row className="mt-4">
                <Col className="text-center">
                  <Label>
                    Are you the first generation in your family to graduate from college?
                  </Label>
                  <br />
                  <Button
                    className="btn-form"
                    id="q3-b-yes"
                    color={formData[0].data[4].value === 'Yes' ? 'dark' : 'inverse'}
                    onClick={() => this.onFrmBtnClick(0, 4, 'Yes')}
                    active={formData[0].data[4].value === 'Yes'}
                  >
                    Yes
                  </Button>
                  <Button
                    className="btn-form"
                    id="q3-b-no"
                    color={formData[0].data[4].value === 'No' ? 'dark' : 'inverse'}
                    onClick={() => this.onFrmBtnClick(0, 4, 'No')}
                    active={formData[0].data[4].value === 'No'}
                  >
                    No
                  </Button>
                </Col>
              </Row>
              {/* // Demographics - 1st Gen College */}
              {/* Demographics - Veteran */}
              <Row className="mt-4">
                <Col className="text-center">
                  <Label>Are you a military veteran?</Label>
                  <br />
                  <Button
                    className="btn-form"
                    id="q3-b-yes"
                    color={formData[0].data[5].value === 'Yes' ? 'dark' : 'inverse'}
                    onClick={() => this.onFrmBtnClick(0, 5, 'Yes')}
                    active={formData[0].data[5].value === 'Yes'}
                  >
                    Yes
                  </Button>
                  <Button
                    className="btn-form"
                    id="q3-b-no"
                    color={formData[0].data[5].value === 'No' ? 'dark' : 'inverse'}
                    onClick={() => this.onFrmBtnClick(0, 5, 'No')}
                    active={formData[0].data[5].value === 'No'}
                  >
                    No
                  </Button>
                </Col>
              </Row>
              {/* // Demographics - Veteran */}
              <FormGroup row className="submitBlock mt-4">
                <Col md={12} className="text-center">
                  <Button
                    className="ml-xs"
                    id="s0-b-save"
                    color="info"
                    onClick={(e) => this.saveForm(e, 'inProgress')}
                  >
                    Save
                  </Button>
                  <Button
                    className="ml-xs"
                    id="s0-b-cont"
                    color="info"
                    onClick={(e) => this.onSectionBtnClick(e, 1)}
                  >
                    Continue
                  </Button>
                </Col>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderPostdoctoralTrainingProgram() {
    const { formData } = this.state;

    return (
      <div>
        <Row>
          {/* Formal Postdoctoral Training Program */}
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Formal Postdoctoral Training Program
                </h5>
              }
            >
              <FormGroup className="mt-4">
                <Form>
                  {/* Formal Postdoctoral Training Program - Q1 */}
                  <Row>
                    <Col sm={12} className="text-center">
                      <Label>
                        <b>Q1</b> Are you enrolled in a formal postdoctoral training program?
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="q1-b-yes"
                        color={formData[1].data[0].value === 'Yes' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 0, 'Yes')}
                        active={formData[1].data[0].value === 'Yes'}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn-form"
                        id="q1-b-no"
                        color={formData[1].data[0].value === 'No' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(1, 0, 'No')}
                        active={formData[1].data[0].value === 'No'}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                  {/* Formal Postdoctoral Training Program - Q1 - subQs */}
                  {(() => {
                    if (formData[1].data[0].value === 'Yes') {
                      return <div>{this.renderPostdoctoralTrainingProgramQuestions()}</div>;
                    }
                  })()}
                  {/* // Formal Postdoctoral Training Program - Q1 - subQs */}
                  {/* // Formal Postdoctoral Training Program - Q1 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col md={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s1-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderProfessionalEmployment() {
    const { formData } = this.state;

    return (
      <div>
        <Row>
          {/* Professional Employment */}
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Professional Employment
                </h5>
              }
            >
              <FormGroup className="mt-4">
                <Form>
                  {/* Professional Employment - Q2 */}
                  <Row>
                    <Col sm={12} className="text-center">
                      <Label>
                        <b>Q2</b> Are you employed professionally?
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="q2-b-yes"
                        color={formData[2].data[0].value === 'Yes' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(2, 0, 'Yes')}
                        active={formData[2].data[0].value === 'Yes'}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn-form"
                        id="q2-b-no"
                        color={formData[2].data[0].value === 'No' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(2, 0, 'No')}
                        active={formData[2].data[0].value === 'No'}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                  {/* Professional Employment - Q2 - subQs */}
                  {(() => {
                    if (formData[2].data[0].value === 'Yes') {
                      return <div>{this.renderProfessionalEmploymentQuestions()}</div>;
                    }
                  })()}
                  {/* // Professional Employment - Q2 - subQs */}
                  {/* // Professional Employment - Q2 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col md={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s1-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderStateLicensedPsychologist() {
    const { formData } = this.state;

    return (
      <div>
        <Row>
          {/* State Licensed Psychologist */}
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> State Licensed Psychologist
                </h5>
              }
            >
              <FormGroup className="mt-4">
                <Form>
                  {/* State Licensed Psychologist - Q3 */}
                  <Row>
                    <Col sm={12} className="text-center">
                      <Label>
                        <b>Q3</b> Did you obtain a license as a psychologist?
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="q3-b-yes"
                        color={formData[3].data[0].value === 'Yes' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(3, 0, 'Yes')}
                        active={formData[3].data[0].value === 'Yes'}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn-form"
                        id="q3-b-no"
                        color={formData[3].data[0].value === 'No' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(3, 0, 'No')}
                        active={formData[3].data[0].value === 'No'}
                      >
                        No
                      </Button>
                      <Button
                        className="btn-form"
                        id="q3-b-notEligible"
                        color={
                          formData[3].data[0].value === 'Not yet eligible for licensing'
                            ? 'dark'
                            : 'inverse'
                        }
                        onClick={() => this.onFrmBtnClick(3, 0, 'Not yet eligible for licensing')}
                        active={formData[3].data[0].value === 'Not yet eligible for licensing'}
                      >
                        Not yet eligible for licensing
                      </Button>
                    </Col>
                  </Row>
                  <p>
                    <br />
                  </p>
                  {/* State Licensed Psychologist - Q3 - subQs */}
                  {(() => {
                    if (formData[3].data[0].value === 'Yes') {
                      return <div>{this.renderStateLicensedPsychologistQuestions()}</div>;
                    }
                  })()}
                  {/* // State Licensed Psychologist - Q3 - subQs */}
                  {/* // State Licensed Psychologist - Q3 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col md={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s1-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderOtherProfessionalActivities() {
    const { formData, organizationName, publicationSelect, presentationsSelect } = this.state;

    return (
      <div>
        <Row>
          {/* Other Professional Activities */}
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Other Professional Activities
                </h5>
              }
            >
              <FormGroup className="mt-4">
                <Form>
                  {/* Other Professional Activities - Q4 */}
                  <Row>
                    <Col sm={12} className="text-center">
                      <Label>
                        <b>Q4</b> Are you a member of a professional/scientific organization?
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="q4-b-yes"
                        color={formData[4].data[0].value === 'Yes' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(4, 0, 'Yes')}
                        active={formData[4].data[0].value === 'Yes'}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn-form"
                        id="q4-b-no"
                        color={formData[4].data[0].value === 'No' ? 'dark' : 'inverse'}
                        onClick={() => this.onFrmBtnClick(4, 0, 'No')}
                        active={formData[4].data[0].value === 'No'}
                      >
                        No
                      </Button>
                    </Col>
                  </Row>
                  {/* Other Professional Activities - Q4 - subQs */}
                  {(() => {
                    if (formData[4].data[0].value === 'Yes') {
                      return (
                        <div>
                          <Row className="mt-4">
                            <Col md={3} className="text-md-right">
                              <Label>
                                <i className="fa fa-chevron-right" /> Which one?
                              </Label>
                            </Col>
                            <Col md={8}>
                              <Input
                                type="text"
                                id="organizationName"
                                name="organizationName"
                                value={organizationName}
                                onChange={this.handleInputChange}
                              />
                            </Col>
                          </Row>
                        </div>
                      );
                    }
                  })()}
                  {/* // Other Professional Activities - Q4 - subQs */}
                  {/* // Other Professional Activities - Q4 */}
                  {/* Other Professional Activities - Q5 */}
                  <Row className="mt-4">
                    <Col sm={12} className="text-center">
                      <Label>
                        <b>Q5</b> Number of publications since the year you graduated
                      </Label>
                      <br />
                      <Row className="justify-content-center">
                        <Col xs={2}>
                          <Input
                            type="select"
                            name="publicationSelect"
                            id="publicationSelect"
                            value={publicationSelect}
                            onChange={this.handleInputChange}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10+">10+</option>
                          </Input>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* // Other Professional Activities - Q5 */}
                  {/* Other Professional Activities - Q6 */}
                  <Row className="mt-4">
                    <Col sm={12} className="text-center">
                      <Label>
                        <b>Q6</b> Number of conference presentations since the year you graduated
                      </Label>
                      <br />
                      <Row className="justify-content-center">
                        <Col xs={2}>
                          <Input
                            type="select"
                            name="presentationsSelect"
                            id="presentationsSelect"
                            value={presentationsSelect}
                            onChange={this.handleInputChange}
                          >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10+">10+</option>
                          </Input>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {/* // Other Professional Activities - Q5 */}
                  {/* Other Professional Activities - Q7 */}
                  <Row className="mt-4">
                    <Col sm={12} className="text-center">
                      <Label>
                        <b>
                          <u>Please upload your most up to date CV</u>
                        </b>
                      </Label>
                      {this.renderUploadedFiles('cv')}
                    </Col>
                  </Row>
                  {/* // Other Professional Activities - Q7 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col md={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s1-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderProgramFeedback() {
    const { doWell, doneBetter, suggestions } = this.state;
    return (
      <div>
        {/* Program Feedback */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Program Feedback
                </h5>
              }
            >
              <FormGroup className="mt-4">
                <Form>
                  {/* Program Feedback - Q7 */}
                  <FormGroup>
                    <Row>
                      <Col sm={12} className="text-center">
                        <Label>
                          <b>Q7</b> What did LLU do particularly well in preparing you for your
                          current employment?
                        </Label>
                        <br />
                        <Input
                          id="doWell"
                          rows="4"
                          type="textarea"
                          name="doWell"
                          value={doWell}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* // Program Feedback - Q7 */}
                  {/* Program Feedback - Q8 */}
                  <FormGroup className="mt-4">
                    <Row>
                      <Col sm={12} className="text-center">
                        <Label>
                          <b>Q8</b> What do you think LLU could have done better to prepare you for
                          your current employment?
                        </Label>
                        <br />
                        <Input
                          id="doneBetter"
                          rows="4"
                          type="textarea"
                          name="doneBetter"
                          value={doneBetter}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* // Program Feedback - Q8 */}
                  {/* Program Feedback - Q9 */}
                  <FormGroup className="mt-4">
                    <Row>
                      <Col sm={12} className="text-center">
                        <Label>
                          <b>Q9</b> Do you have any suggestion for us that would help us better
                          prepare our graduates for future employment?
                        </Label>
                        <br />
                        <Input
                          id="suggestions"
                          rows="4"
                          type="textarea"
                          name="suggestions"
                          value={suggestions}
                          onChange={this.handleInputChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* // Program Feedback - Q9 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col sm={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s1-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
        {/* // Feedback */}
      </div>
    );
  }

  renderResearchAndEthicalStandards() {
    const { formData } = this.state;

    return (
      <div>
        <Row>
          {/* Research and Ethical Standards */}
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Research and Ethical Standards
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Research and Ethical Standards - Q8 */}
                  <SurveyButtonGroup
                    qNumber="Q10"
                    qText="Teaching you how to independently formulate research or other
                        scholarly activities (e.g. critical literature reviews, dissertation,
                        efficacy studies, clinical case studies, theoretical papers, program
                        evaluation projects, program development projects) that are of sufficient
                        quality and rigor to have the potential to contribute to the scientific,
                        psychological or professional knowledge base."
                    value={formData[6].data[0].value}
                    formDataID={6}
                    dataID={0}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Research and Ethical Standards - Q8 */}
                  {/* Research and Ethical Standards - Q9 */}
                  <SurveyButtonGroup
                    qNumber="Q11"
                    qText="Teaching you how to critically evaluate research."
                    value={formData[6].data[1].value}
                    formDataID={6}
                    dataID={1}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Research and Ethical Standards - Q9 */}
                  {/* Research and Ethical Standards - Q10 */}
                  <SurveyButtonGroup
                    qNumber="Q12"
                    qText="Teaching you how to disseminate research or other scholarly
                        activities via professional presentations or publications. These can be at the local, regional or national level."
                    value={formData[6].data[2].value}
                    formDataID={6}
                    dataID={2}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Research and Ethical Standards - Q10 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col sm={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s2-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s2-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s2-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderLegalAndEthicalStandards() {
    const { formData } = this.state;

    return (
      <div>
        <Row>
          {/* Legal and Ethical Standards */}
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Legal and Ethical Standards
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Legal and Ethical Standards - Q13 */}
                  <SurveyButtonGroup
                    qNumber="Q13"
                    qText="Teaching you how to be knowledgeable of an act in accordance with
                        the relevant laws, regulations, rules and policies governing health service
                        psychology at the organizational, local, state, regional and federal levels;
                        and relevant professional standards and guidelines."
                    value={formData[7].data[0].value}
                    formDataID={7}
                    dataID={0}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Legal and Ethical Standards - Q13 */}
                  {/* Legal and Ethical Standards - Q14 */}
                  <SurveyButtonGroup
                    qNumber="Q14"
                    qText="Teaching you how to recognize ethical dilemmas as they arise and
                        apply ethical decision-making processes in order to resolve the dilemmas."
                    value={formData[7].data[1].value}
                    formDataID={7}
                    dataID={1}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Legal and Ethical Standards - Q14 */}
                  {/* Legal and Ethical Standards - Q15 */}
                  <SurveyButtonGroup
                    qNumber="Q15"
                    qText="Teaching you to conduct yourself in an ethical manner in all
                        professional activities."
                    value={formData[7].data[2].value}
                    formDataID={7}
                    dataID={2}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Legal and Ethical Standards - Q15 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col sm={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s3-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s3-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s3-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderIndividualAndCulturalDiversity() {
    const { formData } = this.state;

    return (
      <div>
        <Row>
          {/* Individual and Cultural Diversity */}
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Individual and Cultural Diversity
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Individual and Cultural Diversity - Q16 */}
                  <SurveyButtonGroup
                    qNumber="Q16"
                    qText="Teaching you how to understand how your own personal/cultural
                        history, attitudes and biases may affect how you understand and interact
                        with people different than yourself."
                    value={formData[8].data[0].value}
                    formDataID={8}
                    dataID={0}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Individual and Cultural Diversity - Q16 */}
                  {/* Individual and Cultural Diversity - Q17 */}
                  <SurveyButtonGroup
                    qNumber="Q17"
                    qText="Teaching you how the current theoretical and empirical knowledge
                        base as it relates to addressing diversity in all professional activities,
                        including research, training, supervision, consultation and service."
                    value={formData[8].data[1].value}
                    formDataID={8}
                    dataID={1}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Individual and Cultural Diversity - Q17 */}
                  {/* Individual and Cultural Diversity - Q18 */}
                  <SurveyButtonGroup
                    qNumber="Q18"
                    qText="Teaching you how to integrate awareness and knowledge of
                        individual and cultural differences in the conduct of your professional roles
                        (e.g. research, services and other professional activities)."
                    value={formData[8].data[2].value}
                    formDataID={8}
                    dataID={2}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Individual and Cultural Diversity - Q18 */}
                  {/* Individual and Cultural Diversity - Q19 */}
                  <SurveyButtonGroup
                    qNumber="Q19"
                    qText="Teaching you the requisite knowledge base and ability to
                        articulate an approach to working effectively with diverse individuals and
                        groups, and then apply this approach effectively in your professional work."
                    value={formData[8].data[3].value}
                    formDataID={8}
                    dataID={3}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Individual and Cultural Diversity - Q17 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col sm={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s4-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s4-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s4-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderProfessionalValuesAndAttitudes() {
    const { formData } = this.state;

    return (
      <div>
        <Row>
          {/* Professional Values and Attitudes */}
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Professional Values and Attitudes
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/*  Professional Values and Attitudes - Q20 */}
                  <SurveyButtonGroup
                    qNumber="Q20"
                    qText="Teaching you to behave in ways that reflect the values and
                        attitudes of psychology, including integrity, deportment, professional
                        identity, accountability, lifelong learning and concern for the welfare of
                        others."
                    value={formData[9].data[0].value}
                    formDataID={9}
                    dataID={0}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/*  // Professional Values and Attitudes - Q20 */}
                  {/*  Professional Values and Attitudes - Q21 */}
                  <SurveyButtonGroup
                    qNumber="Q21"
                    qText="Teaching you to engage in self-reflection regarding your personal
                        and professional functioning."
                    value={formData[9].data[1].value}
                    formDataID={9}
                    dataID={1}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/*  // Professional Values and Attitudes - Q21 */}
                  {/*  Professional Values and Attitudes - Q22 */}
                  <SurveyButtonGroup
                    qNumber="Q22"
                    qText="Teaching you to engage in activities to maintain and improve
                        performance, well-being and professional effectiveness."
                    value={formData[9].data[2].value}
                    formDataID={9}
                    dataID={2}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/*  // Professional Values and Attitudes - Q22 */}
                  {/*  Professional Values and Attitudes - Q23 */}
                  <SurveyButtonGroup
                    qNumber="Q23"
                    qText="Teaching you to actively seek and demonstrate openness and
                        responsiveness to feedback and supervision."
                    value={formData[9].data[3].value}
                    formDataID={9}
                    dataID={3}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/*  Professional Values and Attitudes - Q23 */}
                  {/*  Professional Values and Attitudes - Q24 */}
                  <SurveyButtonGroup
                    qNumber="Q24"
                    qText="Teaching you to respond professionally to increasingly complex
                        situations with a greater degree of independence as you progressed across
                        levels of training."
                    value={formData[9].data[4].value}
                    formDataID={9}
                    dataID={4}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/*  // Professional Values and Attitudes - Q24 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col sm={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s5-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s5-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s5-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderCommunicationAndInterpersonalSkills() {
    const { formData } = this.state;

    return (
      <div>
        {/* Communication and Interpersonal Skills */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Communication and Interpersonal Skills
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Communication and Interpersonal Skills - Q25 */}
                  <SurveyButtonGroup
                    qNumber="Q25"
                    qText="Teaching you how to develop and maintain effective relationships
                        with a wide range of individuals, including colleagues, communities,
                        organizations, supervisors, supervisees and those receiving professional
                        services."
                    value={formData[10].data[0].value}
                    formDataID={10}
                    dataID={0}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Communication and Interpersonal Skills - Q25 */}
                  {/*  Communication and Interpersonal Skills - Q26 */}
                  <SurveyButtonGroup
                    qNumber="Q26"
                    qText="Teaching you to produce and comprehend oral, nonverbal and
                        written communications that are informative and well-integrated."
                    value={formData[10].data[1].value}
                    formDataID={10}
                    dataID={1}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/*  // Communication and Interpersonal Skills - Q26 */}
                  {/* Communication and Interpersonal Skills - Q27 */}
                  <SurveyButtonGroup
                    qNumber="Q27"
                    qText="Teaching you to demonstrate a thorough grasp of professional
                        language and concepts."
                    value={formData[10].data[2].value}
                    formDataID={10}
                    dataID={2}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Communication and Interpersonal Skills - Q27 */}
                  {/* Communication and Interpersonal Skills - Q28 */}
                  <SurveyButtonGroup
                    qNumber="Q28"
                    qText="Teaching you to demonstrate effective interpersonal skills and
                        the ability to manage difficult communication well."
                    value={formData[10].data[3].value}
                    formDataID={10}
                    dataID={3}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Communication and Interpersonal Skills - Q26 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col sm={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s6-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s6-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s6-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderAssessment() {
    const { formData } = this.state;

    return (
      <div>
        {/* Assessment */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Assessment
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Assessment - Q29 */}
                  <SurveyButtonGroup
                    qNumber="Q29"
                    qText="Teaching you to select and apply assessment methods that draw
                        from the best available empirical literature and that reflect the science of
                        measurement and psychometrics."
                    value={formData[11].data[0].value}
                    formDataID={11}
                    dataID={0}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Assessment - Q29 */}
                  {/*  Assessment - Q30 */}
                  <SurveyButtonGroup
                    qNumber="Q30"
                    qText="Teaching you to collect relevant assessment data using multiple
                        sources and methods appropriate to the identified goals and questions of the
                        assessment, as well as relevant diversity characteristics of the service
                        recipient."
                    value={formData[11].data[1].value}
                    formDataID={11}
                    dataID={1}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/*  // Assessment - Q30 */}
                  {/* Assessment - Q31 */}
                  <SurveyButtonGroup
                    qNumber="Q31"
                    qText="Teaching you to interpret assessment results, following current
                        research and professional standards and guidelines, to inform case
                        conceptualization, classification and recommendations, while guarding
                        against decision-making biases, distinguishing the aspects of assessment
                        that are subjective from those that are objective."
                    value={formData[11].data[2].value}
                    formDataID={11}
                    dataID={2}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Assessment - Q31 */}
                  {/* Assessment - Q32 */}
                  <SurveyButtonGroup
                    qNumber="Q32"
                    qText="Teaching you to communicate orally and in written documents the
                        findings and implications of the assessment in an accurate and effective
                        manner sensitive to a range of audiences."
                    value={formData[11].data[3].value}
                    formDataID={11}
                    dataID={3}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Assessment - Q32 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col sm={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s7-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s7-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s7-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderIntervention() {
    const { formData } = this.state;

    return (
      <div>
        {/* Intervention */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Intervention
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Intervention - Q33 */}
                  <SurveyButtonGroup
                    qNumber="Q33"
                    qText="Teaching you to establish and maintain effective relationships
                        with the recipients of psychological services."
                    value={formData[12].data[0].value}
                    formDataID={12}
                    dataID={0}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Intervention - Q33 */}
                  {/* Intervention - Q34 */}
                  <SurveyButtonGroup
                    qNumber="Q34"
                    qText="Teaching you to develop evidence-based Intervention plans
                        specific to the service delivery goals."
                    value={formData[12].data[1].value}
                    formDataID={12}
                    dataID={1}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Intervention - Q34 */}
                  {/* Intervention - Q35 */}
                  <SurveyButtonGroup
                    qNumber="Q35"
                    qText="Teaching you to implement interventions informed by the
                          current scientific literature, assessment findings, diversity
                          characteristics and contextual variables."
                    value={formData[12].data[2].value}
                    formDataID={12}
                    dataID={2}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Intervention - Q35 */}
                  {/* Intervention - Q36 */}
                  <SurveyButtonGroup
                    qNumber="Q36"
                    qText="Teaching you to demonstrate the ability to apply the relevant
                        research literature to clinical decision making."
                    value={formData[12].data[3].value}
                    formDataID={12}
                    dataID={3}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Intervention - Q36 */}
                  {/* Intervention - Q37 */}
                  <SurveyButtonGroup
                    qNumber="Q37"
                    qText="Teaching you to modify and adapt evidence-based approaches
                        effectively when a clear evidence-base is lacking."
                    value={formData[12].data[4].value}
                    formDataID={12}
                    dataID={4}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Intervention - Q37 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col sm={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s8-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s8-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s8-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderSupervisionConsultationAndInterpersonalSkills() {
    const { formData } = this.state;

    return (
      <div>
        {/* Supervision, Consultation and Interpersonal/Interdisciplinary Skills */}
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Supervision, Consultation and
                  Interpersonal/Interdisciplinary Skills
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Supervision - Q38 */}
                  <SurveyButtonGroup
                    qNumber="Q38"
                    qText="Teaching knowledge of supervision models and practices."
                    value={formData[13].data[0].value}
                    formDataID={13}
                    dataID={0}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Supervision - Q38 */}
                  {/* Supervision - Q39 */}
                  <SurveyButtonGroup
                    qNumber="Q39"
                    qText="Teaching knowledge and respect for the roles and perspectives of
                        other professions."
                    value={formData[13].data[1].value}
                    formDataID={13}
                    dataID={1}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Supervision - Q39 */}
                  {/* Supervision - Q40 */}
                  <SurveyButtonGroup
                    qNumber="Q40"
                    qText="Teaching knowledge of consultation models and practices."
                    value={formData[13].data[2].value}
                    formDataID={13}
                    dataID={2}
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Supervision - Q40 */}
                  <FormGroup row className="submitBlock mt-3">
                    <Col sm={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s9-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s9-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s9-b-submit"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'completed')}
                      >
                        Submit
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
        <p>
          <br />
        </p>
      </div>
    );
  }

  renderReadout() {
    const {
      formData,
      emailAddress,
      yearGraduatedSelect,
      ethnicitySelect,
      raceSelect,
      postdoctoralProgramName,
      postdoctoralProgramAddress,
      otherEmphasis,
      employerName,
      employerAddress,
      jobTitle,
      otherPositionSetting,
      otherWorkSetting,
      stateSelect,
      dateSelect,
      licenseNumber,
      npiNumber,
      organizationName,
      publicationSelect,
      presentationsSelect,
      doWell,
      doneBetter,
      suggestions,
    } = this.state;

    // set trainingProgramActivities array for Readout
    const trainingProgramActivities = [];
    if (formData[1].data[6].value[0].value === 1) {
      trainingProgramActivities.push('Administration');
    }
    if (formData[1].data[6].value[1].value === 1) {
      trainingProgramActivities.push('Assessment');
    }
    if (formData[1].data[6].value[2].value === 1) {
      trainingProgramActivities.push('Consultation');
    }
    if (formData[1].data[6].value[3].value === 1) {
      trainingProgramActivities.push('Psychotherapy');
    }
    if (formData[1].data[6].value[4].value === 1) {
      trainingProgramActivities.push('Research');
    }
    if (formData[1].data[6].value[5].value === 1) {
      trainingProgramActivities.push('Supervision');
    }
    if (formData[1].data[6].value[6].value === 1) {
      trainingProgramActivities.push('Teaching');
    }
    if (formData[1].data[6].value[7].value === 1) {
      trainingProgramActivities.push('Unknown');
    }
    if (formData[1].data[6].value[8].value === 1) {
      trainingProgramActivities.push('Other');
    }
    if (trainingProgramActivities.length === 0) {
      trainingProgramActivities.push('N/A');
    }

    // set employmentSettingTypes array for Readout
    const employmentSettingTypes = [];
    if (formData[2].data[4].value[0].value === 1) {
      employmentSettingTypes.push('Academic Teaching');
    }
    if (formData[2].data[4].value[1].value === 1) {
      employmentSettingTypes.push('Academic Medical Center');
    }
    if (formData[2].data[4].value[2].value === 1) {
      employmentSettingTypes.push('Area Health Education Center');
    }
    if (formData[2].data[4].value[3].value === 1) {
      employmentSettingTypes.push('Certified Community Behavioral Health Center (CCBHC)');
    }
    if (formData[2].data[4].value[4].value === 1) {
      employmentSettingTypes.push('Community Mental Health Center');
    }
    if (formData[2].data[4].value[5].value === 1) {
      employmentSettingTypes.push('Community Health Center (CHC)');
    }
    if (formData[2].data[4].value[6].value === 1) {
      employmentSettingTypes.push('Consortium');
    }
    if (formData[2].data[4].value[7].value === 1) {
      employmentSettingTypes.push('Correctional Facility');
    }
    if (formData[2].data[4].value[8].value === 1) {
      employmentSettingTypes.push('Critical Access Hospital');
    }
    if (formData[2].data[4].value[9].value === 1) {
      employmentSettingTypes.push('Federal Government');
    }
    if (formData[2].data[4].value[10].value === 1) {
      employmentSettingTypes.push('FQHC or Look-Alike');
    }
    if (formData[2].data[4].value[11].value === 1) {
      employmentSettingTypes.push('Health Department (local/state/tribal)');
    }
    if (formData[2].data[4].value[12].value === 1) {
      employmentSettingTypes.push('Health Maintenance Organization');
    }
    if (formData[2].data[4].value[13].value === 1) {
      employmentSettingTypes.push('Hospital (non-academic)');
    }
    if (formData[2].data[4].value[14].value === 1) {
      employmentSettingTypes.push('Indian Health Service (HIS)/Tribal/Urban Indian Health Center');
    }
    if (formData[2].data[4].value[15].value === 1) {
      employmentSettingTypes.push('Independent Practice');
    }
    if (formData[2].data[4].value[16].value === 1) {
      employmentSettingTypes.push('Nursing Home');
    }
    if (formData[2].data[4].value[17].value === 1) {
      employmentSettingTypes.push('Other Clinical Health Setting');
    }
    if (formData[2].data[4].value[18].value === 1) {
      employmentSettingTypes.push('Other Community-Based Organization');
    }
    if (formData[2].data[4].value[19].value === 1) {
      employmentSettingTypes.push('Other Long-term Care Facility');
    }
    if (formData[2].data[4].value[20].value === 1) {
      employmentSettingTypes.push('Other Specialty Clinic');
    }
    if (formData[2].data[4].value[21].value === 1) {
      employmentSettingTypes.push('Private Industry');
    }
    if (formData[2].data[4].value[22].value === 1) {
      employmentSettingTypes.push('Psychiatric Facility');
    }
    if (formData[2].data[4].value[23].value === 1) {
      employmentSettingTypes.push(
        'Residential Living Facility (including independent and assisted living)',
      );
    }
    if (formData[2].data[4].value[24].value === 1) {
      employmentSettingTypes.push('Rural Health Clinic');
    }
    if (formData[2].data[4].value[25].value === 1) {
      employmentSettingTypes.push('School District or System');
    }
    if (formData[2].data[4].value[26].value === 1) {
      employmentSettingTypes.push('State or Local Government');
    }
    if (formData[2].data[4].value[27].value === 1) {
      employmentSettingTypes.push('US Armed Forces');
    }
    if (formData[2].data[4].value[28].value === 1) {
      employmentSettingTypes.push('Veterans Affairs Healthcare (e.g., VA hospital or clinic)');
    }
    if (formData[2].data[4].value[29].value === 1) {
      employmentSettingTypes.push('Pursuing Additional Education or Training');
    }
    if (formData[2].data[4].value[30].value === 1) {
      employmentSettingTypes.push('University Counseling Center');
    }
    if (formData[2].data[4].value[31].value === 1) {
      employmentSettingTypes.push('Not Currently Employed');
    }
    if (formData[2].data[4].value[32].value === 1) {
      employmentSettingTypes.push('Other');
    }
    if (employmentSettingTypes.length === 0) {
      employmentSettingTypes.push('N/A');
    }

    // set employmentActivities array for Readout
    const employmentActivities = [];
    if (formData[2].data[6].value[0].value === 1) {
      employmentActivities.push('Administration');
    }
    if (formData[2].data[6].value[1].value === 1) {
      employmentActivities.push('Assessment');
    }
    if (formData[2].data[6].value[2].value === 1) {
      employmentActivities.push('Consultation');
    }
    if (formData[2].data[6].value[3].value === 1) {
      employmentActivities.push('Psychotherapy');
    }
    if (formData[2].data[6].value[4].value === 1) {
      employmentActivities.push('Research');
    }
    if (formData[2].data[6].value[5].value === 1) {
      employmentActivities.push('Supervision');
    }
    if (formData[2].data[6].value[6].value === 1) {
      employmentActivities.push('Teaching');
    }
    if (formData[2].data[6].value[7].value === 1) {
      employmentActivities.push('Unknown');
    }
    if (formData[2].data[6].value[8].value === 1) {
      employmentActivities.push('Other');
    }
    if (employmentActivities.length === 0) {
      employmentActivities.push('N/A');
    }

    // set employmentWorkSettings array for Readout
    const employmentWorkSettings = [];
    if (formData[2].data[7].value[0].value === 1) {
      employmentWorkSettings.push('Health Professional Shortage Area');
    }
    if (formData[2].data[7].value[1].value === 1) {
      employmentWorkSettings.push('Medically Underserved Community');
    }
    if (formData[2].data[7].value[2].value === 1) {
      employmentWorkSettings.push('National Health Service Corps (NHSC) - Approved Site');
    }
    if (formData[2].data[7].value[3].value === 1) {
      employmentWorkSettings.push('Primary Care Setting');
    }
    if (formData[2].data[7].value[4].value === 1) {
      employmentWorkSettings.push('Rural Area');
    }
    if (formData[2].data[7].value[5].value === 1) {
      employmentWorkSettings.push('None of the Above');
    }
    if (formData[2].data[7].value[6].value === 1) {
      employmentWorkSettings.push('Other');
    }
    if (employmentWorkSettings.length === 0) {
      employmentWorkSettings.push('N/A');
    }

    return (
      <div>
        <Row>
          <Col>
            {/* Demographics */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Demographics
                </h5>
              }
            >
              {/* Demographics - Permanent Email */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Permanent e-mail address: <b className="ml-2">{emailAddress}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - Permanent Email */}
              {/* Demographics - Year Graduated */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Year Graduated from LLU: <b className="ml-2">{yearGraduatedSelect}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - Year Graduated */}
              {/* Demographics - Ethnicity */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Ethnicity: <b className="ml-2">{ethnicitySelect}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - Ethnicity */}
              {/* Demographics - Race */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Race: <b className="ml-2">{raceSelect}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - Race */}
              {/* Demographics - 1st Gen College */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Are you the first generation in your family to graduate from college?{' '}
                    <b className="ml-2">{formData[0].data[4].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - 1st Gen College */}
              {/* Demographics - Veteran */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    Are you a military veteran? <b className="ml-2">{formData[0].data[5].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Demographics - Veteran */}
            </Widget>

            {/* Formal Postdoctoral Training Program */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Formal Postdoctoral Training Program
                </h5>
              }
            >
              {/* Formal Postdoctoral Training Program - Q1 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q1</b>
                    </Label>{' '}
                    Are you enrolled in a formal postdoctoral training program?{' '}
                    <b className="ml-2">{formData[1].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* Formal Postdoctoral Training Program - Q1 - subQs */}
              {(() => {
                if (formData[1].data[0].value === 'Yes') {
                  return (
                    <div>
                      {/* Formal Postdoctoral Training Program - Post Doctoral Program Name */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Program Name: <b className="ml-2">{postdoctoralProgramName}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Formal Postdoctoral Training Program - Post Doctoral Program Name */}
                      {/* Formal Postdoctoral Training Program - Post Doctoral Program Address */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Program Address: <b className="ml-2">{postdoctoralProgramAddress}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Formal Postdoctoral Training Program - Post Doctoral Program Address */}
                      {/* // Formal Postdoctoral Training Program - Accredited */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Is it accredited? <b className="ml-2">{formData[1].data[3].value}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Formal Postdoctoral Training Program - Accredited */}
                      {/* Formal Postdoctoral Training Program - Emphasis */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Emphasis: <b className="ml-2">{formData[1].data[4].value}</b>
                          </p>
                        </Col>
                      </Row>
                      {(() => {
                        if (formData[1].data[4].value === 'Other') {
                          return (
                            <div>
                              <Row className="mt-4">
                                <Col className="text-center">
                                  <p className="readout">
                                    Please specify the other emphasis:{' '}
                                    <b className="ml-2">{otherEmphasis}</b>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      })()}
                      {/* // Formal Postdoctoral Training Program - Emphasis */}
                      {/* Formal Postdoctoral Training Program - Activities */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Select all of the activities that apply to the position:
                            <b className="ml-2">{trainingProgramActivities.join(', ')}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Formal Postdoctoral Training Program - Activities */}
                    </div>
                  );
                }
              })()}
              {/* // Formal Postdoctoral Training Program - Q1 - subQs */}
              {/* // Formal Postdoctoral Training Program - Q1 */}
            </Widget>

            {/* Professional Employment */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Professional Employment
                </h5>
              }
            >
              {/* Professional Employment - Q2 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q2</b>
                    </Label>{' '}
                    Are you employed professionally?{' '}
                    <b className="ml-2">{formData[2].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* Professional Employment - Q2 - subQs */}
              {(() => {
                if (formData[2].data[0].value === 'Yes') {
                  return (
                    <div>
                      {/* Professional Employment - Q2 - Subquestions */}
                      {/* Professional Employment - Employer Name */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Employer Name: <b className="ml-2">{employerName}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Professional Employment - Employer Name */}
                      {/* Professional Employment - Employer Address */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Employer Address: <b className="ml-2">{employerAddress}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Professional Employment - Employer Address */}
                      {/* Professional Employment - Job Title */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Job Title: <b className="ml-2">{jobTitle}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Professional Employment - Job Title */}
                      {/* Professional Employment - Setting Types */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Select all of the activities that apply to the position:
                            <b className="ml-2">{employmentSettingTypes.join(', ')}</b>
                          </p>
                        </Col>
                      </Row>
                      {(() => {
                        if (formData[2].data[4].value[32].value === 1) {
                          return (
                            <div>
                              <Row className="mt-4">
                                <Col className="text-center">
                                  <p className="readout">
                                    Please specify the other setting type:{' '}
                                    <b className="ml-2">{otherPositionSetting}</b>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      })()}
                      {/* // Professional Employment - Setting Types */}
                      {/* Professional Employment - Activities */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Select all of the activities that apply to the position:
                            <b className="ml-2">{employmentActivities.join(', ')}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // Professional Employment - Activities */}
                      {/* Professional Employment - Work Settings */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Do you work in any of the following settings?
                            <b className="ml-2">{employmentWorkSettings.join(', ')}</b>
                          </p>
                        </Col>
                      </Row>
                      {(() => {
                        if (formData[2].data[7].value[6].value === 1) {
                          return (
                            <div>
                              <Row className="mt-4">
                                <Col className="text-center">
                                  <p className="readout">
                                    Please specify the other setting:{' '}
                                    <b className="ml-2">{otherWorkSetting}</b>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                      })()}
                      {/* // Professional Employment - Work Settings */}
                    </div>
                  );
                }
              })()}
              {/* // Professional Employment - Q2 - subQs */}
              {/* // Professional Employment - Q2 */}
            </Widget>

            {/* State Licensed Psychologist */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> State Licensed Psychologist
                </h5>
              }
            >
              {/* State Licensed Psychologist - Q3 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q3</b>
                    </Label>{' '}
                    Did you obtain a license as a psychologist?{' '}
                    <b className="ml-2">{formData[3].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* State Licensed Psychologist - Q3 - subQs */}
              {(() => {
                if (formData[3].data[0].value === 'Yes') {
                  return (
                    <div>
                      {/* State Licensed Psychologist - State */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            State: <b className="ml-2">{stateSelect}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // State Licensed Psychologist - State */}
                      {/* State Licensed Psychologist - License Date */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            License Date:{' '}
                            {/* <b className="ml-2">{dateSelect.toLocaleDateString('en-US')}</b> */}
                            <b className="ml-2">{dateSelect}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // State Licensed Psychologist - License Date */}
                      {/* State Licensed Psychologist - License Number */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            License Number: <b className="ml-2">{licenseNumber}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // State Licensed Psychologist - License Number */}
                      {/* State Licensed Psychologist - NPI Number */}
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            NPI Number: <b className="ml-2">{npiNumber}</b>
                          </p>
                        </Col>
                      </Row>
                      {/* // State Licensed Psychologist - NPI Number */}
                    </div>
                  );
                }
              })()}
              {/* // State Licensed Psychologist - Q3 - subQs */}
              {/* // State Licensed Psychologist - Q3 */}
            </Widget>

            {/* Other Professional Activities */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Other Professional Activities
                </h5>
              }
            >
              {/* Other Professional Activities - Q4 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q4</b>
                    </Label>{' '}
                    Are you a member of a professional/scientific organization?{' '}
                    <b className="ml-2">{formData[4].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* Other Professional Activities - Q4 - subQs */}
              {(() => {
                if (formData[4].data[0].value === 'Yes') {
                  return (
                    <div>
                      <Row className="mt-4">
                        <Col className="text-center">
                          <p className="readout">
                            <Label>
                              <i className="fa fa-chevron-right" />
                            </Label>{' '}
                            Which one? <b className="ml-2">{organizationName}</b>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  );
                }
              })()}
              {/* // Other Professional Activities - Q4 - subQs */}
              {/* // Other Professional Activities - Q4 */}
              {/* Other Professional Activities - Q5 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q5</b>
                    </Label>{' '}
                    Number of publications since the year you graduated:{' '}
                    <b className="ml-2">{publicationSelect}</b>
                  </p>
                </Col>
              </Row>
              {/* // Other Professional Activities - Q5 */}
              {/* Other Professional Activities - Q6 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q6</b>
                    </Label>{' '}
                    Number of conference presentations since the year you graduated:{' '}
                    <b className="ml-2">{presentationsSelect}</b>
                  </p>
                </Col>
              </Row>
              {/* // Other Professional Activities - Q5 */}
              {/* Other Professional Activities - Q7 */}
              <Row className="mt-4">
                <Col sm={12} className="text-center">
                  <Label>
                    <b>
                      <u>Please upload your most up to date CV</u>
                    </b>
                  </Label>
                  {this.renderUploadedFiles('cv')}
                </Col>
              </Row>
              {/* // Other Professional Activities - Q7 */}
            </Widget>

            {/* Program Feedback */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Program Feedback
                </h5>
              }
            >
              {/* Program Feedback - Q7 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q7</b>
                    </Label>{' '}
                    <u>
                      What did LLU do particularly well in preparing you for your current
                      employment?
                    </u>
                  </p>
                  <p className="readoutComments">{doWell}</p>
                </Col>
              </Row>
              {/* // Program Feedback - Q7 */}
              {/* Program Feedback - Q8 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q8</b>
                    </Label>{' '}
                    <u>
                      What do you think LLU could have done better to prepare you for your current
                      employment?
                    </u>
                  </p>
                  <p className="readoutComments">{doneBetter}</p>
                </Col>
              </Row>
              {/* // Program Feedback - Q8 */}
              {/* Program Feedback - Q9 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q9</b>
                    </Label>{' '}
                    <u>
                      Do you have any suggestion for us that would help us better prepare our
                      graduates for future employment?
                    </u>
                  </p>
                  <p className="readoutComments">{suggestions}</p>
                </Col>
              </Row>
              {/* // Program Feedback - Q9 */}
            </Widget>

            {/* Research and Ethical Standards */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Research and Ethical Standards
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Research and Ethical Standards - Q8 */}
              <Row className="mt-5">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q10</b>
                    </Label>{' '}
                    Teaching you how to independently formulate research or other scholarly
                    activities (e.g. critical literature reviews, dissertation, efficacy studies,
                    clinical case studies, theoretical papers, program evaluation projects, program
                    development projects) that are of sufficient quality and rigor to have the
                    potential to contribute to the scientific, psychological or professional
                    knowledge base: <b className="ml-2">{formData[6].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Research and Ethical Standards - Q8 */}
              {/* Research and Ethical Standards - Q9 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q11</b>
                    </Label>{' '}
                    Teaching you how to critically evaluate research:{' '}
                    <b className="ml-2">{formData[6].data[1].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Research and Ethical Standards - Q9 */}
              {/* Research and Ethical Standards - Q10 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q12</b>
                    </Label>{' '}
                    Teaching you how to disseminate research or other scholarly activities via
                    professional presentations or publications. These can be at the local, regional
                    or national level: <b className="ml-2">{formData[6].data[2].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Research and Ethical Standards - Q10 */}
            </Widget>

            {/* Legal and Ethical Standards */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Legal and Ethical Standards
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Legal and Ethical Standards - Q13 */}
              <Row className="mt-5">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q13</b>
                    </Label>{' '}
                    Teaching you how to be knowledgeable of an act in accordance with the relevant
                    laws, regulations, rules and policies governing health service psychology at the
                    organizational, local, state, regional and federal levels; and relevant
                    professional standards and guidelines:{' '}
                    <b className="ml-2">{formData[7].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Legal and Ethical Standards - Q13 */}
              {/* Legal and Ethical Standards - Q14 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q14</b>
                    </Label>{' '}
                    Teaching you how to recognize ethical dilemmas as they arise and apply ethical
                    decision-making processes in order to resolve the dilemmas:{' '}
                    <b className="ml-2">{formData[7].data[1].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Legal and Ethical Standards - Q14 */}
              {/* Legal and Ethical Standards - Q15 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q15</b>
                    </Label>{' '}
                    Teaching you to conduct yourself in an ethical manner in all professional
                    activities: <b className="ml-2">{formData[7].data[2].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Legal and Ethical Standards - Q15 */}
            </Widget>

            {/* Individual and Cultural Diversity */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Individual and Cultural Diversity
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Individual and Cultural Diversity - Q16 */}
              <Row className="mt-5">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q16</b>
                    </Label>{' '}
                    Teaching you how to understand how your own personal/cultural history, attitudes
                    and biases may affect how you understand and interact with people different than
                    yourself: <b className="ml-2">{formData[8].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Individual and Cultural Diversity - Q16 */}
              {/* Individual and Cultural Diversity - Q17 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q17</b>
                    </Label>{' '}
                    Teaching you how the current theoretical and empirical knowledge base as it
                    relates to addressing diversity in all professional activities, including
                    research, training, supervision, consultation and service:{' '}
                    <b className="ml-2">{formData[8].data[1].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Individual and Cultural Diversity - Q17 */}
              {/* Individual and Cultural Diversity - Q18 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q18</b>
                    </Label>{' '}
                    Teaching you how to integrate awareness and knowledge of individual and cultural
                    differences in the conduct of your professional roles (e.g. research, services
                    and other professional activities):{' '}
                    <b className="ml-2">{formData[8].data[2].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Individual and Cultural Diversity - Q18 */}
              {/* Individual and Cultural Diversity - Q19 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q19</b>
                    </Label>{' '}
                    Teaching you the requisite knowledge base and ability to articulate an approach
                    to working effectively with diverse individuals and groups, and then apply this
                    approach effectively in your professional work:{' '}
                    <b className="ml-2">{formData[8].data[3].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Individual and Cultural Diversity - Q17 */}
            </Widget>

            {/* Professional Values and Attitudes */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Professional Values and Attitudes
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/*  Professional Values and Attitudes - Q20 */}
              <Row className="mt-5">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q20</b>
                    </Label>{' '}
                    Teaching you to behave in ways that reflect the values and attitudes of
                    psychology, including integrity, deportment, professional identity,
                    accountability, lifelong learning and concern for the welfare of others:{' '}
                    <b className="ml-2">{formData[9].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/*  // Professional Values and Attitudes - Q20 */}
              {/*  Professional Values and Attitudes - Q21 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q21</b>
                    </Label>{' '}
                    Teaching you to engage in self-reflection regarding your personal and
                    professional functioning: <b className="ml-2">{formData[9].data[1].value}</b>
                  </p>
                </Col>
              </Row>
              {/*  // Professional Values and Attitudes - Q21 */}
              {/*  Professional Values and Attitudes - Q22 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q22</b>
                    </Label>{' '}
                    Teaching you to engage in activities to maintain and improve performance,
                    well-being and professional effectiveness:{' '}
                    <b className="ml-2">{formData[9].data[2].value}</b>
                  </p>
                </Col>
              </Row>
              {/*  // Professional Values and Attitudes - Q22 */}
              {/*  Professional Values and Attitudes - Q23 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q23</b>
                    </Label>{' '}
                    Teaching you to actively seek and demonstrate openness and responsiveness to
                    feedback and supervision: <b className="ml-2">{formData[9].data[3].value}</b>
                  </p>
                </Col>
              </Row>
              {/*  Professional Values and Attitudes - Q23 */}
              {/*  Professional Values and Attitudes - Q24 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q24</b>
                    </Label>{' '}
                    Teaching you to respond professionally to increasingly complex situations with a
                    greater degree of independence as you progressed across levels of training:{' '}
                    <b className="ml-2">{formData[9].data[4].value}</b>
                  </p>
                </Col>
              </Row>
              {/*  // Professional Values and Attitudes - Q24 */}
            </Widget>

            {/* Communication and Interpersonal Skills */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Communication and Interpersonal Skills
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Communication and Interpersonal Skills - Q25 */}
              <Row className="mt-5">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q25</b>
                    </Label>{' '}
                    Teaching you how to develop and maintain effective relationships with a wide
                    range of individuals, including colleagues, communities, organizations,
                    supervisors, supervisees and those receiving professional services:{' '}
                    <b className="ml-2">{formData[10].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Communication and Interpersonal Skills - Q25 */}
              {/*  Communication and Interpersonal Skills - Q26 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q26</b>
                    </Label>{' '}
                    Teaching you to produce and comprehend oral, nonverbal and written
                    communications that are informative and well-integrated:{' '}
                    <b className="ml-2">{formData[10].data[1].value}</b>
                  </p>
                </Col>
              </Row>
              {/*  // Communication and Interpersonal Skills - Q26 */}
              {/* Communication and Interpersonal Skills - Q27 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q27</b>
                    </Label>{' '}
                    Teaching you to demonstrate a thorough grasp of professional language and
                    concepts: <b className="ml-2">{formData[10].data[2].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Communication and Interpersonal Skills - Q27 */}
              {/* Communication and Interpersonal Skills - Q28 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q28</b>
                    </Label>{' '}
                    Teaching you to demonstrate effective interpersonal skills and the ability to
                    manage difficult communication well:{' '}
                    <b className="ml-2">{formData[10].data[3].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Communication and Interpersonal Skills - Q26 */}
            </Widget>

            {/* Assessment */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Assessment
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Assessment - Q29 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q29</b>
                    </Label>{' '}
                    Teaching you to select and apply assessment methods that draw from the best
                    available empirical literature and that reflect the science of measurement and
                    psychometrics: <b className="ml-2">{formData[11].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Assessment - Q29 */}
              {/*  Assessment - Q30 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q30</b>
                    </Label>{' '}
                    Teaching you to collect relevant assessment data using multiple sources and
                    methods appropriate to the identified goals and questions of the assessment, as
                    well as relevant diversity characteristics of the service recipient:{' '}
                    <b className="ml-2">{formData[11].data[1].value}</b>
                  </p>
                </Col>
              </Row>
              {/*  // Assessment - Q30 */}
              {/* Assessment - Q31 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q31</b>
                    </Label>{' '}
                    Teaching you to interpret assessment results, following current research and
                    professional standards and guidelines, to inform case conceptualization,
                    classification and recommendations, while guarding against decision-making
                    biases, distinguishing the aspects of assessment that are subjective from those
                    that are objective: <b className="ml-2">{formData[11].data[2].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Assessment - Q31 */}
              {/* Assessment - Q32 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q32</b>
                    </Label>{' '}
                    Teaching you to communicate orally and in written documents the findings and
                    implications of the assessment in an accurate and effective manner sensitive to
                    a range of audiences: <b className="ml-2">{formData[11].data[3].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Assessment - Q32 */}
            </Widget>

            {/* Intervention */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Intervention
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Intervention - Q33 */}
              <Row className="mt-5">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q33</b>
                    </Label>{' '}
                    Teaching you to establish and maintain effective relationships with the
                    recipients of psychological services:{' '}
                    <b className="ml-2">{formData[12].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Intervention - Q33 */}
              {/* Intervention - Q34 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q34</b>
                    </Label>{' '}
                    Teaching you to develop evidence-based Intervention plans specific to the
                    service delivery goals: <b className="ml-2">{formData[12].data[1].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Intervention - Q34 */}
              {/* Intervention - Q35 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q35</b>
                    </Label>{' '}
                    Teaching you to implement interventions informed by the current scientific
                    literature, assessment findings, diversity characteristics and contextual
                    variables: <b className="ml-2">{formData[12].data[2].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Intervention - Q35 */}
              {/* Intervention - Q36 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q36</b>
                    </Label>{' '}
                    Teaching you to demonstrate the ability to apply the relevant research
                    literature to clinical decision making:{' '}
                    <b className="ml-2">{formData[12].data[3].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Intervention - Q36 */}
              {/* Intervention - Q37 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q37</b>
                    </Label>{' '}
                    Teaching you to modify and adapt evidence-based approaches effectively when a
                    clear evidence-base is lacking:{' '}
                    <b className="ml-2">{formData[12].data[4].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Intervention - Q37 */}
            </Widget>

            {/* Supervision, Consultation and Interpersonal/Interdisciplinary Skills */}
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Supervision, Consultation and
                  Interpersonal/Interdisciplinary Skills
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please use the following guidelines to rate how
                      effective the program was in meeting each training goal:
                    </p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Not effective at all
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Minimally effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Somewhat effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Moderately effective
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={10}>
                        Very effective
                      </Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Supervision - Q38 */}
              <Row className="mt-5">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q38</b>
                    </Label>{' '}
                    Teaching knowledge of supervision models and practices:{' '}
                    <b className="ml-2">{formData[13].data[0].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Supervision - Q38 */}
              {/* Supervision - Q39 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q39</b>
                    </Label>{' '}
                    Teaching knowledge and respect for the roles and perspectives of other
                    professions: <b className="ml-2">{formData[13].data[1].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Supervision - Q39 */}
              {/* Supervision - Q40 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q40</b>
                    </Label>{' '}
                    Teaching knowledge of consultation models and practices:{' '}
                    <b className="ml-2">{formData[13].data[2].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Supervision - Q40 */}
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, error, currentSection, studentName } = this.state;

    if (!loading && !error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Row>
                <Col xs="12">
                  <div className="crumbs">
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link to="/profile">Profile</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Alumni Survey</BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </Col>
              </Row>
              <h2 className="page-title">
                Alumni Survey: <small>{studentName}</small>
              </h2>
              {currentSection === 0 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={0} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 0 && this.renderDemographics()}
              {currentSection === 1 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={13} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 1 && this.renderPostdoctoralTrainingProgram()}
              {currentSection === 2 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={15} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 2 && this.renderProfessionalEmployment()}
              {currentSection === 3 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={17} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 3 && this.renderStateLicensedPsychologist()}
              {currentSection === 4 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={20} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 4 && this.renderOtherProfessionalActivities()}
              {currentSection === 5 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={26} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 5 && this.renderProgramFeedback()}
              {currentSection === 6 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={33} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 6 && this.renderResearchAndEthicalStandards()}
              {currentSection === 7 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={39} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 7 && this.renderLegalAndEthicalStandards()}
              {currentSection === 8 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={46} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 8 && this.renderIndividualAndCulturalDiversity()}
              {currentSection === 9 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={54} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 9 && this.renderProfessionalValuesAndAttitudes()}
              {currentSection === 10 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={65} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 10 && this.renderCommunicationAndInterpersonalSkills()}
              {currentSection === 11 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={74} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 11 && this.renderAssessment()}
              {currentSection === 12 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={83} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 12 && this.renderIntervention()}
              {currentSection === 13 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={93} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 13 && this.renderSupervisionConsultationAndInterpersonalSkills()}
              {currentSection === 999 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={100} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 999 && this.renderReadout()}
            </Col>
          </Row>
        </div>
      );
    }
    if (loading) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <h2 className="page-title">Student Progress Report</h2>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
            </Col>
          </Row>
        </div>
      );
    }
    if (error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Widget>
                <Row className="mt-4 mb-4 text-center">
                  <Col>
                    <h4>
                      There was an error loading the form, please reload the page and try again.
                    </h4>
                  </Col>
                </Row>
              </Widget>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

AS.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(AS);
