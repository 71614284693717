import React from 'react';
import { Container, Navbar, NavbarBrand } from 'reactstrap';

import './Footer.scss';

function Footer() {
  return (
    <div className="footer">
      <Navbar>
        <Container className="brand-center justify-content-center">
          <NavbarBrand style={{ color: 'white', fontSize: '0.8em' }}>
            LLU Student Evaluation System ~ all rights reserved
          </NavbarBrand>
        </Container>
      </Navbar>
    </div>
  );
}

export default Footer;
