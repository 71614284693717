/* eslint-disable react/no-unescaped-entities */
/* eslint no-nested-ternary: "off" */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Breadcrumb, BreadcrumbItem, Row, Col, Label } from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../../Auth/Auth';

import Widget from '../../Widget/Widget';

import '../forms.scss';

import loadingSpinner from '../../../images/Eclipse-1s-200px.svg';

class saceStudentReadout extends React.Component {
  constructor(props) {
    super(props);
    this.getForm = this.getForm.bind(this);
    this.renderReadout = this.renderReadout.bind(this);

    this.state = {
      formData: [],
      childAssessmentCases: 0,
      childTreatmentCases: 0,
      adultAssessmentCases: 0,
      adultTreatmentCases: 0,
      couplesFamilyAssessmentCases: 0,
      couplesFamilyTreatmentCases: 0,
      otherComments: '',
      practicumRecommendations: '',
      mainStrengths: '',
      areasOfImprovement: '',
      practicumSiteName: '',
      practicumSiteSupervisor: '',
      studentName: '',
      program: '',
      term: '',
      academicYear: '',
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.getForm();
  }

  getForm() {
    const { match } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/form/${match.params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        let {
          formData,
          childAssessmentCases,
          childTreatmentCases,
          adultAssessmentCases,
          adultTreatmentCases,
          couplesFamilyAssessmentCases,
          couplesFamilyTreatmentCases,
          otherComments,
          practicumRecommendations,
          mainStrengths,
          areasOfImprovement,
        } = this.state;

        const { data, student, practicumSiteName, practicumSiteSupervisor, term, academicYear } =
          response.data;
        const studentName = `${student.firstName} ${student.lastName}`;

        // check if data was returned in a array
        if (data.length === 8) {
          formData = data;
        } else {
          // eslint-disable-next-line prefer-destructuring
          formData = data[0];
        }

        // set number of assessments + comments
        childAssessmentCases = formData[0].data[8].value;
        childTreatmentCases = formData[0].data[9].value;
        adultAssessmentCases = formData[0].data[10].value;
        adultTreatmentCases = formData[0].data[11].value;
        couplesFamilyAssessmentCases = formData[0].data[12].value;
        couplesFamilyTreatmentCases = formData[0].data[13].value;
        otherComments = formData[7].data[0].value;
        practicumRecommendations = formData[7].data[1].value;
        mainStrengths = formData[7].data[2].value;
        areasOfImprovement = formData[7].data[3].value;

        // check comments and set to 'No Comments' if empty
        if (otherComments === '') {
          otherComments = 'No Comments';
        }
        if (practicumRecommendations === '') {
          practicumRecommendations = 'No Comments';
        }
        if (mainStrengths === '') {
          mainStrengths = 'No Comments';
        }
        if (areasOfImprovement === '') {
          areasOfImprovement = 'No Comments';
        }

        this.setState({
          formData,
          childAssessmentCases,
          childTreatmentCases,
          adultAssessmentCases,
          adultTreatmentCases,
          couplesFamilyAssessmentCases,
          couplesFamilyTreatmentCases,
          otherComments,
          practicumRecommendations,
          mainStrengths,
          areasOfImprovement,
          practicumSiteName,
          practicumSiteSupervisor,
          studentName,
          program: student.currentProgram,
          term,
          academicYear,
          loading: false,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  renderReadout() {
    const {
      formData,
      practicumSiteName,
      practicumSiteSupervisor,
      studentName,
      program,
      otherComments,
      practicumRecommendations,
      mainStrengths,
      areasOfImprovement,
    } = this.state;

    // set directObservationType array for Readout
    const directObservationType = [];
    if (formData[0].data[6].value[0].value === 1) {
      directObservationType.push('Live Observation');
    }
    if (formData[0].data[6].value[1].value === 1) {
      directObservationType.push('Audio Recording');
    }
    if (formData[0].data[6].value[2].value === 1) {
      directObservationType.push('Video Recording');
    }
    if (directObservationType.length === 0) {
      directObservationType.push('N/A');
    }

    return (
      <div>
        <Row>
          <Col>
            {/* General Information */}
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> General Information{' '}
                </h5>
              }
            >
              {/* Student Ratings - Practicum Site */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <u>
                        <b>Practicum Site</b>
                      </u>
                    </Label>
                    <br />
                    {practicumSiteName}
                  </p>
                </Col>
              </Row>
              {/* // Student Ratings - Practicum Site */}
              {/* Student Ratings - Supervisor */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <u>
                        <b>Supervisor</b>
                      </u>
                    </Label>
                    <br />
                    {practicumSiteSupervisor}
                  </p>
                </Col>
              </Row>
              {/* // Student Ratings - Supervisor */}
              {/* Student Ratings - Student */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <u>
                        <b>Student</b>
                      </u>
                    </Label>
                    <br />
                    {studentName}
                  </p>
                </Col>
              </Row>
              {/* // Student Ratings - Student */}
              {/* Student Ratings - Q1 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q1</b>
                    </Label>{' '}
                    Is your training site a healthcare or medical facility:{' '}
                    <b className="ml-2">{formData[0].data[3].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Student Ratings - Q1 */}
              {/* Student Ratings - Q2 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q2</b>
                    </Label>{' '}
                    Did you use a direct observation method at least once during the evaluation
                    period: <b className="ml-2">{formData[0].data[4].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Student Ratings - Q2 */}
              {formData[0].data[4].value === 'Yes' && (
                <div>
                  {/* Student Ratings - Q2A */}
                  <Row>
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <i className="fa fa-chevron-right" />
                        </Label>{' '}
                        Was the direct observation provided by the licensed supervisor who is
                        legally responsible for the student's training:{' '}
                        <b className="ml-2">{formData[0].data[5].value}</b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Student Ratings - Q2A */}
                  {/* Student Ratings - Q2B */}
                  <Row>
                    <Col className="text-center">
                      <p className="readout">
                        <Label>
                          <i className="fa fa-chevron-right" />
                        </Label>{' '}
                        Select all of the direct observations that apply to the position:{' '}
                        <b className="ml-2">
                          {directObservationType === 0 ? 'None' : directObservationType.join(', ')}
                        </b>
                      </p>
                    </Col>
                  </Row>
                  {/* // Student Ratings - Q2B */}
                </div>
              )}
              {/* Student Ratings - Q3 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q3</b>
                    </Label>{' '}
                    My experience and knowledge of this student's clinical performance is:
                    <b className="ml-2">{formData[0].data[7].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Student Ratings - Q3 */}
              {/* Student Ratings - Q4 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q4</b>
                    </Label>{' '}
                    Please indicate the approximate number of each type of case that you have
                    supervised
                  </p>
                  <p className="readout">
                    Child Assessment:
                    <b className="ml-2">{formData[0].data[8].value}</b>
                  </p>
                  <p className="readout">
                    Child Treatment:
                    <b className="ml-2">{formData[0].data[9].value}</b>
                  </p>
                  <p className="readout">
                    Adult Assessment:
                    <b className="ml-2">{formData[0].data[10].value}</b>
                  </p>
                  <p className="readout">
                    Adult Treatment:
                    <b className="ml-2">{formData[0].data[11].value}</b>
                  </p>
                  <p className="readout">
                    Couples / Family Assessment:
                    <b className="ml-2">{formData[0].data[12].value}</b>
                  </p>
                  <p className="readout">
                    Couples / Family Treatment:
                    <b className="ml-2">{formData[0].data[13].value}</b>
                  </p>
                </Col>
              </Row>
              {/* // Student Ratings - Q4 */}
            </Widget>
            {/* // General Information */}
            {/* General Clinical Skills */}
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> General Clinical Skills{' '}
                </h5>
              }
            >
              <Row>
                <Col xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please rate your supervisee using the following
                      scale:
                    </p>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11}>
                        Student committed a critical error (e.g. violated APA Code of Ethics)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11}>Beginner</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11}>
                        Requires substantial supervision (expected by the end of internal practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11}>
                        Requires moderate supervision (expected by the end of first year of external
                        practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11}>
                        Requires minimal supervision (expected by the end of pre-intern year or
                        second year of external practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11}>
                        Competent for Independent Practice (expected by the end of internship)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>6</strong>
                      </Col>
                      <Col sm={11}>Proficient</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>7</strong>
                      </Col>
                      <Col sm={11}>Expert</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11}>Not applicable</Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* General Clinical Skills - Q5 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q5</b>
                    </Label>{' '}
                    Shows familiarity with and commitment to the APA Code of Ethics:{' '}
                    <b className="ml-2">
                      {formData[1].data[0].value === '' || formData[1].data[0].value === -1
                        ? formData[1].data[0].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // General Clinical Skills - Q5 */}
              {/* General Clinical Skills - Q6 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q6</b>
                    </Label>{' '}
                    Demonstrates good verbal and non-verbal communication skills with clients:{' '}
                    <b className="ml-2">
                      {formData[1].data[1].value === '' || formData[1].data[1].value === -1
                        ? formData[1].data[1].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // General Clinical Skills - Q6 */}
              {/* General Clinical Skills - Q7 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q7</b>
                    </Label>{' '}
                    Shows empathy and warm regard for clients:{' '}
                    <b className="ml-2">
                      {formData[1].data[2].value === '' || formData[1].data[2].value === -1
                        ? formData[1].data[2].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // General Clinical Skills - Q7 */}
              {/* General Clinical Skills - Q8 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q8</b>
                    </Label>{' '}
                    Able to establish rapport:{' '}
                    <b className="ml-2">
                      {formData[1].data[3].value === '' || formData[1].data[3].value === -1
                        ? formData[1].data[3].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[3].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // General Clinical Skills - Q8 */}
              {/* General Clinical Skills - Q9 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q9</b>
                    </Label>{' '}
                    Demonstrates good interviewing style:{' '}
                    <b className="ml-2">
                      {formData[1].data[4].value === '' || formData[1].data[4].value === -1
                        ? formData[1].data[4].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[4].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // General Clinical Skills - Q9 */}
              {/* General Clinical Skills - Q10 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q10</b>
                    </Label>{' '}
                    Comfortable working with patients from diverse cultural, ethnic, gender and
                    sexual orientation backgrounds:{' '}
                    <b className="ml-2">
                      {formData[1].data[5].value === '' || formData[1].data[5].value === -1
                        ? formData[1].data[5].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[5].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // General Clinical Skills - Q10 */}
              {/* General Clinical Skills - Q11 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q11</b>
                    </Label>{' '}
                    Shows respect and appreciation for clients:{' '}
                    <b className="ml-2">
                      {formData[1].data[6].value === '' || formData[1].data[6].value === -1
                        ? formData[1].data[6].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[6].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // General Clinical Skills - Q11 */}
              {/* General Clinical Skills - Q12 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q12</b>
                    </Label>{' '}
                    Applies principles of health psychology when appropriate:{' '}
                    <b className="ml-2">
                      {formData[1].data[7].value === '' || formData[1].data[7].value === -1
                        ? formData[1].data[7].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[7].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // General Clinical Skills - Q12 */}
              {/* General Clinical Skills - Q13 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q13</b>
                    </Label>{' '}
                    Comfortable working in professional health and medical settings:{' '}
                    <b className="ml-2">
                      {formData[1].data[8].value === '' || formData[1].data[8].value === -1
                        ? formData[1].data[8].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[1].data[8].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // General Clinical Skills - Q13 */}
            </Widget>
            {/* // General Clinical Skills */}
            {/* Rating of Professional Behavior */}
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Rating of Professional Behavior{' '}
                </h5>
              }
            >
              <Row>
                <Col xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please rate your supervisee using the following
                      scale:
                    </p>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11}>
                        Student committed a critical error (e.g. violated APA Code of Ethics)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11}>Beginner</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11}>
                        Requires substantial supervision (expected by the end of internal practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11}>
                        Requires moderate supervision (expected by the end of first year of external
                        practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11}>
                        Requires minimal supervision (expected by the end of pre-intern year or
                        second year of external practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11}>
                        Competent for Independent Practice (expected by the end of internship)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>6</strong>
                      </Col>
                      <Col sm={11}>Proficient</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>7</strong>
                      </Col>
                      <Col sm={11}>Expert</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11}>Not applicable</Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Rating of Professional Behavior - Q14 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q14</b>
                    </Label>{' '}
                    Meets time demands:{' '}
                    <b className="ml-2">
                      {formData[2].data[0].value === '' || formData[2].data[0].value === -1
                        ? formData[2].data[0].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[2].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Rating of Professional Behavior - Q14 */}
              {/* Rating of Professional Behavior - Q15 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q15</b>
                    </Label>{' '}
                    Interacts appropriately with clinical staff and other professionals:{' '}
                    <b className="ml-2">
                      {formData[2].data[1].value === '' || formData[2].data[1].value === -1
                        ? formData[2].data[1].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[2].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Rating of Professional Behavior - Q15 */}
              {/* Rating of Professional Behavior - Q16 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q16</b>
                    </Label>{' '}
                    Maintains appropriate notes:{' '}
                    <b className="ml-2">
                      {formData[2].data[2].value === '' || formData[2].data[2].value === -1
                        ? formData[2].data[2].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[2].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Rating of Professional Behavior - Q16 */}
              {/* Rating of Professional Behavior - Q17 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q17</b>
                    </Label>{' '}
                    {program === 'Ph.D.' &&
                      'Cultivates professional identity of a scientist-practitioner:'}
                    {program === 'Psy.D.' &&
                      'Cultivates professional identity of a practitioner-scholar:'}{' '}
                    <b className="ml-2">
                      {formData[2].data[3].value === '' || formData[2].data[3].value === -1
                        ? formData[2].data[3].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[2].data[3].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Rating of Professional Behavior - Q17 */}
              {/* Rating of Professional Behavior - Q18 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q18</b>
                    </Label>{' '}
                    Able to consult with other psychologists:{' '}
                    <b className="ml-2">
                      {formData[2].data[4].value === '' || formData[2].data[4].value === -1
                        ? formData[2].data[4].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[2].data[4].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Rating of Professional Behavior - Q18 */}
              {/* Rating of Professional Behavior - Q19 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q19</b>
                    </Label>{' '}
                    Able to consult with other health care professionals:{' '}
                    <b className="ml-2">
                      {formData[2].data[5].value === '' || formData[2].data[5].value === -1
                        ? formData[2].data[5].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[2].data[5].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Rating of Professional Behavior - Q19 */}
            </Widget>
            {/* // Rating of Professional Behavior */}
            {/* Knowledge of Psychopathology */}
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Knowledge of Psychopathology{' '}
                </h5>
              }
            >
              <Row>
                <Col xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please rate your supervisee using the following
                      scale:
                    </p>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11}>
                        Student committed a critical error (e.g. violated APA Code of Ethics)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11}>Beginner</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11}>
                        Requires substantial supervision (expected by the end of internal practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11}>
                        Requires moderate supervision (expected by the end of first year of external
                        practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11}>
                        Requires minimal supervision (expected by the end of pre-intern year or
                        second year of external practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11}>
                        Competent for Independent Practice (expected by the end of internship)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>6</strong>
                      </Col>
                      <Col sm={11}>Proficient</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>7</strong>
                      </Col>
                      <Col sm={11}>Expert</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11}>Not applicable</Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Knowledge of Psychopathology - Q20 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q20</b>
                    </Label>{' '}
                    Has strong working knowledge of DSM-5:{' '}
                    <b className="ml-2">
                      {formData[3].data[0].value === '' || formData[3].data[0].value === -1
                        ? formData[3].data[0].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[3].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Knowledge of Psychopathology - Q20 */}
              {/* Knowledge of Psychopathology - Q21 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q21</b>
                    </Label>{' '}
                    Able to formulate a solid differential diagnosis:{' '}
                    <b className="ml-2">
                      {formData[3].data[1].value === '' || formData[3].data[1].value === -1
                        ? formData[3].data[1].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[3].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Knowledge of Psychopathology - Q21 */}
              {/* Knowledge of Psychopathology - Q22 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q22</b>
                    </Label>{' '}
                    Able to identify client's strengths as they apply to the presenting problem:{' '}
                    <b className="ml-2">
                      {formData[3].data[2].value === '' || formData[3].data[2].value === -1
                        ? formData[3].data[2].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[3].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Knowledge of Psychopathology - Q22 */}
            </Widget>
            {/* // Knowledge of Psychopathology */}
            {/* Clinical Treatment */}
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Clinical Treatment{' '}
                </h5>
              }
            >
              <Row>
                <Col xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please rate your supervisee using the following
                      scale:
                    </p>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11}>
                        Student committed a critical error (e.g. violated APA Code of Ethics)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11}>Beginner</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11}>
                        Requires substantial supervision (expected by the end of internal practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11}>
                        Requires moderate supervision (expected by the end of first year of external
                        practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11}>
                        Requires minimal supervision (expected by the end of pre-intern year or
                        second year of external practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11}>
                        Competent for Independent Practice (expected by the end of internship)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>6</strong>
                      </Col>
                      <Col sm={11}>Proficient</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>7</strong>
                      </Col>
                      <Col sm={11}>Expert</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11}>Not applicable</Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Clinical Treatment - Q23 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q23</b>
                    </Label>{' '}
                    Demonstrates knowledge of theory underlying clinical intervention strategies:{' '}
                    <b className="ml-2">
                      {formData[4].data[0].value === '' || formData[4].data[0].value === -1
                        ? formData[4].data[0].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[4].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Clinical Treatment - Q23 */}
              {/* Clinical Treatment - Q24 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q24</b>
                    </Label>{' '}
                    Able to indentify appropriate treatment plan:{' '}
                    <b className="ml-2">
                      {formData[4].data[1].value === '' || formData[4].data[1].value === -1
                        ? formData[4].data[1].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[4].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Clinical Treatment - Q24 */}
              {/* Clinical Treatment - Q25 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q25</b>
                    </Label>{' '}
                    Able to implement clinical techniques of at least one core theoretical
                    orientation:{' '}
                    <b className="ml-2">
                      {formData[4].data[2].value === '' || formData[4].data[2].value === -1
                        ? formData[4].data[2].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[4].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Clinical Treatment - Q25 */}
              {/* Clinical Treatment - Q26 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q26</b>
                    </Label>{' '}
                    Able to implement, evaluate and revise treatment plans:{' '}
                    <b className="ml-2">
                      {formData[4].data[3].value === '' || formData[4].data[3].value === -1
                        ? formData[4].data[3].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[4].data[3].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Clinical Treatment - Q26 */}
              {/* Clinical Treatment - Q27 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q27</b>
                    </Label>{' '}
                    Able to integrate empirically-supported treatments when appropriate:{' '}
                    <b className="ml-2">
                      {formData[4].data[4].value === '' || formData[4].data[4].value === -1
                        ? formData[4].data[4].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[4].data[4].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Clinical Treatment - Q27 */}
              {/* Clinical Treatment - Q28 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q28</b>
                    </Label>{' '}
                    Able to manage high-risk or difficult situations:{' '}
                    <b className="ml-2">
                      {formData[4].data[5].value === '' || formData[4].data[5].value === -1
                        ? formData[4].data[5].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[4].data[5].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Clinical Treatment - Q28 */}
              {/* Clinical Treatment - Q29 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q29</b>
                    </Label>{' '}
                    Able to conceptualize clinical cases from a biopsychosocial-spiritual
                    perspective:{' '}
                    <b className="ml-2">
                      {formData[4].data[6].value === '' || formData[4].data[6].value === -1
                        ? formData[4].data[6].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[4].data[6].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Clinical Treatment - Q29 */}
            </Widget>
            {/* // Clinical Treatment */}
            {/* Assessment and Consultation */}
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Assessment and Consultation{' '}
                </h5>
              }
            >
              <Row>
                <Col xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please rate your supervisee using the following
                      scale:
                    </p>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11}>
                        Student committed a critical error (e.g. violated APA Code of Ethics)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11}>Beginner</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11}>
                        Requires substantial supervision (expected by the end of internal practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11}>
                        Requires moderate supervision (expected by the end of first year of external
                        practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11}>
                        Requires minimal supervision (expected by the end of pre-intern year or
                        second year of external practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11}>
                        Competent for Independent Practice (expected by the end of internship)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>6</strong>
                      </Col>
                      <Col sm={11}>Proficient</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>7</strong>
                      </Col>
                      <Col sm={11}>Expert</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11}>Not applicable</Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Assessment and Consultation - Q30 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q30</b>
                    </Label>{' '}
                    Exhibits strong interviewing skills:{' '}
                    <b className="ml-2">
                      {formData[5].data[0].value === '' || formData[5].data[0].value === -1
                        ? formData[5].data[0].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[5].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Assessment and Consultation - Q30 */}
              {/* Assessment and Consultation - Q31 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q31</b>
                    </Label>{' '}
                    Able to administer and score psychological tests:{' '}
                    <b className="ml-2">
                      {formData[5].data[1].value === '' || formData[5].data[1].value === -1
                        ? formData[5].data[1].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[5].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Assessment and Consultation - Q31 */}
              {/* Assessment and Consultation - Q32 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q32</b>
                    </Label>{' '}
                    Able to interpret and integrate test results:{' '}
                    <b className="ml-2">
                      {formData[5].data[2].value === '' || formData[5].data[2].value === -1
                        ? formData[5].data[2].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[5].data[2].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Assessment and Consultation - Q32 */}
              {/* Assessment and Consultation - Q33 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q33</b>
                    </Label>{' '}
                    Able to generate appropriate recommendations:{' '}
                    <b className="ml-2">
                      {formData[5].data[3].value === '' || formData[5].data[3].value === -1
                        ? formData[5].data[3].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[5].data[3].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Assessment and Consultation - Q33 */}
              {/* Assessment and Consultation - Q34 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q34</b>
                    </Label>{' '}
                    Writes good written assessment reports:{' '}
                    <b className="ml-2">
                      {formData[5].data[4].value === '' || formData[5].data[4].value === -1
                        ? formData[5].data[4].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[5].data[4].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Assessment and Consultation - Q34 */}
              {/* Assessment and Consultation - Q35 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q35</b>
                    </Label>{' '}
                    Gives appropriate feedback to patient and/or referral source:{' '}
                    <b className="ml-2">
                      {formData[5].data[5].value === '' || formData[5].data[5].value === -1
                        ? formData[5].data[5].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[5].data[5].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Assessment and Consultation - Q35 */}
            </Widget>
            {/* // Assessment and Consultation */}
            {/* Use of Supervision */}
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Use of Supervision{' '}
                </h5>
              }
            >
              <Row>
                <Col xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please rate your supervisee using the following
                      scale:
                    </p>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11}>
                        Student committed a critical error (e.g. violated APA Code of Ethics)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11}>Beginner</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11}>
                        Requires substantial supervision (expected by the end of internal practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11}>
                        Requires moderate supervision (expected by the end of first year of external
                        practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11}>
                        Requires minimal supervision (expected by the end of pre-intern year or
                        second year of external practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11}>
                        Competent for Independent Practice (expected by the end of internship)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>6</strong>
                      </Col>
                      <Col sm={11}>Proficient</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>7</strong>
                      </Col>
                      <Col sm={11}>Expert</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11}>Not applicable</Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Use of Supervision - Q36 */}
              <Row className="mt-4">
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q36</b>
                    </Label>{' '}
                    Able to describe and engage in self-reflection about their theoretical
                    orientation:{' '}
                    <b className="ml-2">
                      {formData[6].data[0].value === '' || formData[6].data[0].value === -1
                        ? formData[6].data[0].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[6].data[0].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Use of Supervision - Q36 */}
              {/* Use of Supervision - Q37 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q37</b>
                    </Label>{' '}
                    Appropriately uses and learns from supervision:{' '}
                    <b className="ml-2">
                      {formData[6].data[1].value === '' || formData[6].data[1].value === -1
                        ? formData[6].data[1].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[6].data[1].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Use of Supervision - Q37 */}
            </Widget>
            {/* // Use of Supervision */}
            {/* Comments and Global Rating */}
            <Widget
              title={
                <h5>
                  {' '}
                  <i className="fa fa-align-left" /> Comments and Global Rating{' '}
                </h5>
              }
            >
              <Row>
                <Col xs={12}>
                  <Alert color="primary">
                    <p>
                      For the next set of questions, please rate your supervisee using the following
                      scale:
                    </p>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>0</strong>
                      </Col>
                      <Col sm={11}>
                        Student committed a critical error (e.g. violated APA Code of Ethics)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11}>Beginner</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11}>
                        Requires substantial supervision (expected by the end of internal practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11}>
                        Requires moderate supervision (expected by the end of first year of external
                        practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11}>
                        Requires minimal supervision (expected by the end of pre-intern year or
                        second year of external practicum)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11}>
                        Competent for Independent Practice (expected by the end of internship)
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>6</strong>
                      </Col>
                      <Col sm={11}>Proficient</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>7</strong>
                      </Col>
                      <Col sm={11}>Expert</Col>
                    </Row>
                    <Row>
                      <Col sm={1} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11}>Not applicable</Col>
                    </Row>
                  </Alert>
                </Col>
              </Row>
              {/* Comments and Global Rating - Q38 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <u>
                        <b>Q38</b> Other comments and observations
                      </u>
                    </Label>
                    <br />
                    {otherComments}
                  </p>
                </Col>
              </Row>
              {/* // Comments and Global Rating - Q38 */}
              {/* Comments and Global Rating - Q39 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <u>
                        <b>Q39</b> Explicit recommendations for future practicum training
                      </u>
                    </Label>
                    <br />
                    {practicumRecommendations}
                  </p>
                </Col>
              </Row>
              {/* // Comments and Global Rating - Q39 */}
              {/* Comments and Global Rating - Q40 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <u>
                        <b>Q40</b> Student's main strengths and assets
                      </u>
                    </Label>
                    <br />
                    {mainStrengths}
                  </p>
                </Col>
              </Row>
              {/* // Comments and Global Rating - Q40 */}
              {/* Comments and Global Rating - Q41 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <u>
                        <b>Q41</b> Areas needing further development
                      </u>
                    </Label>
                    <br />
                    {areasOfImprovement}
                  </p>
                </Col>
              </Row>
              {/* // Comments and Global Rating - Q41 */}
              {/* Comments and Global Rating - Q42 */}
              <Row>
                <Col className="text-center">
                  <p className="readout">
                    <Label>
                      <b>Q42</b>
                    </Label>{' '}
                    Global rating of current clinical performance:{' '}
                    <b className="ml-2">
                      {formData[7].data[4].value === '' || formData[7].data[4].value === -1
                        ? formData[7].data[4].value === ''
                          ? 'Unrated'
                          : 'N/A'
                        : formData[7].data[4].value}
                    </b>
                  </p>
                </Col>
              </Row>
              {/* // Comments and Global Rating - Q42 */}
            </Widget>
            {/* // Comments and Global Rating */}
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, error, studentName, term, academicYear } = this.state;

    /* render readout */
    if (!loading && !error) {
      return (
        <div>
          <Row>
            <Col xs={{ size: 10, offset: 1 }}>
              <Row>
                <Col xs="12">
                  <div className="crumbs">
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link to="/profile">Profile</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Semi-Annual Clinical Evaluation</BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </Col>
              </Row>
              <h2 className="page-title">
                Semi-Annual Clinical Evaluation: {studentName} -{' '}
                {term.charAt(0).toUpperCase() + term.slice(1)} {academicYear}-{academicYear + 1}
              </h2>
              {this.renderReadout()}
            </Col>
          </Row>
        </div>
      );
    }
    /* render error */
    if (error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Widget>
                <Row className="mt-4 mb-4 text-center">
                  <Col>
                    <h4>
                      There was an error loading the form, please reload the page and try again.
                    </h4>
                  </Col>
                </Row>
              </Widget>
            </Col>
          </Row>
        </div>
      );
    }
    /* render loading as default */
    return (
      <div>
        <Row className="mt-4">
          <Col xs={{ size: 10, offset: 1 }}>
            <h2 className="page-title">Semi-Annual Clinical Evaluation</h2>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
          </Col>
        </Row>
      </div>
    );
  }
}

saceStudentReadout.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(saceStudentReadout);
