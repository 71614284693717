import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import auth0Client from './Auth/Auth';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './components/Home/Home';
import Callback from './components/Callback/Callback';
import SecuredRoute from './components/SecuredRoute/SecuredRoute';
import Account from './components/Account/Account';
import ASEStudentReadout from './components/Forms/aseStudentReadout/ASEStudentReadout';
import saceStudentReadout from './components/Forms/saceStudentReadout/saceStudentReadout';
import saceStudentReadoutV2 from './components/Forms/saceStudentReadoutV2/saceStudentReadoutV2';
import PSE from './components/Forms/pse/PSE';
import SPR from './components/Forms/spr/SPR';
import SSS from './components/Forms/sss/SSS';
import AS from './components/Forms/as/AS';

import './App.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkingSession: true,
    };
  }

  async componentDidMount() {
    const { location } = this.props;
    if (location.pathname === '/callback') {
      this.setState({ checkingSession: false });
      return;
    }
    try {
      await auth0Client.silentAuth();
      this.forceUpdate();
    } catch (err) {
      // eslint-disable-next-line no-console
      if (err.error !== 'login_required') console.error(err.error);
    }
    this.setState({ checkingSession: false });
  }

  render() {
    const { checkingSession } = this.state;
    return (
      <div className="main-container">
        <Header />
        <div>
          <main className="content">
            <Route exact path="/" component={Home} />
            <Route exact path="/callback" component={Callback} />
            <SecuredRoute
              exact
              path="/profile"
              component={Account}
              checkingSession={checkingSession}
            />
            <SecuredRoute
              exact
              path="/form/ASE/:formid"
              component={ASEStudentReadout}
              checkingSession={checkingSession}
            />
            <SecuredRoute
              exact
              path="/form/SACE/:formid"
              component={saceStudentReadout}
              checkingSession={checkingSession}
            />
            <SecuredRoute
              exact
              path="/form/SACEv2/:formid"
              component={saceStudentReadoutV2}
              checkingSession={checkingSession}
            />
            <SecuredRoute
              exact
              path="/form/PSE/:formid"
              component={PSE}
              checkingSession={checkingSession}
            />
            <SecuredRoute
              exact
              path="/form/SPR/:formid"
              component={SPR}
              checkingSession={checkingSession}
            />
            <SecuredRoute
              exact
              path="/form/SSS/:formid"
              component={SSS}
              checkingSession={checkingSession}
            />
            <SecuredRoute
              exact
              path="/form/AS/:formid"
              component={AS}
              checkingSession={checkingSession}
            />
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}

App.propTypes = {
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(App);
