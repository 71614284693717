/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Progress,
  Alert,
  Breadcrumb,
  BreadcrumbItem,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../../Auth/Auth';

import SurveyButtonGroup from '../../SurveyButtonGroup/SurveyButtonGroup';
import Widget from '../../Widget/Widget';

import '../forms.scss';

import loadingSpinner from '../../../images/Eclipse-1s-200px.svg';

class SSS extends React.Component {
  constructor(props) {
    super(props);
    this.handleTextInputChange = this.handleTextInputChange.bind(this);
    this.handleNumberInputChange = this.handleNumberInputChange.bind(this);
    this.onFrmBtnClick = this.onFrmBtnClick.bind(this);
    this.onFrmBtnToggle = this.onFrmBtnToggle.bind(this);
    this.onSectionBtnClick = this.onSectionBtnClick.bind(this);
    this.getForm = this.getForm.bind(this);
    this.saveForm = this.saveForm.bind(this);
    this.renderTraining = this.renderTraining.bind(this);
    this.renderProgramExperience = this.renderProgramExperience.bind(this);
    this.renderFacultyExperience = this.renderFacultyExperience.bind(this);
    this.renderStudentExperience = this.renderStudentExperience.bind(this);

    this.state = {
      formData: [
        {
          id: 'Training',
          data: [
            {
              id: 'substanceAbuseTreatment',
              value: -1,
            },
            {
              id: 'integratedBehavioralHealth',
              value: -1,
            },
            {
              id: 'healthEquity',
              value: -1,
            },
            {
              id: 'resilienceTraining',
              value: -1,
            },
            {
              id: 'maternalHealth',
              value: -1,
            },
            {
              id: 'COVID',
              value: -1,
            },
            {
              id: 'MAT',
              value: -1,
            },
            {
              id: 'telehealthTraining',
              value: '',
            },
            {
              id: 'primaryCareTraining',
              value: '',
            },
            {
              id: 'medicallyUnderservedTraining',
              value: '',
            },
            {
              id: 'ruralHealthTraining',
              value: '',
            },
          ],
        },
        {
          id: 'Program Experience',
          data: [
            {
              id: 'q3',
              value: '',
            },
            {
              id: 'q4',
              value: '',
            },
            {
              id: 'q5',
              value: '',
            },
            {
              id: 'q6',
              value: '',
            },
            {
              id: 'q7',
              value: '',
            },
            {
              id: 'q8',
              value: '',
            },
            {
              id: 'q9',
              value: '',
            },
            {
              id: 'q10',
              value: '',
            },
            {
              id: 'q11',
              value: '',
            },
            {
              id: 'q12',
              value: '',
            },
            {
              id: 'q13',
              value: '',
            },
            {
              id: 'q14',
              value: '',
            },
            {
              id: 'q15',
              value: '',
            },
            {
              id: 'q16',
              value: '',
            },
            {
              id: 'q17',
              value: '',
            },
            {
              id: 'q18',
              value: '',
            },
          ],
        },
        {
          id: 'Faculty Experience',
          data: [
            {
              id: 'q19',
              value: '',
            },
            {
              id: 'q20',
              value: '',
            },
            {
              id: 'q21',
              value: '',
            },
            {
              id: 'q22',
              value: '',
            },
            {
              id: 'q23',
              value: '',
            },
            {
              id: 'q24',
              value: '',
            },
            {
              id: 'q25',
              value: '',
            },
            {
              id: 'q26',
              value: '',
            },
            {
              id: 'q27',
              value: '',
            },
            {
              id: 'q28',
              value: '',
            },
          ],
        },
        {
          id: 'Student Experience',
          data: [
            {
              id: 'q29',
              value: '',
            },
            {
              id: 'q30',
              value: '',
            },
            {
              id: 'q31',
              value: '',
            },
            {
              id: 'q32',
              value: '',
            },
            {
              id: 'q33',
              value: '',
            },
            {
              id: 'q34',
              value: '',
            },
            {
              id: 'q35',
              value: '',
            },
            {
              id: 'q36',
              value: '',
            },
            {
              id: 'q37',
              value: '',
            },
            {
              id: 'q38',
              value: '',
            },
            {
              id: 'q39',
              value: '',
            },
          ],
        },
      ],
      telehealthTraining: 0,
      primaryCareTraining: 0,
      medicallyUnderservedTraining: 0,
      ruralHealthTraining: 0,
      programStrengths: '',
      programWeaknesses: '',
      programSuggestions: '',
      studentName: '',
      program: '',
      year: '',
      currentSection: 0,
      loading: true,
      error: false,
    };
  }

  componentDidMount() {
    this.getForm();
  }

  handleTextInputChange(e) {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  handleNumberInputChange(e) {
    const { target } = e;
    const { name, value } = target;

    // only allow numbers 0 through 9 and backspace
    if (target.validity.valid) {
      this.setState({ [name]: value });
    } else if (value === '') {
      this.setState({ [name]: value });
    }
  }

  onFrmBtnClick(sectionID, questionID, answer) {
    const newState = {
      ...this.state.formData,
    };

    newState[sectionID].data[questionID].value = answer;

    this.setState({
      formData: newState,
    });
  }

  onFrmBtnToggle(e, sectionID, questionID) {
    e.preventDefault();
    e.target.blur();

    const newState = {
      ...this.state.formData,
    };

    newState[sectionID].data[questionID].value *= -1;

    this.setState({
      formData: newState,
    });
  }

  onSectionBtnClick(e, sectionValue) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const { currentSection } = this.state;
    const newSectionID = currentSection + sectionValue;

    this.setState({
      currentSection: newSectionID,
    });
  }

  getForm() {
    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/form/${params.formid}`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        // check if data is valid
        const { data, status, student, studentYear, studentProgram } = response.data;
        const studentName = `${student.firstName} ${student.lastName}`;

        if (status !== 'new') {
          let {
            formData,
            telehealthTraining,
            primaryCareTraining,
            medicallyUnderservedTraining,
            ruralHealthTraining,
            programStrengths,
            programWeaknesses,
            programSuggestions,
            currentSection,
          } = this.state;

          // check if data was returned in a array
          if (data.length === 4) {
            formData = data;
          } else {
            // eslint-disable-next-line prefer-destructuring
            formData = data[0];
          }

          // * Training
          if (formData[0].data[7].value !== '') {
            telehealthTraining = formData[0].data[7].value;
          }
          if (formData[0].data[8].value !== '') {
            primaryCareTraining = formData[0].data[8].value;
          }
          if (formData[0].data[9].value !== '') {
            medicallyUnderservedTraining = formData[0].data[9].value;
          }
          if (formData[0].data[10].value !== '') {
            ruralHealthTraining = formData[0].data[10].value;
          }

          // * Program Feedback
          programStrengths = formData[3].data[8].value;
          programWeaknesses = formData[3].data[9].value;
          programSuggestions = formData[3].data[10].value;

          // if form is cmmpleted, set currentSection to display readout
          if (status === 'completed') {
            currentSection = 999;
          }

          // form inProgress/completed, set student info and formData
          this.setState({
            formData,
            telehealthTraining,
            primaryCareTraining,
            medicallyUnderservedTraining,
            ruralHealthTraining,
            programStrengths,
            programWeaknesses,
            programSuggestions,
            studentName,
            year: studentYear,
            program: studentProgram,
            currentSection,
            loading: false,
          });
        } else {
          // new form, set student info
          this.setState({
            studentName,
            year: studentYear,
            program: studentProgram,
            loading: false,
          });
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  saveForm(e, newStatus) {
    e.preventDefault();
    e.target.blur();
    window.scrollTo(0, 0);

    const {
      match: { params },
    } = this.props;
    const url = `${process.env.REACT_APP_API_URL}/form/update`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    const id = params.formid;
    const {
      formData,
      telehealthTraining,
      primaryCareTraining,
      medicallyUnderservedTraining,
      ruralHealthTraining,
      programStrengths,
      programWeaknesses,
      programSuggestions,
    } = this.state;

    // * Training
    // set qs from inputs
    formData[0].data[7].value = telehealthTraining;
    formData[0].data[8].value = primaryCareTraining;
    formData[0].data[9].value = medicallyUnderservedTraining;
    formData[0].data[10].value = ruralHealthTraining;

    // * Program Feedback
    // set qs from inputs
    formData[3].data[8].value = programStrengths;
    formData[3].data[9].value = programWeaknesses;
    formData[3].data[10].value = programSuggestions;

    axios
      .post(url, { id, newStatus, formData }, config)
      .then((response) => {
        // update form status if results are valid
        const { status } = response.data;
        if (status === 'completed') {
          // eslint-disable-next-line react/prop-types, react/destructuring-assignment
          this.props.history.push('/profile');
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  renderTraining() {
    const {
      formData,
      telehealthTraining,
      primaryCareTraining,
      medicallyUnderservedTraining,
      ruralHealthTraining,
    } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Training
                </h5>
              }
            >
              <FormGroup>
                <Form>
                  {/* Training - Q1 */}
                  <Row className="mt-4">
                    <Col sm={12} className="text-center">
                      <Label>
                        <b>Q1</b> Select any HHS Priority Topic Area on which you&apos;ve received
                        training in the past year: (Select <span className="text-danger">ALL</span>{' '}
                        that apply)
                      </Label>
                      <br />
                      <Button
                        className="btn-form"
                        id="training-b-substanceAbuseTreatment"
                        color={formData[0].data[0].value === 1 ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnToggle(e, 0, 0)}
                        active={formData[0].data[0].value === 1}
                      >
                        Substance abuse treatment
                      </Button>
                      <Button
                        className="btn-form"
                        id="training-b-integratedBehavioralHealth"
                        color={formData[0].data[1].value === 1 ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnToggle(e, 0, 1)}
                        active={formData[0].data[1].value === 1}
                      >
                        Integrated behavioral health in primary care
                      </Button>
                      <Button
                        className="btn-form"
                        id="training-b-healthEquity"
                        color={formData[0].data[2].value === 1 ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnToggle(e, 0, 2)}
                        active={formData[0].data[2].value === 1}
                      >
                        Health equity/social determinants of health
                      </Button>
                      <Button
                        className="btn-form"
                        id="training-b-resilienceTraining"
                        color={formData[0].data[3].value === 1 ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnToggle(e, 0, 3)}
                        active={formData[0].data[3].value === 1}
                      >
                        Resilience training
                      </Button>
                      <Button
                        className="btn-form"
                        id="training-b-maternalHealth"
                        color={formData[0].data[4].value === 1 ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnToggle(e, 0, 4)}
                        active={formData[0].data[4].value === 1}
                      >
                        Maternal health
                      </Button>
                      <Button
                        className="btn-form"
                        id="training-b-COVID"
                        color={formData[0].data[5].value === 1 ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnToggle(e, 0, 5)}
                        active={formData[0].data[5].value === 1}
                      >
                        COVID-19 training
                      </Button>
                      <Button
                        className="btn-form"
                        id="training-b-MAT"
                        color={formData[0].data[6].value === 1 ? 'dark' : 'inverse'}
                        onClick={(e) => this.onFrmBtnToggle(e, 0, 6)}
                        active={formData[0].data[6].value === 1}
                      >
                        MAT for SUD/OUD
                      </Button>
                    </Col>
                  </Row>
                  {/* // Training - Q1 */}
                  {/* Training - Q2 */}
                  <FormGroup>
                    <Row className="mt-4">
                      <Col sm={12} className="text-center">
                        <Label>
                          <b>Q2</b> Provide the number of contact hours (i.e., face-to-face hours)
                          you&apos;ve received training in the past year in the following areas
                        </Label>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup row>
                          <Label xs={7} className="text-right label-sm">
                            Telehealth
                          </Label>
                          <Col xs={3}>
                            <Input
                              type="tel"
                              id="t-telehealthTraining"
                              name="telehealthTraining"
                              value={telehealthTraining}
                              onChange={this.handleNumberInputChange}
                              pattern="[0-9]+"
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup row>
                          <Label xs={7} className="text-right label-sm">
                            Primary Care Setting
                          </Label>
                          <Col xs={3}>
                            <Input
                              type="tel"
                              id="t-primaryCareTraining"
                              name="primaryCareTraining"
                              value={primaryCareTraining}
                              onChange={this.handleNumberInputChange}
                              pattern="[0-9]+"
                            />
                          </Col>
                          <Col md={2} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <FormGroup row>
                          <Label xs={7} className="text-right label-sm">
                            Medically Underserved Community
                          </Label>
                          <Col xs={3}>
                            <Input
                              type="tel"
                              id="t-medicallyUnderservedTraining"
                              name="medicallyUnderservedTraining"
                              value={medicallyUnderservedTraining}
                              onChange={this.handleNumberInputChange}
                              pattern="[0-9]+"
                            />
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup row>
                          <Label xs={7} className="text-right label-sm">
                            Rural Health
                          </Label>
                          <Col xs={3}>
                            <Input
                              type="tel"
                              id="t-ruralHealthTraining"
                              name="ruralHealthTraining"
                              value={ruralHealthTraining}
                              onChange={this.handleNumberInputChange}
                              pattern="[0-9]+"
                            />
                          </Col>
                          <Col md={2} />
                        </FormGroup>
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* // Training - Q2 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col md={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s0-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s0-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderProgramExperience() {
    const { formData, program, year } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Program Experience
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>For the next set of questions, please use the following guidelines:</p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Strongly Disagree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Disagree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Neutral
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Agree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Strongly Agree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Not applicable or not enough information to form a judgement
                      </Col>
                    </Row>
                    <p className="mt-4">
                      <strong className="text-danger">
                        All information reported on this form will be submitted anonymously and is
                        not tied to any identifiable student information. Data will only be reported
                        in aggregate.
                      </strong>
                    </p>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Program Experience - Q3 */}
                  <SurveyButtonGroup
                    qNumber="Q3"
                    qText="Department courses emphasizing treatment (e.g., EBPP I -IV) have
                          enhanced my knowledge and skills."
                    value={formData[1].data[0].value}
                    formDataID={1}
                    dataID={0}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q3 */}
                  {/* Program Experience - Q4 */}
                  <SurveyButtonGroup
                    qNumber="Q4"
                    qText="Department courses emphasizing assessment (e.g.,
                          Cognitive/Intellectual, Neuropsychological, and Objective Personality
                          Assessment) have enhanced my knowledge and skills."
                    value={formData[1].data[1].value}
                    formDataID={1}
                    dataID={1}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q4 */}
                  {/* Program Experience - Q5 */}
                  <SurveyButtonGroup
                    qNumber="Q5"
                    qText="The elective religion classes have enhanced my understanding of
                          the relationship between spirituality and psychology."
                    value={formData[1].data[2].value}
                    formDataID={1}
                    dataID={2}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q5 */}
                  {/* Program Experience - Q6 */}
                  <SurveyButtonGroup
                    qNumber="Q6"
                    qText="I have acquired a broad knowledge base of psychological science
                          in this program."
                    value={formData[1].data[3].value}
                    formDataID={1}
                    dataID={3}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q6 */}
                  {/* Program Experience - Q7 */}
                  <SurveyButtonGroup
                    qNumber="Q7"
                    qText="The psychological science foundation courses (e.g.,
                          Psychobiology, History &amp; Systems, Cognitive, Developmental, Social)
                          have enhanced my understanding of the integration of basic psychological
                          science and clinical practice."
                    value={formData[1].data[4].value}
                    formDataID={1}
                    dataID={4}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q7 */}
                  {/* Program Experience - Q8 */}
                  <SurveyButtonGroup
                    qNumber="Q8"
                    qText="Overall, the coursework in the program is at a good level of
                          academic challenge."
                    value={formData[1].data[5].value}
                    formDataID={1}
                    dataID={5}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q8 */}
                  {/* Program Experience - Q9 */}
                  <SurveyButtonGroup
                    qNumber="Q9"
                    qText="My internal practicum experiences have enhanced my clinical
                          abilities."
                    value={formData[1].data[6].value}
                    formDataID={1}
                    dataID={6}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q9 */}
                  {/* Program Experience - Q10 */}
                  <SurveyButtonGroup
                    qNumber="Q10"
                    qText="My external practicum experiences have enhanced my clinical
                          abilities."
                    value={formData[1].data[7].value}
                    formDataID={1}
                    dataID={7}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q10 */}
                  {/* Program Experience - Q11 */}
                  <SurveyButtonGroup
                    qNumber="Q11"
                    qText="My external pre-internship experiences have enhanced my
                          clinical abilities."
                    value={formData[1].data[8].value}
                    formDataID={1}
                    dataID={8}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q11 */}
                  {/* Program Experience - Q12 */}
                  {((program === 'Psy.D.' && year >= 4) || (program === 'Ph.D.' && year >= 5)) && (
                    <SurveyButtonGroup
                      qNumber="Q12"
                      qText="The internship preparation seminar for the internship application process
                          was valuable."
                      value={formData[1].data[9].value}
                      formDataID={1}
                      dataID={9}
                      min={1}
                      max={5}
                      showNA
                      onClickFunction={this.onFrmBtnClick}
                    />
                  )}
                  {((program === 'Psy.D.' && year < 4) || (program === 'Ph.D.' && year < 5)) && (
                    <div>
                      <Row className="justify-content-center mt-4">
                        <Col sm={10} className="text-center">
                          <Label>
                            <b>Q12 is for 4th year Psy.D. & 5th year Ph.D. or higher</b>
                          </Label>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {/* // Program Experience - Q12 */}
                  {/* Program Experience - Q13 */}
                  {((program === 'Psy.D.' && year >= 4) || (program === 'Ph.D.' && year >= 5)) && (
                    <SurveyButtonGroup
                      qNumber="Q13"
                      qText="The internship preparation seminar for internship interviews was valuable."
                      value={formData[1].data[10].value}
                      formDataID={1}
                      dataID={10}
                      min={1}
                      max={5}
                      showNA
                      onClickFunction={this.onFrmBtnClick}
                    />
                  )}
                  {((program === 'Psy.D.' && year < 4) || (program === 'Ph.D.' && year < 5)) && (
                    <div>
                      <Row className="justify-content-center mt-4">
                        <Col sm={10} className="text-center">
                          <Label>
                            <b>Q13 is for 4th year Psy.D. & 5th year Ph.D. or higher</b>
                          </Label>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {/* // Program Experience - Q13 */}
                  {/* Program Experience - Q14 */}
                  <SurveyButtonGroup
                    qNumber="Q14"
                    qText="I have gained self-awareness and an understanding of its
                          relevance for clinical work."
                    value={formData[1].data[11].value}
                    formDataID={1}
                    dataID={11}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q14 */}
                  {/* Program Experience - Q15 */}
                  <SurveyButtonGroup
                    qNumber="Q15"
                    qText="The quantitative research courses (e.g., Advanced Statistics I,
                          Psychometric Foundations, etc.) enhanced my research skills."
                    value={formData[1].data[12].value}
                    formDataID={1}
                    dataID={12}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q15 */}
                  {/* Program Experience - Q16 */}
                  {program === 'Ph.D.' && (
                    <SurveyButtonGroup
                      qNumber="Q16"
                      qText="The quantitative course series and research methods course enhanced my
                          research skills."
                      value={formData[1].data[13].value}
                      formDataID={1}
                      dataID={13}
                      min={1}
                      max={5}
                      showNA
                      onClickFunction={this.onFrmBtnClick}
                    />
                  )}
                  {program === 'Psy.D.' && (
                    <div>
                      <Row className="justify-content-center mt-4">
                        <Col sm={10} className="text-center">
                          <Label>
                            <b>Q16 is Ph.D. only</b>
                          </Label>
                        </Col>
                      </Row>
                    </div>
                  )}
                  {/* // Program Experience - Q16 */}
                  {/* Program Experience - Q17 */}
                  <SurveyButtonGroup
                    qNumber="Q17"
                    qText="I am able to read and critically evaluate the psychology research
                          literature."
                    value={formData[1].data[14].value}
                    formDataID={1}
                    dataID={14}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Program Experience - Q17 */}
                  {/* Program Experience - Q18 */}
                  <SurveyButtonGroup
                    qNumber="Q18"
                    qText="There is sufficient opportunity for training in health psychology for our
                          doctoral clinical psychology students."
                    value={formData[1].data[15].value}
                    formDataID={1}
                    dataID={15}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  <FormGroup row className="submitBlock mt-4">
                    <Col md={12} className="text-center">
                      <Button
                        className="ml-xs"
                        id="s1-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s1-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderFacultyExperience() {
    const { formData } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Faculty Experience
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>For the next set of questions, please use the following guidelines:</p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Strongly Disagree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Disagree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Neutral
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Agree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Strongly Agree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Not applicable or not enough information to form a judgement
                      </Col>
                    </Row>
                    <p className="mt-4">
                      <strong className="text-danger">
                        All information reported on this form will be submitted anonymously and is
                        not tied to any identifiable student information. Data will only be reported
                        in aggregate.
                      </strong>
                    </p>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Faculty Experience - Q19 */}
                  <SurveyButtonGroup
                    qNumber="Q19"
                    qText="The overall quality of teaching in the program is good."
                    value={formData[2].data[0].value}
                    formDataID={2}
                    dataID={0}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Faculty Experience - Q19 */}
                  {/* Faculty Experience - Q20 */}
                  <SurveyButtonGroup
                    qNumber="Q20"
                    qText="The faculty cares for the students and their professional growth."
                    value={formData[2].data[1].value}
                    formDataID={2}
                    dataID={1}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Faculty Experience - Q20 */}
                  {/* Faculty Experience - Q21 */}
                  <SurveyButtonGroup
                    qNumber="Q21"
                    qText="The interaction within my cohort is positive."
                    value={formData[2].data[2].value}
                    formDataID={2}
                    dataID={2}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Faculty Experience - Q21 */}
                  {/* Faculty Experience - Q22 */}
                  <SurveyButtonGroup
                    qNumber="Q22"
                    qText="The interaction between cohorts is positive."
                    value={formData[2].data[3].value}
                    formDataID={2}
                    dataID={3}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Faculty Experience - Q22 */}
                  {/* Faculty Experience - Q23 */}
                  <SurveyButtonGroup
                    qNumber="Q23"
                    qText="I like the health sciences university setting of the program."
                    value={formData[2].data[4].value}
                    formDataID={2}
                    dataID={4}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Faculty Experience - Q23 */}
                  {/* Faculty Experience - Q24 */}
                  <SurveyButtonGroup
                    qNumber="Q24"
                    qText="Compared to students from other programs at clinical training sites, I am
                          well-prepared academically."
                    value={formData[2].data[5].value}
                    formDataID={2}
                    dataID={5}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Faculty Experience - Q24 */}
                  {/* Faculty Experience - Q25 */}
                  <SurveyButtonGroup
                    qNumber="Q25"
                    qText="Compared to students from other programs at clinical training sites, I am
                          well-prepared clinically."
                    value={formData[2].data[6].value}
                    formDataID={2}
                    dataID={6}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Faculty Experience - Q25 */}
                  {/* Faculty Experience - Q26 */}
                  <SurveyButtonGroup
                    qNumber="Q26"
                    qText="Compared to non-LLU practicum students, I am well prepared regarding
                          professional values, ethics, and matters of integrity."
                    value={formData[2].data[7].value}
                    formDataID={2}
                    dataID={7}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Faculty Experience - Q26 */}
                  {/* Faculty Experience - Q27 */}
                  <SurveyButtonGroup
                    qNumber="Q27"
                    qText="Scheduling appointments with program faculty can be achieved with
                          reasonable ease."
                    value={formData[2].data[8].value}
                    formDataID={2}
                    dataID={8}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Faculty Experience - Q27 */}
                  {/* Faculty Experience - Q28 */}
                  <SurveyButtonGroup
                    qNumber="Q28"
                    qText="The program promotes growth in assuming a professional role as a
                          psychologist."
                    value={formData[2].data[9].value}
                    formDataID={2}
                    dataID={9}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Faculty Experience - Q28 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="s2-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s2-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s2-b-cont"
                        color="info"
                        onClick={(e) => this.onSectionBtnClick(e, 1)}
                      >
                        Continue
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderStudentExperience() {
    const { formData, programStrengths, programWeaknesses, programSuggestions } = this.state;

    return (
      <div>
        <Row>
          <Col>
            <Widget
              title={
                <h5>
                  <i className="fa fa-align-left" /> Student Experience
                </h5>
              }
            >
              <Row className="mt-4">
                <Col lg={2} />
                <Col lg={8} xs={12}>
                  <Alert color="primary">
                    <p>For the next set of questions, please use the following guidelines:</p>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>1</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Strongly Disagree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>2</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Disagree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>3</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Neutral
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>4</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Agree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>5</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Strongly Agree
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={1} xs={2} className="text-center">
                        <strong>N/A</strong>
                      </Col>
                      <Col sm={11} xs={9}>
                        Not applicable or not enough information to form a judgement
                      </Col>
                    </Row>
                    <p className="mt-4">
                      <strong className="text-danger">
                        All information reported on this form will be submitted anonymously and is
                        not tied to any identifiable student information. Data will only be reported
                        in aggregate.
                      </strong>
                    </p>
                  </Alert>
                </Col>
              </Row>
              <FormGroup className="mt-5">
                <Form>
                  {/* Student Experience - Q29 */}
                  <SurveyButtonGroup
                    qNumber="Q29"
                    qText="Student-faculty relationships are good."
                    value={formData[3].data[0].value}
                    formDataID={3}
                    dataID={0}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Student Experience - Q29 */}
                  {/* Student Experience - Q30 */}
                  <SurveyButtonGroup
                    qNumber="Q30"
                    qText="Faculty members serve as good professional role models."
                    value={formData[3].data[1].value}
                    formDataID={3}
                    dataID={1}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Student Experience - Q30 */}
                  {/* Student Experience - Q31 */}
                  <SurveyButtonGroup
                    qNumber="Q31"
                    qText="Respect for cultural and individual diversity is shown in the program."
                    value={formData[3].data[2].value}
                    formDataID={3}
                    dataID={2}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Student Experience - Q31 */}
                  {/* Student Experience - Q32 */}
                  <SurveyButtonGroup
                    qNumber="Q32"
                    qText="Quality mentoring occurs between students and faculty."
                    value={formData[3].data[3].value}
                    formDataID={3}
                    dataID={3}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Student Experience - Q32 */}
                  {/* Student Experience - Q33 */}
                  <SurveyButtonGroup
                    qNumber="Q33"
                    qText="I can clinically deal effectively with clients holding a different
                          religious or moral worldview than mine."
                    value={formData[3].data[4].value}
                    formDataID={3}
                    dataID={4}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Student Experience - Q33 */}
                  {/* Student Experience - Q34 */}
                  <SurveyButtonGroup
                    qNumber="Q34"
                    qText="Policies and procedures are fair and equitably administered across
                          students."
                    value={formData[3].data[5].value}
                    formDataID={3}
                    dataID={5}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Student Experience - Q34 */}
                  {/* Student Experience - Q35 */}
                  <SurveyButtonGroup
                    qNumber="Q35"
                    qText="My expectations at the time of entering the program have been met."
                    value={formData[3].data[6].value}
                    formDataID={3}
                    dataID={6}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Student Experience - Q35 */}
                  {/* Student Experience - Q36 */}
                  <SurveyButtonGroup
                    qNumber="Q36"
                    qText="Knowing what I know now, I would choose to enroll in the LLU Clinical
                          Psychology program if I had to do it over."
                    value={formData[3].data[7].value}
                    formDataID={3}
                    dataID={7}
                    min={1}
                    max={5}
                    showNA
                    onClickFunction={this.onFrmBtnClick}
                  />
                  {/* // Student Experience - Q36 */}
                  {/* Student Experience - Q35 */}
                  <FormGroup className="mt-4">
                    <Row>
                      <Col className="text-center">
                        <Label>
                          <b>Q37</b> What are the real strengths of the program? Please type NA if
                          you do not wish to answer.
                        </Label>
                        <br />
                        <Input
                          style={{
                            width: '75%',
                            display: 'block',
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                          }}
                          id="programStrengths"
                          rows="4"
                          type="textarea"
                          name="programStrengths"
                          value={programStrengths}
                          onChange={this.handleTextInputChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* // Student Experience - Q35 */}
                  {/* Student Experience - Q36 */}
                  <FormGroup className="mt-4">
                    <Row>
                      <Col className="text-center justify-content-center">
                        <Label>
                          <b>Q38</b> What are the primary weaknesses of the program (be as specific
                          as possible)? Please type NA if you do not wish to answer.
                        </Label>
                        <Input
                          style={{
                            width: '75%',
                            display: 'block',
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                          }}
                          id="programWeaknesses"
                          rows="4"
                          type="textarea"
                          name="programWeaknesses"
                          value={programWeaknesses}
                          onChange={this.handleTextInputChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* // Student Experience - Q36 */}
                  {/* Student Experience - Q37 */}
                  <FormGroup className="mt-4">
                    <Row>
                      <Col className="text-center">
                        <Label>
                          <b>Q39</b> Do you have any suggestions for how to improve these
                          weaknesses? Please type NA if you do not wish to answer.
                        </Label>
                        <br />
                        <Input
                          style={{
                            width: '75%',
                            display: 'block',
                            'margin-left': 'auto',
                            'margin-right': 'auto',
                          }}
                          id="programSuggestions"
                          rows="4"
                          type="textarea"
                          name="programSuggestions"
                          value={programSuggestions}
                          onChange={this.handleTextInputChange}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                  {/* // Student Experience - Q37 */}
                  <FormGroup row className="submitBlock mt-4">
                    <Col className="text-center">
                      <Button
                        className="ml-xs"
                        id="s3-b-pre"
                        color="inverse"
                        onClick={(e) => this.onSectionBtnClick(e, -1)}
                      >
                        Previous
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s3-b-save"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'inProgress')}
                      >
                        Save
                      </Button>
                      <Button
                        className="ml-xs"
                        id="s3-b-submit"
                        color="info"
                        onClick={(e) => this.saveForm(e, 'completed')}
                      >
                        Submit
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </FormGroup>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading, error, currentSection, studentName } = this.state;

    if (!loading && !error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Row>
                <Col xs="12">
                  <div className="crumbs">
                    <Breadcrumb>
                      <BreadcrumbItem>
                        <Link to="/profile">Profile</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>Student Satisfaction Survey</BreadcrumbItem>
                    </Breadcrumb>
                  </div>
                </Col>
              </Row>
              <h2 className="page-title">
                Student Satisfaction Survey: <small>{studentName}</small>
              </h2>
              {currentSection === 0 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={0} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 0 && this.renderTraining()}
              {currentSection === 1 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={5} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 1 && this.renderProgramExperience()}
              {currentSection === 2 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={46} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 2 && this.renderFacultyExperience()}
              {currentSection === 3 && (
                <Row>
                  <Col xs="12">
                    <div>
                      <div className="progress-title">
                        <strong>Progress</strong>
                      </div>
                      <Progress color="info" value={74} />
                    </div>
                  </Col>
                </Row>
              )}
              {currentSection === 3 && this.renderStudentExperience()}
              {/* {currentSection === 999 && this.renderReadout()} */}
            </Col>
          </Row>
        </div>
      );
    }
    if (loading) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <h2 className="page-title">Student Satisfaction Survey</h2>
            </Col>
          </Row>
          <Row className="text-center">
            <Col>
              <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
            </Col>
          </Row>
        </div>
      );
    }
    if (error) {
      return (
        <div>
          <Row className="mt-4">
            <Col xs={{ size: 10, offset: 1 }}>
              <Widget>
                <Row className="mt-4 mb-4 text-center">
                  <Col>
                    <h4>
                      There was an error loading the form, please reload the page and try again.
                    </h4>
                  </Col>
                </Row>
              </Widget>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

SSS.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default withRouter(SSS);
