/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["renderLoading"] }] */

import React from 'react';
import { Link } from 'react-router-dom';
import { Table, Row, Col, Collapse, CardBody, Card, Button, Badge } from 'reactstrap';
import axios from 'axios';
import auth0Client from '../../Auth/Auth';
import Widget from '../Widget/Widget';

import '../Forms/forms.scss';

import iUser from '../../images/user.png';
import loadingSpinner from '../../images/Eclipse-1s-200px.svg';

class Account extends React.Component {
  constructor(props) {
    super(props);

    this.getStudentInformation = this.getStudentInformation.bind(this);
    this.renderAcademicYear = this.renderAcademicYear.bind(this);
    this.renderProfile = this.renderProfile.bind(this);
    this.renderLoading = this.renderLoading.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);

    this.state = {
      collapseDoc: false,
      loading: true,
    };
  }

  componentDidMount() {
    this.getStudentInformation();
  }

  getStudentInformation() {
    const url = `${process.env.REACT_APP_API_URL}/student/getProfile`;

    const config = {
      headers: {
        Authorization: `Bearer ${auth0Client.getAccessToken()}`,
      },
    };

    axios
      .get(url, config)
      .then((response) => {
        const {
          advisor,
          currentProgram,
          email,
          firstName,
          forms,
          idNumber,
          internship,
          lastName,
          professionalConcentration,
          startYear,
          transferredYear,
          year,
        } = response.data;

        // set formYears
        let i;
        const formYears = [];
        for (i = 0; i < forms.length; i += 1) {
          if (formYears.indexOf(forms[i].academicYear) === -1) {
            formYears.push(forms[i].academicYear);
          }
        }

        // sort formYears
        formYears.sort((a, b) => b - a);

        // set collapse state for current year
        if (formYears.length > 0) {
          const collapseStr = `collapse${formYears[0]}`;
          this.setState({ [collapseStr]: true });
        }

        this.setState({
          advisor: `${advisor.lastName}`,
          currentProgram,
          email,
          firstName,
          forms,
          idNumber,
          internship,
          lastName,
          professionalConcentration,
          startYear,
          transferredYear,
          year,
          formYears,
          loading: false,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  toggleCollapse(card) {
    this.setState((state) => ({ [card]: !state[card] }));
  }

  renderAcademicYear(year) {
    const collapseStr = `collapse${year}`;
    const { forms } = this.state;
    const collapsed = this.state[collapseStr];

    // get forms for selected year
    const filteredForms = forms.filter((val) => val.academicYear === year);

    // check for PSE forms
    const filteredPSE = filteredForms.filter((form) => form.type === 'PSE');

    // set index of forms
    const idxASE = filteredForms.findIndex((form) => form.type === 'ASE');
    const idxSPR = filteredForms.findIndex((form) => form.type === 'SPR');
    const idxSSS = filteredForms.findIndex((form) => form.type === 'SSS');
    const idxAS = filteredForms.findIndex((form) => form.type === 'AS');

    return (
      <Row key={year}>
        <Col lg={{ size: 10, offset: 1 }} xs={12}>
          <Button
            color="inverse"
            size="lg"
            onClick={() => this.toggleCollapse(`collapse${year}`)}
            style={{ marginBottom: '1rem' }}
            block
          >
            {`Year ${year}-${year + 1}`}
          </Button>
          <Collapse isOpen={collapsed}>
            <Card>
              <CardBody>
                {idxASE !== -1 && (
                  <Row>
                    <Col lg={6} xs={12}>
                      <Link to={`/form/${filteredForms[idxASE].type}/${filteredForms[idxASE]._id}`}>
                        <p>
                          Annual Student Evaluation{' '}
                          {filteredForms[idxASE].status === 'new' && (
                            <Badge className="ml-2" color="info">
                              New
                            </Badge>
                          )}
                          {filteredForms[idxASE].status === 'inProgress' && (
                            <Badge className="ml-2" color="warning">
                              In Progress
                            </Badge>
                          )}
                          {filteredForms[idxASE].status === 'completed' && (
                            <Badge className="ml-2" color="success">
                              Completed
                            </Badge>
                          )}
                        </p>
                      </Link>
                    </Col>
                  </Row>
                )}
                {idxSPR !== -1 && (
                  <Row>
                    <Col lg={6} xs={12}>
                      <Link to={`/form/${filteredForms[idxSPR].type}/${filteredForms[idxSPR]._id}`}>
                        <p>
                          Student Progress Report{' '}
                          {filteredForms[idxSPR].status === 'new' && (
                            <Badge className="ml-2" color="info">
                              New
                            </Badge>
                          )}
                          {filteredForms[idxSPR].status === 'inProgress' && (
                            <Badge className="ml-2" color="warning">
                              In Progress
                            </Badge>
                          )}
                          {filteredForms[idxSPR].status === 'completed' && (
                            <Badge className="ml-2" color="success">
                              Completed
                            </Badge>
                          )}
                        </p>
                      </Link>
                    </Col>
                  </Row>
                )}
                {idxSSS !== -1 && (
                  <Row>
                    <Col lg={6} xs={12}>
                      {filteredForms[idxSSS].status !== 'completed' && (
                        <Link
                          to={`/form/${filteredForms[idxSSS].type}/${filteredForms[idxSSS]._id}`}
                        >
                          <p>
                            Student Progress Report{' '}
                            {filteredForms[idxSSS].status === 'new' && (
                              <Badge className="ml-2" color="info">
                                New
                              </Badge>
                            )}
                            {filteredForms[idxSSS].status === 'inProgress' && (
                              <Badge className="ml-2" color="warning">
                                In Progress
                              </Badge>
                            )}
                          </p>
                        </Link>
                      )}
                      {filteredForms[idxSSS].status === 'completed' && (
                        <p>
                          Student Progress Report{' '}
                          <Badge className="ml-2" color="success">
                            Completed
                          </Badge>
                        </p>
                      )}
                    </Col>
                  </Row>
                )}
                {filteredPSE.length > 0 &&
                  filteredPSE.map((form) => (
                    <Row>
                      <Col lg={6} xs={12}>
                        {form.status !== 'completed' && (
                          <Link to={`/form/${form.type}/${form._id}`}>
                            <p>
                              Practicum Site Evaluation - {form.practicumSiteName}{' '}
                              {form.status === 'new' && (
                                <Badge className="ml-2" color="info">
                                  New
                                </Badge>
                              )}
                              {form.status === 'inProgress' && (
                                <Badge className="ml-2" color="warning">
                                  In Progress
                                </Badge>
                              )}
                            </p>
                          </Link>
                        )}
                        {form.status === 'completed' && (
                          <p>
                            Practicum Site Evaluation - {form.practicumSiteName}{' '}
                            <Badge className="ml-2" color="success">
                              Completed
                            </Badge>
                          </p>
                        )}
                      </Col>
                    </Row>
                  ))}
                {idxAS !== -1 && (
                  <Row>
                    <Col lg={6} xs={12}>
                      <Link to={`/form/${filteredForms[idxAS].type}/${filteredForms[idxAS]._id}`}>
                        <p>
                          Alumni Survey{' '}
                          {filteredForms[idxAS].status === 'new' && (
                            <Badge className="ml-2" color="info">
                              New
                            </Badge>
                          )}
                          {filteredForms[idxAS].status === 'inProgress' && (
                            <Badge className="ml-2" color="warning">
                              In Progress
                            </Badge>
                          )}
                          {filteredForms[idxAS].status === 'completed' && (
                            <Badge className="ml-2" color="success">
                              Completed
                            </Badge>
                          )}
                        </p>
                      </Link>
                    </Col>
                  </Row>
                )}
                {this.renderPracticumSites(filteredForms)}
              </CardBody>
            </Card>
          </Collapse>
        </Col>
      </Row>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderPracticumSites(forms) {
    // get SACE forms for selected year
    const filteredSites = forms.filter((val) => val.type === 'SACE' || val.type === 'SACEv2');

    return (
      <div>
        <Row>
          <Col>
            <p>
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4 className="float-left">Practicum Sites</h4>
            <Table hover>
              <thead>
                <tr>
                  <th scope="col">Term</th>
                  <th scope="col">Facility</th>
                  <th scope="col">Supervisor</th>
                  <th scope="col">Form Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredSites.map((pracSite) => (
                  <tr key={pracSite._id}>
                    <td>{pracSite.term.charAt(0).toUpperCase() + pracSite.term.slice(1)}</td>
                    <td>{pracSite.practicumSiteName}</td>
                    <td>{pracSite.practicumSiteSupervisor}</td>
                    <td>
                      {pracSite.type === 'SACE' && (
                        <Link to={`/form/SACE/${pracSite._id}`}>
                          <div className="text-success">Completed</div>
                        </Link>
                      )}
                      {pracSite.type === 'SACEv2' && (
                        <Link to={`/form/SACEv2/${pracSite._id}`}>
                          <div className="text-success">Completed</div>
                        </Link>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    );
  }

  renderProfile() {
    const {
      advisor,
      currentProgram,
      email,
      firstName,
      idNumber,
      // internship,
      lastName,
      professionalConcentration,
      // startYear,
      // transferredYear,
      year,
      formYears,
      collapseDoc,
    } = this.state;
    return (
      <div>
        <Row>
          <Col sm={{ size: 8, offset: 2 }} />
        </Row>
        <Row>
          <Col lg={{ size: 10, offset: 1 }} xs={12}>
            <h2 className="page-title mt-4">Student Profile</h2>
          </Col>
        </Row>
        <Row>
          <Col lg={{ size: 10, offset: 1 }} xs={12}>
            <Widget>
              <Row>
                <Col md={4}>
                  <div className="text-center">
                    <img
                      className="rounded-circle"
                      style={{ height: `${112} + px` }}
                      src={iUser}
                      alt="64x64"
                    />
                  </div>
                </Col>
                <Col md={8}>
                  <h3 className="mt-md mb-xs">{`${firstName} ${lastName}`}</h3>
                  <Table hover>
                    <tbody>
                      <tr>
                        <th scope="row">ID:</th>
                        <td width="5" />
                        <td>{idNumber}</td>
                      </tr>
                      <tr>
                        <th scope="row">EMAIL:</th>
                        <td />
                        <td>{email}</td>
                      </tr>
                      <tr>
                        <th scope="row">YEAR:</th>
                        <td />
                        <td>{year}</td>
                      </tr>
                      <tr>
                        <th scope="row">ADVISOR:</th>
                        <td />
                        <td>{advisor}</td>
                      </tr>
                      <tr>
                        <th scope="row">PROGRAM:</th>
                        <td />
                        <td>{currentProgram}</td>
                      </tr>
                      <tr>
                        <th scope="row">PROFESSIONAL CONCENTRATION:</th>
                        <td />
                        <td>{professionalConcentration}</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              {formYears.map((academicYear) => this.renderAcademicYear(academicYear))}
              <Row className="mt-4">
                <Col lg={{ size: 10, offset: 1 }} xs={12}>
                  <Button
                    color="inverse"
                    size="lg"
                    onClick={() => this.toggleCollapse('collapseDoc')}
                    style={{ marginBottom: '1rem' }}
                    block
                  >
                    Uploaded Documents
                  </Button>
                  <Collapse isOpen={collapseDoc}>
                    <Card>
                      <CardBody className="text-center">
                        <strong>No Files Uploaded</strong>
                      </CardBody>
                    </Card>
                  </Collapse>
                </Col>
              </Row>
            </Widget>
          </Col>
        </Row>
      </div>
    );
  }

  renderLoading() {
    return (
      <div>
        <Row>
          <Col lg={{ size: 10, offset: 1 }} xs={12}>
            <h2 className="page-title mt-2">Student Profile</h2>
          </Col>
        </Row>
        <Row className="text-center">
          <Col>
            <img src={loadingSpinner} alt="loading spinner" className="img-responsive" />
          </Col>
        </Row>
      </div>
    );
  }

  render() {
    const { loading } = this.state;

    if (!loading) {
      return <div>{this.renderProfile()}</div>;
    }
    return <div>{this.renderLoading()}</div>;
  }
}

export default Account;
