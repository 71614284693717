/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container, Button } from 'reactstrap';
import auth0Client from '../../Auth/Auth';

function Home(props) {
  if (auth0Client.isAuthenticated()) {
    // eslint-disable-next-line react/destructuring-assignment
    props.history.replace('/profile');
    return <div />;
  }

  return (
    <Container>
      {auth0Client.isAuthenticated() && (
        <h4 className="text-center">
          You are logged in! You can now view your <Link to="/profile">profile</Link>.
        </h4>
      )}
      {!auth0Client.isAuthenticated() && (
        <h3 className="text-center text-white mt-4">
          {' '}
          <Button
            style={{ fontSize: '1.5rem', color: '#f8f8f8' }}
            color="link"
            onClick={auth0Client.login}
          >
            You are not logged in! Please login to continue.
          </Button>
        </h3>
      )}
    </Container>
  );
}

Home.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Home);
